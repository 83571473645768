import { useState, useEffect } from "react";

const useFundingPeriodValidation = (
  account: { creationDate?: string; depositRequiredByDate?: string } | undefined
) => {
  const [isFundingPeriodValid, setIsFundingPeriodValid] =
    useState<boolean>(false);
  const [remainingDays, setRemainingDays] = useState<number>(0);
  const [fundingPeriod, setFundingPeriod] = useState<number>(0);

  useEffect(() => {
    const validateFundingPeriod = () => {
      const currentDate = new Date();

      if (account?.creationDate && account?.depositRequiredByDate) {
        const [creationDay, creationMonth, creationYear] = account?.creationDate
          ?.split("-")
          .map(Number);

        const creationDateObject = new Date(
          Date.UTC(creationYear, creationMonth - 1, creationDay)
        );
        const [depositByDay, depositByMonth, depositByYear] =
          account?.depositRequiredByDate.split("-").map(Number);

        const depositByDateObject = new Date(
          Date.UTC(depositByYear, depositByMonth - 1, depositByDay)
        );
        const timeDiff = depositByDateObject.getTime() - currentDate.getTime();
        const remainingFundingPeriod = Math.ceil(
          timeDiff / (1000 * 60 * 60 * 24)
        );

        const totalFundingDay = Math.ceil(
          (depositByDateObject.getTime() - creationDateObject.getTime()) /
            (1000 * 60 * 60 * 24)
        );

        setFundingPeriod(totalFundingDay);
        setRemainingDays(remainingFundingPeriod);

        const fundingPeriodFromCreation = Math.ceil(
          (depositByDateObject.getTime() - creationDateObject.getTime()) /
            (1000 * 60 * 60 * 24)
        );

        if (
          remainingFundingPeriod <= fundingPeriodFromCreation &&
          remainingFundingPeriod >= 0
        ) {
          setIsFundingPeriodValid(true);
        } else {
          setIsFundingPeriodValid(false);
        }
      }
    };
    if (account?.creationDate) {
      validateFundingPeriod();
    }
  }, [account]);
  return { isFundingPeriodValid, remainingDays, fundingPeriod };
};

export default useFundingPeriodValidation;
