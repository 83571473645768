import React from 'react'

export const Input = (props : any) => {
    const { id, className, value, onChange, onFocus, onBlur, placeholder, type, maxLength} = props
  return (
    <input
        value={value}
        id={id}
        className={className}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        maxLength={maxLength}
        type={type}
      />
  )
}