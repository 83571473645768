import { createSlice } from "@reduxjs/toolkit";
import { getWelcomeBoardDetailsAction } from "../actions/welcomeBoardAction";

const initialState = {
  welcomeBoards: []
};

export const welcomeBoardsSlice = createSlice({
  name: "welcomeBoards",
  initialState,
  reducers: {
    clearStates: () => initialState,
  }, 
  extraReducers: (builder) => {
    builder.addCase(getWelcomeBoardDetailsAction.fulfilled, (state : any, { payload }) => {
      // TODO always keep in mind to take care wrong payload or empty payload        
      state.welcomeBoards = payload
    });
  }
});

export const { clearStates } =
welcomeBoardsSlice.actions;

export default welcomeBoardsSlice.reducer;