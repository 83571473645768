import { createSlice } from "@reduxjs/toolkit";
import { getWithdrawalAccountListAction } from "../actions/withdrawalAccountAction";

const initialState = {
  withdrawalAccount: []
};

export const withdrawalAccountSlice = createSlice({
  name: "withdrawalAccount",
  initialState,
  reducers: {
    clearStates: () => initialState,
  }, 
  extraReducers: (builder) => {
    builder.addCase(getWithdrawalAccountListAction.fulfilled, (state : any, { payload }) => {
      // TODO always keep in mind to take care wrong payload or empty payload       
      state.withdrawalAccount = payload
    });
  }
});

export const { clearStates } =
withdrawalAccountSlice.actions;

export default withdrawalAccountSlice.reducer;