import { createSlice } from "@reduxjs/toolkit";
import { getSavingPublicProductAction } from "../actions/savingAccountPublicProductAction";

const initialState = {
  savingAccountPublicProduct: [],
};

export const savingAccountPublicProductSlice = createSlice({
  name: "navigationBar",
  initialState,
  reducers: {
    clearStates: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getSavingPublicProductAction.fulfilled, (state : any, { payload }) => {
      // TODO always keep in mind to take care wrong payload or empty payload
     state.savingAccountPublicProduct = payload;
    });
  },
});

export const { clearStates } = savingAccountPublicProductSlice.actions;

export default savingAccountPublicProductSlice.reducer;
