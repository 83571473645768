import React from "react";
import { INITIAL_COUNT } from "../constant/customConstant";
import Styles from "../../styleModules/commonHeading.module.scss"

export const SelectInput = (props: any) => {
  const {
    options,
    labelTitle,
    id,
    className,
    required,
    onChange,
    isError,
    errorMsg,
    selected,
  } = props;
  const valueSelected = (item: string) => {
    if (selected == item) return true;
    else return false;
  };
  return (
    <>
      {labelTitle && (
        <label htmlFor={id} className="form-label">
          {labelTitle}
          {required && <span className="text-danger"> * </span>}
        </label>
      )}
      <select
        className={`form-select form-control ${className} ${isError && "input-error-border"}`}
        id={id}
        aria-label="Default select example"
        onChange={onChange}
      >
        {options.map((item: any, index: number) => (
          <option
            key={index}
            selected={!selected ? index == INITIAL_COUNT : valueSelected(item)}
          >
            {item}
          </option>
        ))}
      </select>
      {isError && <div className={Styles.errorHeadingSmallStyle}>{errorMsg}</div>}
    </>
  );
};
