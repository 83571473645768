import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  FooterData,
  NavigationLink,
} from "../../../interfaces/footer.interface";
import { Img } from "../../basic components/img";
import Strings from "../../../theme/string";
import iconMap from "./footerIconMap";
import { fetchFooterGraphqlData } from "../../../services/graphqlApis/apis/footerApi";
import { GRAPHQL_API } from "../../../services/url";

export const CustomFooter = () => {

  const [footerCMSData, setFooterCMSData] = useState<FooterData | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const footerGraphqlResponse = await fetchFooterGraphqlData(
          GRAPHQL_API
        );
        if (
          footerGraphqlResponse &&
          typeof footerGraphqlResponse === "object"
        ) {
          setFooterCMSData(footerGraphqlResponse as FooterData);
        }
      } catch (error) {
        console.error("Error fetching footer data", error);
      }
    })();
  }, []);

  const socialLinks = useMemo(() => {
    return footerCMSData?.data.footerDetails?.socialLinksCollection?.items.map(
      (link) => (
        <a
          key={link.icon.title}
          href={link.icon.url}
          aria-label={link.icon.ariaLabel}
          target="_blank"
          rel="noopener noreferrer"
          className="me-2"
        >
          <Img
            src={link.icon.url}
            alt={link.icon.name}
            className="social-icon"
            loading="lazy"
          />
        </a>
      )
    );
  }, [footerCMSData]);

  const appLinks = useMemo(() => {
    return footerCMSData?.data.footerDetails?.appLinksCollection?.items.map(
      (link, index) => (
        <a
          key={index}
          href={link.icon.url}
          aria-label={link.icon.ariaLabel}
          target="_blank"
          rel="noopener noreferrer"
          className="mb-2"
        >
          <Img
            src={link.icon.url}
            alt={link.icon.name}
            className="app-download-icon"
            loading="lazy"
          />
        </a>
      )
    );
  }, [footerCMSData]);

  const sections = useMemo(() => {
    return footerCMSData?.data.footerDetails?.sectionsCollection?.items.map(
      (section, index) => (
        <div key={index} className="col-3">
          <h3 className="h5 mb-4">{section.title}</h3>
          <ul className="list-unstyled">
            {section.linksCollection.items.map((link: NavigationLink) => (
              <li key={link.name} className="mb-2">
                <Link to={link.url} className="points">
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )
    );
  }, [footerCMSData]);

  return (
    <footer
      id="customFooter"
      className="footer mt-5 footer text-center text-lg-start"
      aria-labelledby="footerHeading"
    >
      <div className="container-xxl">
        <div className="row row-top-padding footer-heading">
          <div className="col text-center fw-normal lh-lg font-size-48px font-family-Courgette mb-4">
            {Strings?.footerSavingsText}
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <h3 className="h5">{Strings?.followusText}</h3>
            <div className="d-flex justify-content-start mb-4">
              {socialLinks}
            </div>
            <h3 className="h5">{Strings?.getTheAppText}</h3>
            <div className="d-flex flex-column mb-4">{appLinks}</div>
          </div>
          {sections}
        </div>
        <div className="d-flex flex-row custom-footer-gap">
          <div>
            <Img
              src={iconMap["FcsLogoIcon"]}
              alt="FSCS Logo"
              className="fscs-logo"
              loading="lazy"
            />
          </div>
          <div>
            <p>{footerCMSData?.data?.footerDetails?.overallDescription}</p>
          </div>
        </div>
      </div>
    </footer>
  );
};
