import { createSlice } from "@reduxjs/toolkit";
import { fetchAccount } from "../../services/fetchAccount";

interface DashboardState {
  data: any;
  isLoading: boolean;
  error: string | null;
}

const initialState: DashboardState = {
  data: {},
  isLoading: false,
  error: null,
};

const accountDetailsAccountSlice = createSlice({
  name: "accountDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccount.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(fetchAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Failed to fetch data";
      });
  },
});

export default accountDetailsAccountSlice.reducer;
