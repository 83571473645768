export function formatDateWithOrdinalSuffix(dateStr: string | number | Date) {
    const dateObj = new Date(dateStr);
    const getOrdinalSuffix = (day: number) => {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString('en-GB', { month: 'long' });
    const year = dateObj.getFullYear();
    return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
 }