import styles from "../../styleModules/profileStyles.module.scss";
import userIcon from "../../assest/User.svg";
import profileRing from "../../assest/profileRing.svg";
import { Img } from "../basic components/img";

interface Props {
  firstName: string | null;
  lastName: string | null;
}

const ProfileAvatar = ({ firstName, lastName }: Props) => {
  const initials = firstName && lastName ? `${firstName[0]}${lastName[0]}` : "";
  const fullName = firstName && lastName ? `${firstName} ${lastName}` : "";

  return (
    <div className={`mx-5 mb-2`}>
      <div className={`mt-5 ${styles.profileContainer}`}>
        <Img src={profileRing} width="112px" height="112px"></Img>
        <div className={`${styles.initialsContainer}`}>
          <span className={styles.initials}>{initials.toUpperCase()}</span>
        </div>
        <div className={styles.icon}>
          <Img src={userIcon} alt="profile" width="30px" height="30px" />
        </div>
      </div>
      <div className={`my-4 ${styles.fullname}`}>{fullName}</div>
    </div>
  );
};

export default ProfileAvatar;
