import React from "react";
import { URL_PATH } from "../../services/url";
import monu_icon from "../../assest/monu_Icon_Saving.svg";
import monu_Mission from "../../assest/monu_Mission.svg";
import monu_welcome_product from "../../assest/monu_welcome_product.svg";
import arrow_right from "../../assest/arrow_right.svg";
import { Img } from "../basic components/img";
import { useNavigate } from "react-router-dom";

export const MiddleTopPageIteration = (props: any) => {
  const navigate = useNavigate();
  const {
    title,
    cardTextOne,
    cardTextTwo,
    extra,
    bottomExtra,
    img,
    navigation,
  } = props.item;
  return (
    <div className="col-md col-card">
      <div className="card">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-6">
                  {img && (
                    <Img
                      src={monu_icon}
                      className="img-fluid rounded-start"
                      alt="Saving accounts"
                      width="56"
                    />
                  )}
                </div>
              </div>

              <h2 className="card-title">{title}</h2>
              <p className="card-text">{cardTextOne}</p>
              <p className="card-text">{cardTextTwo}</p>
              <a onClick={() => navigate('/savings')} className="card-link cursor-pointer">
                {" "}
                {extra}{" "}
                <Img
                  src={arrow_right}
                  width="16px"
                  height="16px"
                />
              </a>
              {bottomExtra && <p className="card-subtitle mb-2 text-muted">{bottomExtra}</p>}
            </div>
            <div className="col-lg-5 text-end">
              <Img className="img-fluid" src={title === "Savings Accounts" ? monu_Mission : monu_welcome_product}
                alt="Saving accounts"
                width="210"
                height="280"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
