import React from "react";
import { Button } from "../../../common/basic components/button";
import Strings from "../../../theme/string";
import profileStyles from "../../../styleModules/profileStyles.module.scss";
import headingStyles from "../../../styleModules/commonHeading.module.scss";
import { Img } from "../../../common/basic components/img";
import saveTick from "../../../assest/saveTick.svg";

const ProfileLinkedAccountSuccessBox = ({
  setLinkedAccountSuccessfulUpdateModalFlag,
}: {
  setLinkedAccountSuccessfulUpdateModalFlag: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}) => {
  return (
    <section
      className={`${profileStyles.profileLinkedAccTintedBox} d-flex col-md-8 col-lg-6 mt-4`}
      role="dialog"
      aria-modal="true"
      aria-labelledby="linked-account-update-success"
    >
      <div
        className={`d-flex mt-3 gap-4 mb-4 mr-5 ${profileStyles.linkedAccountInnerBox}`}
      >
        <div>
          <Img
            src={saveTick}
            className="img-margin-top-75 stepper-img"
            alt="Save icon"
            width="40px"
            height="40px"
          />
        </div>
        <div className=" gap-4">
          <div
            id="address-update-success"
            className={`${headingStyles.heading} mb-3`}
          >
            {Strings.profileLinkedAccProcessedText}
          </div>
          <div className={`${headingStyles.lightWeightTitle} mb-3`}>
            {Strings.profileLinkedAccConfirmationText}
          </div>
          <Button
            label="Done"
            className="btn btn-md btn-primary_one mt-2"
            onClick={() => setLinkedAccountSuccessfulUpdateModalFlag(false)}
            aria-label="Close linked account update success message"
          />
        </div>
      </div>
    </section>
  );
};

export default ProfileLinkedAccountSuccessBox;
