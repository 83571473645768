export const URL_PATH = `${process.env.LOCAL_APP_BASE_URL}`;
export const BASE_URL = `${process.env.REACT_APP_BASE_URL}/techcocustapi/techco-product-service/v1/`;
export const COUNTRY_URL = `${process.env.REACT_APP_BASE_URL}/techcocustapi/techco-sf-integration-service/v1/proxy/countries`;
export const LOGO = "logo";
export const NAVIGATION_BAR = "navigationBar";
export const LIFESTYLE = "lifestyles";
export const SAVING_ACCOUNT_PRODUCT = "savingAccountProduct";
export const MIDDLEPAGE = "middlePage";
export const MIDDLEPAGETOP = "middleTopPage";
export const STEPPERSUBPRODUCT = "stepperChoosePlan";
export const STEPPERSUMMARYBOXPRIVACYPOLICY = "stepperSummaryBoxPrivacyPolicy ";
export const SAVING_ACCOUNT_ALL_PRODUCTS = "saving-products";

export const MONUMENT_BASE_URL = "https://www.monument.co";
export const INDUSTRIES_URL = `${process.env.REACT_APP_BASE_URL}/techcocustapi/techco-sf-integration-service/v1/proxy/industries`;
export const ALL_SAVING_ACCOUNT_SF = `${process.env.REACT_APP_BASE_URL}/techcocustapi/techco-account-service/v1/savings`;
export const TS_COG_TOKEN = "https://qa-il-ts-domain.auth.eu-west-2.amazoncognito.com/oauth2/token";
// export const TS_COG_TOKEN = "https://techco-ts-user-pool.auth.eu-west-2.amazoncognito.com/oauth2/token";
export const CREATE_PARTY_API = `${process.env.REACT_APP_BASE_URL}/techcocustapi/techco-sf-integration-service/v1/proxy/party-details`;
export const CREATE_PARTY_API_TSAPI = `${process.env.REACT_APP_BASE_URL_TS}/sf-integration-service/v1/proxy/party-details`;
export const SAVING_APPLICATION_API = `${process.env.REACT_APP_BASE_URL_CUST}/sf-integration-service/v1/proxy/saving-applications`;
export const FETCH_FINANCIAL_DATA = `${process.env.REACT_APP_BASE_URL}/techcocustapi/techco-sf-integration-service/v1/proxy/financial-accounts`;
export const FETCH_ACCOUNT = `${process.env.REACT_APP_BASE_URL}/techcocustapi/techco-account-service/v1/savings/`;
export const FETCH_FINANCIAL_ACCOUNT = `${process.env.REACT_APP_BASE_URL}/techcocustapi/techco-sf-integration-service/v1/proxy/financial-accounts?account-number-hash=`;
export const WITHDRAWAL_AMOUNT_NOTICE_ACCOUNT = `${process.env.REACT_APP_BASE_URL}/techcocustapi/techco-sf-integration-service/v1/proxy/withdrawal-notices`;
export const WITHDRAWAL_NOTICE_ACCOUNT = `${process.env.REACT_APP_BASE_URL}/techcocustapi/techco-sf-integration-service/v1/proxy/withdrawal-notices?account-number-hash=`;
export const WITHDRAWAL_AMOUNT_EASY_ACCESS_ACCOUNT = `${process.env.REACT_APP_BASE_URL}/techcocustapi/techco-payment-service/v1/transactions`;
export const GET_PARTY_DETAILS = `${process.env.REACT_APP_BASE_URL}/techcotsapi/techco-sf-integration-service/v1/proxy/party-details`;
export const SAVE_PROFILE_ADDRESS = `${process.env.REACT_APP_BASE_URL}/techcocustapi/techco-sf-integration-service/v1/proxy/update-address`;
export const TRANSFER_AMOUNT_NOTICE = `${process.env.REACT_APP_BASE_URL}/techcocustapi/techco-payment-service/v1/notice-accounts/transactions`;
export const POST_MEMORABLE_WORD = `${process.env.REACT_APP_BASE_URL}/techcocustapi/techco-sf-integration-service/v1/proxy/memorable-word`;
export const UPDATE_LINKED_ACC = `${process.env.REACT_APP_BASE_URL}/techcocustapi/techco-sf-integration-service/v1/proxy/cases`;
export const GRAPHQL_API = `${process.env.REACT_APP_CONTENTFUL_BASE_URL}/content/v1/spaces/dd9ez9gbofjr/environments/master`;