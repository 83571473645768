import { createSlice } from "@reduxjs/toolkit";
import { fetchLinkedAccount } from "../../services/fetchLinkedAccount";

interface DashboardState {
  data: any;
  isLoading: boolean;
  error: string | null;
}

const initialState: DashboardState = {
  data: {},
  isLoading: false,
  error: null,
};

const accountDetailsLinkedAccount = createSlice({
  name: "fetchLinkedAccount",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLinkedAccount.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchLinkedAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(fetchLinkedAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Failed to fetch data";
      });
  },
});

export default accountDetailsLinkedAccount.reducer;
