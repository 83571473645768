import React from 'react';

export const Img = (props : any) => {
    const {src, loading, height, width, alt, id, className, onClick} = props;
  return (
    <img
        src={src}
        loading={loading}
        height={height}
        width={width}
        alt={alt}
        id={id}
        onClick={onClick}
        className={className}
      />
  )
}
