import React from 'react'
import { LandingPageScreenTop } from '../../common/components/landingPageScreen'
import { MiddlePage } from '../../common/components/middlePage'

export const HomeScreen = () => {
  return (
    <>
    <LandingPageScreenTop />
    <MiddlePage />
    </>
  ) 
}
