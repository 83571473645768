import React from "react";
import Modal from "react-bootstrap/Modal";
import ellipse from "../../assest/Ellipse.svg";
import { LoadingModalProps } from "../../interfaces/loadingModal.interface";

const LoadingModal = ({ show, size,children }: LoadingModalProps) => {
  return (
    <Modal
      show={show}
      size={size}
      aria-labelledby="loading-modal-indicator"
      centered
    >
      <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
        <div className="text-center">
          <img
            src={ellipse}
            alt="green color circle"
            style={{ width: "88px", height: "88px" }}
          />
         {!children && <p className="fs-2">Processing...</p>}
         <div className="mt-4"> {children}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoadingModal;
