import React from "react";
import styles from "../../../../../styleModules/reviewStepper.module.scss";
import pencil from "../../../../../assest/pencil.svg";
import { Img } from "../../../../../common/basic components/img";

export const EditButtonComponent = ({ onClick }: { onClick: () => void }) => (
  <div className={styles.imageStyle} onClick={onClick}>
    <span
      className="square-stepper-button current"
      style={{
        width: "42px",
        height: "38px",
        cursor: "pointer",
      }}
      aria-label="Edit form field"
    >
      <Img
        src={pencil}
        className="img-fluid img-margin-top-75 stepper-img"
        alt="Edit"
        width="16px"
        height="16px"
      />
    </span>
  </div>
);
