import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { FETCH_FINANCIAL_ACCOUNT } from "./url";
import { PostLinkedAccountUpdateData } from "../interfaces/profile.interface";

interface interestToInterface {
  hashedAccount: string;
  account: string;
}

export const fetchLinkedAccount = createAsyncThunk<{ data: any }, string>(
  "accountdetails/fetchLinkedAccount",
  async (hashedAccount) => {
    try {
      const token = await localStorage.getItem("tokenVal");
      const response = await axios.get(
        `${FETCH_FINANCIAL_ACCOUNT}${hashedAccount}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      throw error;
    }
  }
);

export const patchLinkedAccount = createAsyncThunk<{ data: any }, interestToInterface>(
  "accountdetails/fetchLinkedAccount",
  async (hashedAccount) => {
  try {
    const token = await localStorage.getItem("tokenVal");
    const response = await axios.patch(
      `${FETCH_FINANCIAL_ACCOUNT}${hashedAccount.hashedAccount}`,
      {
        payInterestTo: hashedAccount.account,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
});

export const updateLinkedAccountDetails = async (
  url: string,
  postData: PostLinkedAccountUpdateData,
  token: string
) => {
  try {
    const response = await axios.post(url, postData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
    return response?.data;
  } catch (error: any) {
    return { error: true, ...error?.response };
  }
};
