import axios from "axios";
import { BASE_URL } from "./url";

export const getAccountProductPublic = async (url: any) => {
  let tmpData;
  await axios
    .get(`${BASE_URL}saving-products?loyaltyProduct=false&active=true&channel=Web`
    )
    .then((response) => {
      tmpData = [...response.data.data.savingProduct];
    })
    .catch((error) => {
      console.error(error);
    });
  return tmpData;
};

export const getAccountDetails = async (url :any, token: any) => {
  let tmpData;
  await axios
    .get(`${url}`,
      {headers: {
        'Authorization': `${token}`,
        // 'Access-Control-Allow-Credentials':true,
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
      }}
    )
    .then((response) => {
      tmpData = response;
    })
    .catch((error) => {
      console.error(error);
    });
  return tmpData;
};

export const testPOSTapis = async(url :any, token: any, body: any) => {
  try {
    const response = await axios.post(url, body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization":`${token}`
      },
    });
    return response?.data;
  } catch (error:any) {
    if (error.response) {
      // Server responded with a status other than 2xx
      return error.response?.data?.message || "An error occurred";
    } else if (error.request) {
      // Request was made but no response was received
      return "No response received from server";
    } else {
      // Something else caused the error
      return error.message;
    }
  }
}


