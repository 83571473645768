import React from "react";
import vectorTick from "../../../assest/vector_tick.svg";
import { Img } from "../../basic components/img";
import { TOTAL_STEPS } from "../../constant/customConstant";

export const StepperButton = ({ activeStep }: { activeStep: number }) => {

  const renderStepperButtons = React.useCallback(() => {

    return Array.from({ length: TOTAL_STEPS }, (_, index) => {
      const step = index + 1;
      const isActive = activeStep === step - 1;
      const isCompleted = activeStep >= step;
      const isNotLastStep = step !== TOTAL_STEPS;
      return (
        <React.Fragment key={step}>
          <td>
            <div
              className={`square-stepper-button ${
                isActive ? "current" : isCompleted ? "complete" : ""
              }`}
              role="button"
              aria-current={isActive ? "step" : undefined}
              tabIndex={0}
            >
              {isCompleted && !isActive ? (
                <Img
                  src={vectorTick}
                  className="img-fluid rounded-start img-margin-top-75 stepper-img"
                  alt={`Step ${step} complete`}
                  width="auto"
                />
              ) : (
                step
              )}
            </div>
          </td>
          {isNotLastStep && (
            <td>
              <div
                className={`line-stepper-button ${
                  isCompleted ? "complete" : ""
                }`}
                aria-hidden="true"
              ></div>
            </td>
          )}
        </React.Fragment>
      );
    });
  }, [activeStep]);

  return (
    <table className="wizard-stepper">
      <tbody>
        <tr>{renderStepperButtons()}</tr>
      </tbody>
    </table>
  );
};
export default React.memo(StepperButton);
