import { createSlice } from "@reduxjs/toolkit";
import { getCountryListAction } from "../actions/formSelectListsAction";

const initialState = {
  countryList: []
};

export const countryListSlice = createSlice({
  name: "countryList",
  initialState,
  reducers: {
    clearStates: () => initialState,
  }, 
  extraReducers: (builder) => {
    builder.addCase(getCountryListAction.fulfilled, (state : any, { payload }) => {
      // TODO always keep in mind to take care wrong payload or empty payload       
      state.countryList = payload
    });
  }
});

export const { clearStates } =
countryListSlice.actions;

export default countryListSlice.reducer;