const Strings = {
  selectCategoryTitle: "Savings Account",
  selectCategoryDescription:
    "Open an account in a few simple steps. Your eligible savings are protected up to £85,000 by the Financial Services Compensation Scheme.",
  viewProductsBtn: "View Products",
  chooseAnAccountBtn: "Choose an Account",
  btnCancel: "Cancel",
  btnPrevios: "Previous",
  btnContinue: "Continue",
  startApplication: "Start Application",
  submitApplication: "Submit Application",
  choosePlanSummaryHeader:
    "Choose the product that best fits your needs based on the interest rate and terms.",
  typeToSearch: "Type to search",
  enterTextNumber: "Enter Tax Number",
  taxNumberFor: "Tax Number for",
  taxFormatExample: "e.g. 000000000 9 digit format",
  stringNotConsidered: "Only numeric is required.",
  reachedAtMaxLength: "Maximum length is reached.",
  interestPaidOption1: "This",
  interestRadioLinkedAccount: "Linked Account",
  linkBankInterestRadioLabel: "Choose an option for interest being paid",
  linkBankSortCodeLabel: "Sort Code",
  linkBankSortCodePlaceholder: "Enter Sort Code",
  linkBankSortCodeHelperText: "e.g.  000000 sort code should be 6 digits",
  linkBankSortCodeError: "Please enter correct sort Code.",
  linkBankSortCodeBlankError: "Please enter your sort code.",
  linkBankAccountNumberLabel: "Account Number",
  linkBankAccountNumberPlaceholder: "Enter Account Number",
  linkBankAccountNumberHelperText:
    "e.g.  00000000 Account number should be 8 digits",
  linkBankAccountNumberError: "Please enter correct Account number.",
  linkBankAccountNumberBlankError: "Please enter your Account Number.",
  linkBankAmountDepositLabel: "How much are you planning to deposit?",
  linkBankAmountDepositPlaceholder: "Enter Amount",
  example: "e.g. ",
  linkBankAmountDepositHelperText: ", This is your estimated deposit amount",
  linkBankAmountDepositError: "Please enter an amount greater than £0.",

  firstName: "First Name",
  firstNamePlaceholder: "Enter First Name",
  firstNameBlankErrorMsg: "Please enter your first name.",

  middleName: "Middle Name",
  middleNamePlaceholder: "Enter Middle Name if any",

  lastName: "Last Name",
  lastNamePlaceholder: "Enter Last Name",
  lastNameBlankErrorMsg: "Please enter your last name.",

  defaultNameBottomText:
    "e.g. Name should be as per Passport or Driving License",
  defaultInputType: "text",
  nameRegex: "^[a-zA-Z'-]+$",
  defaultNameErrorMsg:
    "Please only use letters (a-z), hyphens (-) and apostrophes ( ' ).",
  nationalityCanNotBeBlank: "Please enter your nationalities or citizenship.",
  countryTaxResidentCanNotBeBlank: "Please enter your tax resident.",
  mobieNumberCanNotBeBlank: "Please enter a valid UK mobile number.",
  dateOfBirthCanNotBeBlank: "Please enter your date of birth.",
  industryCanNotBeBlank:
    "Please type to search and select the industry you work in.",
  nationalityAndCitizenshipCanNotBeBlank:
    "Please type to search and select the nationalities or citizenship do you have.",
  dateOfBirth: "Date of birth",
  dateOfBirthPlaceholder: "DD/MM/YYYY",
  dateOfBirthBottomText: "e.g. DD/MM/YYYY",
  dateOfBirthErrorMsg:
    "Please enter correct date of birth as per below given example.",
  dateOfBirthRegex: "^\\d{4}-\\d{2}-\\d{2}$",

  emailAddress: "Email Address",
  emailAddressPlaceholder: "Enter Email Address & Verify",
  emailAddressBottomText: "e.g. email@example.com",
  emailAddressErrorMsg: "Please enter a valid email address.",
  emailAddressRegex: "^[a-zA-Z0-9_.±]+@[a-zA-Z0-9-]+[\\.][a-zA-Z0-9-]{2,3}",
  emailAddressBlankErrorMsg: "Please enter a valid email address.",
  emailAddressButtonText: "Verify",

  mobileNumber: "UK Mobile Number",
  mobileNumberPlaceholder: "Enter Mobile Number & Verify",
  mobileNumberBottomText: "e.g. +44(0) 0000000000",
  mobileNumberErrorMsg: "Please enter a valid mobile number.",
  mobileNumberRegex: "^[1-9](\\d{9}$)",
  mobileNumberButtonText: "Verify",

  postalAddress: "Postal Address Line1",
  postalAddressPlaceholder: "Search using your postcode",
  postalAddressBottomText: "Cant’t find your address?",
  postalAddressErrorMsg: "Please enter a valid mobile number.",
  postalAddressRegex: "[+]44[-]\\d{2}-\\d{4}\\s\\d{4}$",
  postalAddressButtonText: "Search",

  addressLine2: "Address Line 2",
  addressLine2Placeholder: "Address Line 2",

  addressLine3: "Address Line 3",
  addressLine3Placeholder: "Address Line 3",

  addressLine4: "Address Line 4",
  addressLine4Placeholder: "Address Line 4",

  townCity: "Town/City",
  townCityPlaceholder: "Town/City",
  townCityBlankErrorMsg: "Please enter your town/city.",

  postalCode: "Postal Code",
  postalCodePlaceholder: "Postal Code",
  postalCodeErrorMsg:
    "Postal code length cannot be more than 7 alpha or numeric characters.",
  postalCodeBlankErrorMsg: "Please enter your postcode",
  postalCodeRegex: "^[a-zA-Z0-9]{1,7}$",

  stepperPersonalInformationTitle:
    "The personal information we have collected from you will be shared \
    with the fraud prevention agencies who will use it to prevent any \
    fraud and money-laundering and to verify your identity. If fraud is \
    detected, you could be refused certain services, finances or \
    employment. Further details of your information will be used by us and \
    these fraud prevention agencies and your data protection rights, can \
    be found reading our privacy notice.",
  employmentInfoNeedText: "Learn why we need this data",
  employmentStatusRadioTitle: "Employment Status",
  employmentStatusEmployed: "Employed",
  employmentStatusSelfEmployeed: "Self-Employed",
  employmentStatusRetired: "Retired",
  employmentStatusUnemployed: "Unemployed",
  employmentStatusStudent: "Student",
  employmentStatusHomemaker: "Homemaker",
  employmentIndustrySearchTitle: "What Industry do you work in",
  employmentIndustrySearchTitleErrorMsg: "Please type to search and select the industry you work in.",
  employmentInputJobRoleTitle: "Job Role",
  jobRolePlaceholder: "Enter Job Role",
  incomeRange1: "Below £25,000",
  incomeRange2: "£25,001 to £50,000",
  incomeRange3: "£50,001 to £100,000",
  incomeRange4: "£100,001 to £200,000",
  incomeRange5: "£200,001 or more",
  employmentIncomeRadioTitle: "Annual Income before Tax",
  unableToFindRelatedProduct:
    "Sorry unable to get related product. Please try after some time.",
  jobRoleErrorMessage:
    "Please only use letters (a-z), hyphens (-) and apostrophe’s (')",
  industryInputErrorMessage: "Please select Industry you work in.",
  pleaseChooseEmployeeStatus: "Please choose your employment status",
  addItManually: "Add it manually",
  searchFromDataBase: "Search from data base",
  memorableSectionHeading: "Choose a memorable word",
  memorableSectionSubHeading:
    "We may ask you for this word if we need to verify you. This is an additional measure to keep your account safe.",
  memorableWordPlaceholder: "Enter  Memorable Word",
  memorableWordHelperText: "Minimum of 7 characters",
  memorableWordErrorMessage:
    "Please enter minimum 7 characters memorable word.",
  memorableSectionHintText: "Choose a hint for your memorable word.",
  memorableSectionHintPlaceholder: "Enter Hint",
  loginText: "Login",
  welcome: "Welcome",
  footerSavingsText: "Your savings are safe with us.",
  getTheAppText: "Get the App",
  followusText: "Follow Us",
  yes: "yes",
  us: "US",
  uk: "uk",
  another: "another",
  isUKCitizenOnly: "isUkCitizenOnly",
  otherThanUKCitizen: "otherThanUKCitizen",
  otherThanUKTaxNumber: "otherThanUKTaxNumber",
  taxNumber: "taxNumber",
  isTaxInputEnableForUS: "isTaxInputEnableForUS",
  isTaxInputEnableForAnother: "isTaxInputEnableForAnother",
  isTaxInputEnableForUK: "isTaxInputEnableForUK",
  pleaseSelectToContinue: "Please select to continue.",
  pleaseSelectAnyValue: "Please select any value",
  pleaseSelectAppropriateTitle: "Please select appropriate Title.",
  mustSelectAtLeastOneOption: "Please select at least one option.",
  yourTaxNumberMustBeNineDigit: "Your tax number must be 9 digits.",
  taxNumberCanNotBeBlank : "Please enter your tax number.",

  stepOne: 0,
  stepTwo: 1,
  stepThree: 2,
  stepFour: 3,
  stepFive: 4,
  stepSix: 5,
  stepSeven: 6,
  stepEight: 7,

  zero: 0,
  one: 1,
  two: 2,
  four: 4,
  five: 5,
  six: 6,
  seven: 7,
  eight: 8,
  twenty: 20,
  twoHundred: 200,
  cancelRequestHeading: "Cancel Application",
  cancelReQuestDescriptionConfirmation:
    "Are you sure you want to cancel your application?",
  cancelRequestDescription:
    "This cannot be undone and you will need to start a new application if you wish to open a savings account with us.",
  cancelRequestButtonText: "Yes, cancel my application",
  cancelBackText: "Back",
  timerInfoSectionSubHeading:
    "There are just a few steps to complete before we can open your account, which include knowing a little bit more about you, your account preferences, and the Terms & Conditions.",
  timerInfoSectionInfoHeading:
    "Learn why we need to know a little more about you",
  timerInfoSectionRatePrefix: "Your rate:",
  reviewPagelandingText:
    "Please check and confirm all the information you have provided is correct. You can easily edit your details if required",
  reviewPageCheckBoxLabel: "I confirm, the above information is correct",
  reviewPageGrossText: "Gross",
  reviewPageMainHeading: "is nearly ready.",
  reviewModalHeading: "Why we need to know a little more about you",
  reviewModalDescription:
    "To help us meet our regulatory obligations, we need to ask you some additional income-related questions so that we can better understand how you are funding your account.\n\nWe respect your privacy and are committed to protecting your personal data. The way we store this data is in line with our privacy notice. ",
  productDetailsProductHeading: "Product",
  productDetailsInterestHeading: "Interest Rate",
  productDetailsTitle: "Product Details",
  aer: "AER",
  pleaseEnterOTP: "Please enter the code we sent to +44",
  chooseAProduct: "Choose a Product",
  verificationCodeInvalid:
    "The verification code is invalid. Please re-enter the code to try again.",
  verificationCodeValid: "The verification code is valid.",
  verificationCodeExpireTwentySec:
    "The verification code will expire in 20secs. Please enter the code to verify.",
  verificationCodeExpireResend:
    "The verification code has expired. Please re-send the code to try again.",
  changeMobileNumber: "Change Mobile Number",
  resendCode: "Resend Code",
  pleaseConfirmEmailAddress: "Please confirm your email address is correct,",
  dontWorryNotUsedInMarket:
    "Don’t worry - we won't use it for marketing without your permission.",
  reviewDetailsEmailAddress: "Email address",
  reviewDetailsPhoneNumber: "UK mobile number",
  reviewName: "Name",
  reviewTitle: "Title",
  reviewPersonalDetails: "Personal Details",
  dashboardHeading: "Account Summary",
  dashboardDiscoverMore: "Discover More",
  dashboardCurrentBalance: "Your current balance is",
  dashboardModalText: ", thank you for joining Monument.",
  dashboardButtonTextFT1: "Make your first deposit by",
  dashboardButtonTextFT2: "Add more money by",
  chooseMaturityOptions: "Choose Maturity Options",
  dashboardButtonAddMoney: "Add Money",
  dashboardButtonWithdraw: "Withdraw",
  dashboardButtonMoveMoney: "Move Money",
  dashboardButtonMakeYourFirst: "Make your first deposit",
  jobRoleBlankErrorMessage: "Please enter your job role.",
  address: "Address",
  apiCallFailText: "API call failed ",
  apiErrorText: "API returned an error: ",
  gbr: "GBR",
  mobileCountryCode: "+44",
  mobileCountryCodeIncludeZero: "+44(0)",
  channelWeb: "Web",
  channelMobile: "Mobile",
  privacyFlag: "privacyFlag",
  reviewConfirmation: "reviewConfirmation",
  fscsConsentFlag: "fscsConsentFlag",
  termsAndConditionFlag: "termsAndConditionFlag",
  nationalityNdCitizenships: "Nationalities & Citizenships",
  taxResidency: "Tax Residency",
  otherCountriesTaxResident: "Other countries you are Tax resident of",
  citizenTaxCountryHeading: "Nationality & Tax Declaration",
  sortCode: "sortCode",
  accountNumber: "accountNumber",
  sortCodeStr: "Sort code",
  accountNumberStr: "Account number",
  depositAmount: "depositAmount",
  interestBeingPaid: "interestBeingPaid",
  saved: "saved",
  easyAccess: "Easy Access Savings",
  noticeAccount: "35 Days Notice Account",
  selectAccount: "Select an account",
  withdrawMoney: "Withdraw money",
  withdrawSubHeadingTwo: "Account to Linked Account.",
  linkedAccountDetails: "Your Linked Account details are",
  linkedAccount: "Linked Account",
  payeeName: "Payee name",
  withdrawalAmount: "Withdrawal amount",
  youCanWithdraw: "You can withdraw",
  cancelHint:
    "You can cancel your withdrawal right up until the day before you are due to receive your money.",
  confirmWithdrawal: "Confirm Withdrawal",
  withdrawalAmountPlaceholder: "Enter Withdrawal Amount",
  fixedTermDeposit: " Fixed Term Deposit",
  edit: "Edit",
  update: "Update",
  processing: "Processing",
  continueDot: "...",
  done: "Done",
  next: "Next",
  from: "From",
  selectAccountErrorText: "Please select an account",
  withdrawalAmountErrorText: "Please enter an amount",
  maximumWithdrawalAmountTransferError:
    "The maximum amount you can withdraw is",
  withdrawalSubmissionError:
    "We are having trouble processing your request. Please try again. If the problem continues, please",
  contactUsStr: "contact us",
  maxWithdrawalValidationText: "The maximum number of allowed withdrawals are",
  withdrawalConfirmed: "Your withdrawal is confirmed.",
  withdrawSubTextConfirmOne: "We’ll withdraw",
  withdrawSubTextConfirmTwo: "into your Linked account on",
  noteHeading: "Note:",
  ftNoteOne: "Add money to your account before",
  ftNoteTwo: "to start earning interest.",
  ftNoteThree:
    "Total balance across all your accounts needs to be at least £25,000.00",
  addMoneyBreadcrumb: `Add Money by bank transfer`,
  addMoneyHeading: `Add money by bank transfer.`,
  addMoneyDesc: `To add money to your Monument Savings account, you’ll need to send money from your Linked Account.`,
  addMoneyCopyHeadingLinkedBank: `Your Linked Account details are`,
  addMoneyCopyHeadingCurrentBank: `Your Monument account details`,
  addMoneyPayeeTitle: `Payee name`,
  addMoneySortCodeTitle: `Sort code`,
  addMoneyAccountTitle: `Account number`,
  addMoneyHelpText: `If you still need help, please `,
  addMoneyHelpHyperlinkText: `contact us`,
  addMoneyConditionTextAccountNumber: `account`,
  addMoneyConditionTextSortCode: `sort`,
  cancelButtonLabelText: `Cancel add money`,
  easyAccessWithdrawTextOne: "We’ve withdrawn",
  easyAccessWithdrawTextTwo:
    "from your Easy Access Savings to your Linked Account.",
  easyAccessMainWithdrawText: "Your money has been withdrawn.",
  paidAtMaturity: "Paid at maturity",
  earnedToDate: "Earned to date",
  grossText: "% Gross",
  aerText: "% AER",
  accruedThisMonth: "Accrued this month",
  interestPaidToDate: "Interest paid to date",
  personalInformationText: "Personal information",
  citizenshipText: "Citizenship & tax information",
  employementInfoText: "Employment information",
  ftMatureNotification: "Your account matures on",
  na: "N/A",
  selected: "Selected",
  moveMoney: "Move money",
  moveMoneyHeading: "Move money between your accounts",
  confirmTransfer: "Confirm Transfer",
  transerInputMsgTo:
    "You are transferring money to a Notice Account, which requires advance notice for future withdrawals.",
  transerInputMsgFrom:
    "Your money will be locked for the duration of your fixed term when you confirm this transfer",
  MoveMoneySubmissionError: "Check input fields",
  moveMOneyAmount: "£0.00",
  moveMOneyAmountPlaceholder: "Place holder",
  youCanTransfer: "You can transfer",
  transferAmount: "Amount",
  confirmTransferHeading: "Your money has been transferred.",
  transferLoadingButtonText: "Transferring your money...",
  transferAmountPlaceholder: "Enter Transfer Amount",
  moveMoneyNoticeWarningTo:
    "You are transferring money to a Notice account, which requires advance notice for future withdrawals.",
  moveMoneyNoticeWarningFrom:
    "Your money will be locked for the duration of your fixed term when you confirm this transfer.",
  moveMoneyNoticeError:
    "Transfer is not allowed if notice period is longer than funding period.",
  moveMoneyFromError: "Select an account to move money from.",
  moveMoneyToError: "Please select an account.",
  moveMoneySameAccountError: "Money can not be moved to the same account.",
  moveMoneyEmptyAmountError: "Please enter an amount.",
  moveMoneyValidAmountError: "The most you can tranfer is",
  yourInterestWillPaidToLinkedAccount:
    "Your interest will now be paid to your Linked Account.",
  payInterestToUpdate: "Pay Interest to Updated.",
  payInterestTo: "Pay interest to",
  interest: "Interest",
  account: "Account",
  accDetailsTransactionsHeading: "Transactions",
  accDetailsManageSavingsAccount: "Manage Savings Account",

  no: "No",
  refresh: "Refresh",
  yourWithdrawalBeingPro: "Your Withdrawal is being processed.",
  cancelTransactionDis:
    "Your withdrawal has already been processed and your money will be with you within the next 24 hours. You can add money to your account anytime.",
  yesCancelRequest: "Yes, Cancel request",
  withdrawalRequestSuccCanceled: "Withdrawal request successfully cancelled.",
  closed: "Closed",
  withdrawaTo: "Withdraw to",
  dueOn: "Due on",
  wouldYouLikeCancel: "Would you like to cancel this withdrawal request?",
  myProfileText: "My Profile",
  addressUpdatedText: "Your address has been updated",
  addressUpdateErrorOne: "Your address has been recently updated. Please",
  addressUpdateErrorTwo: "to change your Address.",
  linkedAccountUpdateErrorTwo: "to change your Linked Account.",
  postCodeBlankErrorText: "Please enter and search using a postcode.",
  postCodeLengthErrorText:
    "Postcode length cannot be more than 7 alpha or numeric characters.",
  addressErrorOne:
    "We couldn’t find your address. Try searching again or adding it manually using the link below.",
  linkedErrorOne: "Your Linked Account has been recently updated. Please ",
  addressErrorTwo:
    "Sorry, something doesn’t seem to be working right now, try adding it manually using the link below.",
  home: "Home",
  addressProcessingError:
    "We’re sorry, we are having trouble processing your request. Please try again. If the problem continues, please",
  detailsText: "details",
  dashboardTitle: "dashboard",
  marketingPreferencesHeading: "Marketing Preferences",
  pushNotification: "Push Notification",
  post: "Post",
  email: "Email",
  message: "Message",
  myProfileNationalTaxHeading: "Citizenship & tax information",
  myProfileSubHeading:
    "This is where you can update your address, Linked Account and Marketing Preferences. Need to change something else? Please contact us on [client bank number].",
  breadcrumbsAddmoney: "Add Money by bank transfer",
  breadcrumbsMovemoney: "Move money between your accounts",
  breadcrumbsWithdrawmoney: "Withdraw money",
  postOptIn: "postOptIn",
  emailOptIn: "emailOptIn",
  smsOptIn: "smsOptIn",
  nationalitiesNdCitizenship: "Nationalities & citizenship",
  profileLinkedAccInfoOne:
    "This change will update the Linked Account for all your Monument accounts.",
  profileLinkedAccInfoTwo:
    "This usually takes 20 seconds but can take longer if we require more information.",
  profileLinkedAccInfoThree:
    "We’ll send you a text and email after your Linked Account has been updated",
  profileLinkedAccProcessedText: "Your change is being processed.",
  profileLinkedAccConfirmationText:
    " This usually takes around 20 seconds, whilst we check the details you’ve provided are correct. We’ll send you a text and email when your change has been made. If we require any additional information we’ll be in touch by email.",
  profileLinkedAccConfirmationStr: "Confirmation",
};

export default Strings;
