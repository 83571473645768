import React from "react";
import { default as commonStyleHeading } from "../../../styleModules/commonHeading.module.scss";
import Strings from "../../../theme/string";
import { Input } from "../../basic components/input";
import { ARRAY_INITIAL_COUNT } from "../../constant/customConstant";

export const TaxDisplayComponent = (props: any) => {
  const { label, placeholder, bottomText, value, updateCountryHandler, isErrorRequired, mainObj, isTaxNumberFieldHasValue } = props;
  const checkInputValue = (e: any) => {
    const tmpVal = e?.target?.value;
    const isValueNumeric = !isNaN(tmpVal);
    if (!isValueNumeric || tmpVal.length > 9) {
      const subStringVal = tmpVal?.substring(0, tmpVal?.length - 1);
      updateCountryHandler(subStringVal);
    } else {
      updateCountryHandler(tmpVal);
    }
  };

  return (
    <div className="row mb-3">
      <div className="col-md-8 col-lg-6">
        <label className="form-label">
          {label} <span className="text-danger"> * </span>
        </label>
        <Input
          labelClassName=""
          lableValue={label}
          labelDangerRequired={true}
          placeholder={placeholder}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            checkInputValue(e)
          }
          className={`form-control ${isErrorRequired && 'input-error-border'}`}
          spanclassName="fst-italic font-size-12px"
          bottomText={bottomText}
          bottomDiscription={true}
        />
      </div>
      {isErrorRequired && (
        <div className={`${commonStyleHeading.errorHeadingSmallerStyle} mt-1`}>
          {isTaxNumberFieldHasValue
            ? Strings.yourTaxNumberMustBeNineDigit
            : Strings.taxNumberCanNotBeBlank}
        </div>
      )}
    </div>
  );
};
