import AccountWidget from "./AccountWidget";
import TransactionWidget from "./TransactionWidget";
import ManageSavingAccountWidget from "./ManageSavingAccountWidget";
import InterestPaidWidget from "./InterestPaidWidget";
import { useAppSelector } from "../../redux/redux-hooks";
import { useEffect, useState } from "react";
import { fetchAccount } from "../../services/fetchAccount";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchAccountTransactions,
  Transaction,
} from "../../services/fetchAccountTransactions";
import { generateSHA256 } from "../../common/utils/sha256Generator";
import { fetchLinkedAccount } from "../../services/fetchLinkedAccount";
import {
  INTEREST_APPLIED,
  PAGINATION_INITIAL_OFFSET as activePage,
} from "../../common/constant/accountDetailsConstant";
import Strings from "../../theme/string";
import WithdrawalWidget from "./WithdrawalWidget";
import Breadcrumbs from "../../common/basic components/Breadcrumbs";

const AccountDetails = () => {
  const [hashedAccount, setHashedAccount] = useState("");
  const dispatch = useDispatch();

  const { accountNumber } = useParams<{ accountNumber: string }>();

  useEffect(() => {
    if (accountNumber) {
      generateSHA256(accountNumber)
        .then((hashedAccount) => {
          setHashedAccount(hashedAccount);
          try {
            dispatch<any>(fetchAccount(hashedAccount));
          } catch (err) {
            console.error("Error fetching account: ", err);
          }
          try {
            dispatch<any>(
              fetchAccountTransactions({ hashedAccount, activePage })
            );
          } catch (err) {
            console.error("Error fetching transaction: ", err);
          }
          try {
            dispatch<any>(fetchLinkedAccount(hashedAccount));
          } catch (err) {
            console.error("Error fetching saving account: ", err);
          }
        })
        .catch((err) => console.error("Error generating SHA-256 hash:", err));
    }
  }, [accountNumber]);

  const { data: account } = useAppSelector(
    (state: any) => state.accountDetails
  );
  const { data: transactions } = useAppSelector(
    (state: any) => state.transaction
  );

  const {
    data: savingAccount,
    isLoading,
    error,
  } = useAppSelector((state: any) => state.linkedAccount);

  const calculateTotalInterest = (transactions: Transaction[]) => {
    return transactions
      .filter((transaction) => transaction.transactionType === INTEREST_APPLIED)
      .reduce((total, transaction) => total + transaction.amount, 0);
  };

  const totalPaidInterest = calculateTotalInterest(transactions);
  return (
    <div className="mt-4">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Breadcrumbs
              data={account}
              previousPath={`/accountdetails/${account?.accountNumber}`}
            />
          </div>

          <div className="row mt-2">
            {/* Left Section */}
            <div className="col-8">
              <div className="col-12">
                <h3 className="fw-bold text-start">{account.accountName}</h3>
              </div>
              {
                <AccountWidget
                  data={account}
                  totalPaidInterest={totalPaidInterest}
                />
              }
              {hashedAccount && (
                <>
                  <div className="row font-size-24px mt-3">
                    <div className="col">{"Upcoming transactions"}</div>
                  </div>
                  <WithdrawalWidget {...{ hashedAccount }} />
                </>
              )}
              <div className="row font-size-24px mt-3">
                <div className="col">
                  {Strings.accDetailsTransactionsHeading}
                </div>
              </div>
              {<TransactionWidget />}
            </div>
            {/* Right Section */}
            <div className="col-4">
              <div className="col-12">
                <h3 className="fw-bold text-start">
                  {Strings.accDetailsManageSavingsAccount}
                </h3>
              </div>
              {
                <ManageSavingAccountWidget
                  data={savingAccount}
                  isLoading={isLoading}
                />
              }
              {
                <InterestPaidWidget
                  payInterestTo={savingAccount[Strings.zero]?.payInterestTo}
                  hashedAccount={hashedAccount}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
