import React from "react";
import { useAppSelector } from "../../redux/redux-hooks";
import { MiddleTopPageIteration } from "./middleTopPageIteration";

export const MiddleTopPage = (props : any) => {
  const middlePageTop = useAppSelector((state : any) => state.middlePageTop);  
  return (
    <section id="middlePage" className="align-items-center offerings-cards container-xxl">
      
          <div className="row" >
            {middlePageTop?.middlePageTopObj.map((item : any) => {
              return <MiddleTopPageIteration {...{ item }} />;
            })}
          </div>
      
    </section>
  );
};
