import React, { useEffect, useMemo, useState, useCallback } from "react";
import headingStyles from "../../styleModules/commonHeading.module.scss";
import { SelectInput } from "../../common/basic components/selectInput";
import {
  CURRENCY_DEFAULT_VALUE,
  CURRENT_SYMBOL,
  WITHDRAWAL_CUSTOM_ACCOUNT_STATUS,
} from "../../common/constant/customConstant";
import Strings from "../../theme/string";
import styles from "../../styleModules/withdrawMoney.module.scss";
import { Button } from "../../common/basic components/button";
import { useAppSelector } from "../../redux/redux-hooks";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom";
import { formatBalance } from "../../common/utils/formatBalance";
import {
  AccountData,
  AccountDetails,
} from "../../interfaces/withdrawalSection.interface";
import { extractSortCodeAndAccount } from "../../common/utils/extractSortCodeAndAcc";
import {
  fetchFinancialData,
  withdrawalEasyAccessAccount,
  withdrawalNoticeAccount,
} from "../../services/fetchFinancialAccountData";
import { generateSHA256 } from "../../common/utils/sha256Generator";
import {
  FETCH_FINANCIAL_DATA,
  WITHDRAWAL_AMOUNT_EASY_ACCESS_ACCOUNT,
  WITHDRAWAL_AMOUNT_NOTICE_ACCOUNT,
} from "../../services/url";
import { AccountTypes } from "../../enums/customerService.enums";
import {
  currencyToNumConvertor,
  currencyToNumString,
} from "../../common/utils/currencyConvertors";
import { generateRandomAlphanumeric } from "../../common/utils/randomUniqueAlphanumStringGenerator";
import LoadingModal from "../../common/components/LoadingModal";
import { DynamicWithdrawalMoveMoneyBreadcrumb } from "./withdrawalMoveMoneyCommonBreadcrumb";
import Breadcrumbs from "../../common/basic components/Breadcrumbs";

// USE THIS ACC NUMBER  --> 04073400003391 --> DATA IS NOT AVAILABLE FOR SOME OTHER SAMPLE DATA

const WithdrawMoneyComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { accountNumber } = useParams<{ accountNumber: string }>();

  const [withdrawalAmount, setWithdrawalAmount] = useState<string>(
    CURRENCY_DEFAULT_VALUE
  );
  const [selectedData, setSelectedData] = useState<AccountData>();
  const [selectedValue, setSelectedValue] = useState<string>(
    CURRENCY_DEFAULT_VALUE
  );
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [linkedAccountData, setLinkedAccountData] = useState<{
    linkedAccount: string;
    partyName: string;
    accountNumber: string;
    type: string;
  } | null>(null);

  const accountData: AccountData[] = useAppSelector(
    (state: { dashboardAccountData: { data: AccountData[] } }) =>
      state?.dashboardAccountData?.data
  );
  const [isEditing, setIsEditing] = useState(false);
  const [withdrawalFlag, setWithdrawalFlag] = useState(false);
  const [withdrawalSubmissionError, setWithdrawalSubmissionError] =
    useState<boolean>();
  const [tempFlag, setTempFlag] = useState(false);
  const [filteredAccData, setFilteredAccData] = useState<AccountData[]>([]);
  const [loadModalFlag, setLoadModalFlag] = useState<boolean>(false);
  const [dynamicDescriptionAccountName, setDynamicDescriptionAccountName] =
    useState<string>("");
  const [isNoticeAccount, setIsNoticeAccount] = useState(false);

  const formatCurrencyWithDecimals = useCallback((value: string) => {
    const cleanedValue = value.replace(/[^\d.]/g, "");
    const numberValue = parseFloat(cleanedValue);
    if (isNaN(numberValue)) return CURRENCY_DEFAULT_VALUE;
    return numberValue.toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP",
    });
  }, []);

  const fetchLinkedAccountData = useCallback(async (accountNumber: string) => {
    try {
      const token = localStorage.getItem("tokenVal") ?? "";
      const hashAcc = await generateSHA256(accountNumber || "");

      let financialData = await fetchFinancialData(
        FETCH_FINANCIAL_DATA + `?account-number-hash=${hashAcc}`,
        token
      );
      return financialData?.data[0];
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (accountNumber) {
      fetchLinkedAccountData(accountNumber).then(setLinkedAccountData);
    }
  }, [accountNumber]);

  useEffect(() => {
    if (accountData) {
      const filteredAccData = accountData?.filter(
        ({ accountType, balance }) =>
          (accountType == AccountTypes.EASY_ACCESS ||
            accountType == AccountTypes.NOTICE_ACCOUNT) &&
          balance > 0
      );
      setFilteredAccData(filteredAccData);
    }
  }, []);

  useEffect(() => {
    if (accountData && accountNumber) {
      const accData = accountData.find(
        (item) => item.accountNumber === accountNumber
      );
      if (!accData) return;
      const formattedBalance = formatBalance(accData.balance || 0);
      setSelectedData(accData);
      setDynamicDescriptionAccountName(
        accData?.accountName + " " + Strings?.detailsText
      );
      setIsNoticeAccount(accData?.accountType != undefined && accData?.accountType == 'NT')
      setSelectedOption((prev) =>
        prev !== `${accData.accountName} - ${formattedBalance}`
          ? `${accData.accountName} - ${formattedBalance}`
          : prev
      );
      setSelectedValue((prev) =>
        prev !== formattedBalance ? formattedBalance : prev
      );
    }
  }, [accountData, accountNumber]);

  useEffect(() => {
    (async () => {
      if (withdrawalSubmissionError == false) {
        if (!isSelectionError && !isWithdrawalError) {
          setLoadModalFlag(true);
          try {
            const token = localStorage.getItem("tokenVal") ?? "";

            if (linkedAccountData?.type == AccountTypes.NOTICE_ACCOUNT) {
              let postData = {
                withdrawalNoticeDetails: {
                  amount: currencyToNumConvertor(withdrawalAmount),
                  fromAccountNumber: linkedAccountData?.accountNumber,
                  toAccountNumber: linkedAccountData?.linkedAccount,
                  withdrawToAccount: "Linked Account",
                  channel: "Mobile", // Web Value throws error
                },
              };
              let withdrawalResult = await withdrawalNoticeAccount(
                WITHDRAWAL_AMOUNT_NOTICE_ACCOUNT,
                postData,
                token
              );
              if (withdrawalResult && typeof withdrawalResult === "object") {
                navigate(`/confirmWithdrawal`, {
                  state: {
                    from: withdrawalAmount,
                    withdrawalDate: withdrawalResult?.data?.withdrawalDate,
                    type: AccountTypes.NOTICE_ACCOUNT,
                  },
                });
              } else {
                setWithdrawalSubmissionError(true);
                setLoadModalFlag(false);
              }
            } else {
              let postData = {
                amount: currencyToNumConvertor(withdrawalAmount),
                fromAccount: {
                  accountNumber: linkedAccountData?.accountNumber,
                  accountName: linkedAccountData?.partyName,
                },
                toAccount: {
                  accountNumber: linkedAccountData?.linkedAccount,
                  accountName: linkedAccountData?.partyName,
                },
                reference: generateRandomAlphanumeric(16), // this is required
              };
              let withdrawalResult = await withdrawalEasyAccessAccount(
                WITHDRAWAL_AMOUNT_EASY_ACCESS_ACCOUNT,
                postData,
                token
              );
              if (withdrawalResult && typeof withdrawalResult === "object") {
                navigate(`/confirmWithdrawal`, {
                  state: {
                    from: withdrawalAmount,
                    type: AccountTypes.EASY_ACCESS,
                  },
                });
              } else {
                setWithdrawalSubmissionError(true);
                setLoadModalFlag(false);
              }
            }
          } catch (error) {
            setWithdrawalSubmissionError(true);
            setLoadModalFlag(false);
          }
        }
      }
    })();
  }, [tempFlag]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (!value) {
        setWithdrawalAmount("");
        return;
      }
      const numberPart = currencyToNumString(value);

      const decimalPointExists = numberPart.indexOf(".") !== -1;
      const sanitizedValue = decimalPointExists
        ? numberPart.replace(/[^0-9.]/g, "")
        : numberPart.replace(/[^0-9]/g, "");
      setWithdrawalAmount(sanitizedValue);
    },
    []
  );

  const onMouseLeaveWithdrawalFormatting = useCallback(() => {
    if (!withdrawalAmount) {
      setWithdrawalAmount(CURRENCY_DEFAULT_VALUE);
    } else if (!withdrawalAmount.includes(".")) {
      setWithdrawalAmount(CURRENT_SYMBOL + withdrawalAmount + ".00");
    } else {
      setWithdrawalAmount(formatCurrencyWithDecimals(withdrawalAmount));
    }
    setIsEditing(false);
  }, [withdrawalAmount, formatCurrencyWithDecimals]);

  const onMouseEnterWithdrawalFormatting = useCallback(() => {
    setIsEditing(true);
  }, []);

  const selectInputHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(e.target.value);

    if (e.target.selectedIndex > 0) {
      const accNumber = filteredAccData?.filter(
        (item: AccountData, index: number) =>
          index == e.target.selectedIndex - 1
      );
      setIsNoticeAccount(accNumber[0]?.accountType != undefined && accNumber[0]?.accountType == 'NT')
      if (accNumber) {
        setDynamicDescriptionAccountName(
          accNumber[0]?.accountName + " " + Strings?.detailsText
        );
        fetchLinkedAccountData(accNumber[0]?.accountNumber).then(
          setLinkedAccountData
        );
      }
    } else {
      setDynamicDescriptionAccountName("");
    }
  };

  const options = useMemo(
    () => [
      `${Strings.selectAccount} - ${CURRENCY_DEFAULT_VALUE}`,
      ...(filteredAccData.map(
        ({ accountName, balance }: any) =>
          `${accountName} - ${formatBalance(balance)}`
      ) || []),
    ],

    [filteredAccData]
  );

  const linkedAccountDetails = useMemo(() => {
    const { sortCode, accountNumber: linkedAccNum } =
      extractSortCodeAndAccount(linkedAccountData?.linkedAccount || "") || {};
    return [
      { key: Strings.payeeName, val: linkedAccountData?.partyName },
      { key: Strings.sortCodeStr, val: sortCode },
      { key: Strings.accountNumberStr, val: linkedAccNum },
    ];
  }, [linkedAccountData]);

  const isSelectionError = useMemo(
    () => withdrawalFlag && selectedValue == options[0],
    [selectedValue, withdrawalFlag]
  );

  const { isWithdrawalError, withdrawalErrorText } = useMemo(() => {
    if (withdrawalFlag) {
      if (
        withdrawalAmount == CURRENCY_DEFAULT_VALUE ||
        withdrawalAmount == ""
      ) {
        return {
          isWithdrawalError: true,
          withdrawalErrorText: Strings.withdrawalAmountErrorText,
        };
      } else if (
        currencyToNumConvertor(
          selectedValue.slice(selectedValue.indexOf(CURRENT_SYMBOL) + 1)
        ) -
          currencyToNumConvertor(withdrawalAmount) <
        (selectedData?.minimumBalance ?? 100)
      ) {
        return {
          isWithdrawalError: true,
          withdrawalErrorText: `${
            Strings.maximumWithdrawalAmountTransferError
          } ${formatBalance(
            currencyToNumConvertor(
              selectedValue.slice(selectedValue.indexOf(CURRENT_SYMBOL) + 1)
            ) - (selectedData?.minimumBalance ?? 100)
          )}`,
        };
      } else if (
        (selectedData?.withdrawalCounterCurrentYear ?? 0) >
          (selectedData?.noOfAnnualWithdrawals ?? 0) ||
        (selectedData?.withdrawalCounterCurrentMonth ?? 0) >
          (selectedData?.noOfMonthlyWithdrawals ?? 0) ||
        Number(localStorage.getItem("withdrawalCount")) > 5
      ) {
        return {
          isWithdrawalError: true,
          withdrawalErrorText: `${
            Strings.maxWithdrawalValidationText
          }${" "} ${5}${selectedData?.noOfAnnualWithdrawals ?? ""} ${
            selectedData?.noOfMonthlyWithdrawals ?? ""
          }`,
        };
      }
    }

    return { isWithdrawalError: false, withdrawalErrorText: "" };
  }, [withdrawalAmount, withdrawalSubmissionError, selectedValue]);

  const confirmWithdrawalHandler = () => {
    setWithdrawalFlag(true);
    setTempFlag(!tempFlag);
    if (
      selectedData?.blockWithdrawals == "yes" ||
      WITHDRAWAL_CUSTOM_ACCOUNT_STATUS?.includes(
        selectedData?.accountstate ?? ""
      )
    ) {
      setWithdrawalSubmissionError(true);
    } else {
      setWithdrawalSubmissionError(false);
    }
  };
  return (
    <>
      <LoadingModal show={loadModalFlag} size="sm" />
      <main className="mt-4 container">
        <section className={`${styles.withdrawContainer} `}>
          <header>
            <Breadcrumbs
              data={selectedData}
              previousPath={`/moveMoney/${selectedData?.accountNumber}`}
            />
            <h1 className={`${headingStyles.headingMedium} mt-2`}>
              {Strings.withdrawMoney}
            </h1>
          </header>
          <section>
            <div className="mb-2">{Strings.from} </div>
            <SelectInput
              labelTitle={false && "Select account"}
              id="userNameTitle"
              required
              className="rounded-0"
              onChange={selectInputHandler}
              selected={selectedOption || ""}
              options={options}
              isError={isSelectionError}
              errorMsg={Strings.selectAccountErrorText}
              aria-label="Select account"
            />
          </section>

          <section>
            <div className="mb-2">{Strings.linkedAccountDetails}</div>
            <div className={styles.linkedAccountBoxStyle}>
              {Strings.linkedAccount}
            </div>
            <div className={styles.linkedDetailsParent}>
              {linkedAccountDetails?.map((item, index) => (
                <div key={index} className={styles.linkedDetailsStyle}>
                  <div className="d-flex justify-between p-1 align-items-center justify-content-between">
                    <div className={styles.linkedAccountKeyStyle}>
                      <span>{item.key}</span>
                    </div>
                    <div className={styles.linkedAccountValueStyle}>
                      <span>{item.val}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
          <section>
            <div className="mb-2">{Strings.withdrawalAmount}</div>
            <input
              id="withdrawalAmount"
              type={Strings.defaultInputType}
              className={`form-control mb-2 ${
                isWithdrawalError && !isSelectionError && "input-error-border"
              }`}
              placeholder={Strings.withdrawalAmountPlaceholder}
              value={
                isEditing
                  ? withdrawalAmount
                  : withdrawalAmount || CURRENCY_DEFAULT_VALUE
              }
              onChange={handleInputChange}
              onMouseLeave={onMouseLeaveWithdrawalFormatting}
              onMouseEnter={onMouseEnterWithdrawalFormatting}
              aria-label="Enter withdrawal amount"
            />
            {isWithdrawalError && !isSelectionError && (
              <div className={`${headingStyles.errorHeadingSmallerStyle} py-1`}>
                {withdrawalErrorText}
              </div>
            )}
            {selectedValue != options[0] && (
              <div className={headingStyles.grayHeading}>
                {Strings.youCanWithdraw}{" "}
                {formatBalance(
                  currencyToNumConvertor(
                    selectedValue.slice(
                      selectedValue.indexOf(CURRENT_SYMBOL) + 1
                    )
                  ) - (selectedData?.minimumBalance ?? 100)
                )}
              </div>
            )}
          </section>
          {isNoticeAccount && <div className={styles.withdrawSubHeading}>{Strings.cancelHint}</div>}

          {withdrawalSubmissionError && (
            <div className={`${styles.withdrawSubHeading} text-danger`}>
              {Strings.withdrawalSubmissionError}{" "}
              <Link to="/contact-us" className="text-danger">
                <u>{Strings.contactUsStr}</u>
              </Link>
              .
            </div>
          )}
          <footer className={styles.continueButtonStyle}>
            <Button
              label={Strings.btnCancel}
              className="btn btn-secondary btn-md"
              onClick={() => navigate(-1)}
              aria-label="Cancel withdrawal"
            />
            <Button
              label={Strings.confirmWithdrawal}
              className={"btn btn-md btn-primary_one"}
              onClick={confirmWithdrawalHandler}
              aria-label="Confirm withdrawal"
            />
          </footer>
        </section>
      </main>
    </>
  );
};
export default WithdrawMoneyComponent;
