import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { FETCH_ACCOUNT } from "./url";

export interface Account {
  data: {
    accountName: string;
    accountNumber: string;
    accountOwner: string;
    accountStatus: string;
    accountType: string;
    aer: number;
    balance: number;
    interestRate: number;
    creationDate: string;
    interestEarned: number;
    lifetimeInterestEarned: number;
  };
}

export const fetchAccount = createAsyncThunk<{ data: Account }, string>(
  "accountdetails/fetchAccount",
  async (hashsedAccountNumber) => {
    try {
      const token = await localStorage.getItem("tokenVal");
      const response = await axios.get(
        `${FETCH_ACCOUNT}${hashsedAccountNumber}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      throw error;
    }
  }
);
