import React from "react";
import Strings from "../../../theme/string";

export const EmailValidationPopup = (props : any) => {
  const { email } = props;

  return (
    <div>
      <div>{Strings.pleaseConfirmEmailAddress}</div>
      <div>
        <b>{email} </b>
      </div>
      <br />
      <div>
        {Strings.dontWorryNotUsedInMarket}
      </div>
    </div>
  );
};
