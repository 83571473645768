import React from "react";
import Nav from "react-bootstrap/Nav";
import { NavItem } from "../../interfaces/headerSection.interface";
import { useNavigate } from "react-router-dom";

export const NavLink = ({ heading, navigation }: NavItem) => {
  const navigate = useNavigate();
  return (
    <Nav.Link
      onClick={() => navigate(navigation)}
      id={`navlink-${heading}`}
      aria-label={heading}
    >
      {heading}
    </Nav.Link>
  );
};
