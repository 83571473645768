import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchDashboardData } from "../../services/fetchDashboardData";
import { Account } from "../../services/fetchDashboardData";

interface DashboardState {
  data: Account[];
  isLoading: boolean;
  error: string | null;
}

const initialState: DashboardState = {
  data: [],
  isLoading: false,
  error: null,
};

const dashboardAccountSlice = createSlice({
  name: "dashboardAccount",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchDashboardData.fulfilled,
        (state, action: PayloadAction<{ data: Account[] }>) => {
          state.isLoading = false;
          state.data = action.payload.data;
        }
      )
      .addCase(fetchDashboardData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Failed to fetch data";
      });
  },
});

export default dashboardAccountSlice.reducer;
