import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MainStepperPageTitle } from "../../common/components/stepper/mainStepperPageTitle";
import { INITIAL_COUNT } from "../../common/constant/customConstant";
import { getSavingPublicProductAction } from "../../redux/actions/savingAccountPublicProductAction";
import { getStepperSubProductAction } from "../../redux/actions/stepperSubproductAction";
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks";
import Strings from "../../theme/string";
import { SubProductSelectedPlan } from "./subProductSelectedPlan";
import { SubProductSelectedPlanDescription } from "./subProductSelectedPlanDescription";

export const StepperChoosePlan = (props : any) => {
  const { id : productName } = useParams();
  const dispatch = useAppDispatch();
  const [selectedProduct, setSelectedProduct] = useState();
  const { stepperDetails, savingAccountPublicProduct } = useAppSelector((state: any) => state);
  const data = savingAccountPublicProduct?.savingAccountPublicProduct?.filter((item : any)=> {
    return item?.productFamily?.toLowerCase().search(productName?.split(' ')[0].toLowerCase()) >= 0
  });
  
  const tmpProduct = selectedProduct || data?.length > 0 && data[0];
  const updateFadeId = (e: any) => {
    setSelectedProduct(e);
  };

  useEffect(() => {
    dispatch<any>(getStepperSubProductAction());
    dispatch<any>(getSavingPublicProductAction());
    }, []);
  
  return (
    <>
      <MainStepperPageTitle />
      <div>
        <SubProductSelectedPlan onClick={(e:React.ChangeEvent<HTMLInputElement>) => updateFadeId(e)} data={data} tmpProduct={tmpProduct} />
        {stepperDetails?.buttonValidatonIsRequired && Object.keys(stepperDetails?.stepperChoosedPlan).length == INITIAL_COUNT && <div className="fst-italic font-size-12px text-danger fw-bold">
        {Strings.unableToFindRelatedProduct}
          </div>
          }
          {Object.keys(stepperDetails.stepperSubProduct).length > 0 && <div id="example-fade-text" className="selectedProductDescription">
            <SubProductSelectedPlanDescription tmpProduct={tmpProduct}
            />
          </div>}
      </div>
    </>
  );
};