import { useEffect, useState, useCallback } from "react";
import { StepperChoosePlan } from "../../../screens/choosePlan/stepperChoosePlan";
import StepperSummaryBox from "../../../screens/summaryBox/stepperSummaryBox";
import { StepperButton } from "./stepperButton";
import { StepperFooter } from "./stepperFooter";
import { StepperTitle } from "./stepperTitle";
import StepperFscsAndTerms from "../../../screens/submissionAndVerification/stepperFscsAndTerms";
import StepperLinkBankAccount from "../../../screens/linkBankAccount/stepperLinkBankAccount";
import { clearStates } from "../../../redux/reducer/stepperSubProductSlice";
import { useAppDispatch } from "../../../redux/redux-hooks";
import StepperReviewComponent from "../../../screens/choosePlan/ReviewStepper/stepperReviewComponent";
import StepperPersonalDetails from "../../../screens/stepperPersonalDetails/stepperPersonalDetails";
import { StepperSteps } from "../../../enums/generalStepper.enums";

export const StepperRunner = () => {
  const dispatch = useAppDispatch();
  const [activeStep, setActiveStep] = useState<number>(0);

  useEffect(() => {
    dispatch(clearStates());
  }, []);

  const renderStepContent = useCallback(() => {
    switch (activeStep) {
      case StepperSteps.CHOOSE_PLAN:
        return <StepperChoosePlan />;
      case StepperSteps.SUMMARY_BOX:
        return <StepperSummaryBox />;
      case StepperSteps.PERSONAL_DETAILS:
        return <StepperPersonalDetails />;
      case StepperSteps.LINK_BANK_ACCOUNT:
        return <StepperLinkBankAccount />;
      case StepperSteps.REVIEW:
        return <StepperReviewComponent />;
      case StepperSteps.FSCS_AND_TERMS:
        return <StepperFscsAndTerms />;
      default:
        return null;
    }
  }, [activeStep]);

  return (
    <>
      <div className="mt-4 container">
        <StepperTitle activeStep={activeStep} />
        <StepperButton activeStep={activeStep} />
        {renderStepContent()}
        <StepperFooter {...{ activeStep, setActiveStep }} />
      </div>
    </>
  );
};
