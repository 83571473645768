import React from "react";
import styles from "../../../../styleModules/reviewStepper.module.scss";
import { GenericViewFormComponentProps } from "../../../../interfaces/reviewStepper.interface";

// THIS COMPONENT CAN BE USED COMMONLY FOR ALL THE READ ONLY FIELDS WITHOUT EDIT BUTTON

const GenericViewFormComponent = React.memo(
  ({ formData,profileFlag = false, showRequiredMarker  = true}: GenericViewFormComponentProps) => {
    return (
      <div className="w-100 flex-wrap">
        <div className="col-md-8 col-lg-6">
          <div>
            <div className="row gap-3">
              {formData.map((data, index) => (
                <div key={index}>
                  <label htmlFor={`formField-${index}`} className="form-label">
                    {data.title}
                    <span className="text-danger">{showRequiredMarker && ` *`}</span>
                  </label>
                  <div
                    id={`formField-${index}`}
                    className={styles.genericFormFieldStyle}
                    aria-label={`${data.title} field`}
                  >
                    <div className={styles.genericFormFieldTextStyle}>
                      {!Array.isArray(data.fieldData)
                        ? data.fieldData
                        : data.fieldData.map((item, idx) => (
                            <div key={idx}>{item}</div>
                          ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default GenericViewFormComponent;
