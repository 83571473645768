import React, {
  useReducer,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { ActionTypeEnum } from "../../../enums/reviewStepper.enums";
import { useAppDispatch, useAppSelector } from "../../../redux/redux-hooks";
import { RootState } from "../../../interfaces/reviewStepper.interface";

import Strings from "../../../theme/string";
import commonHeading from "../../../styleModules/commonHeading.module.scss";
import micsStyles from "../../../styleModules/commonMicsStyles.module.scss";
import ProfileProcessingSectionBox from "../profileAddressComponents/profileProcessingBox";
import { SaveButtonComponent } from "../../choosePlan/ReviewStepper/ReviewSubComponents/miscComponents/saveButtonComponent";
import { EditButtonComponent } from "../../choosePlan/ReviewStepper/ReviewSubComponents/miscComponents/editButtonComponent";
import GenericViewFormComponent from "../../choosePlan/ReviewStepper/ReviewSubComponents/GenericViewFormComponent";
import { HelperLinkBankAccount } from "../../linkBankAccount/helperLinkBankAccount";
import {
  numberFormat,
  sortCodeAutoFormat,
} from "../../../common/modules/validation";
import {
  updateValidationIsRequired,
  updatedLinkUKBank,
} from "../../../redux/reducer/stepperSubProductSlice";
import { INITIAL_COUNT } from "../../../common/constant/customConstant";
import { ProfileLinkedAccountProceedingConfirmation } from "./profileLinkedAccountProceedingConfirmation";
import { updateLinkedAccountDetails } from "../../../services/fetchLinkedAccount";
import { UPDATE_LINKED_ACC } from "../../../services/url";
import headingStyles from "../../../styleModules/commonHeading.module.scss";
import { Link } from "react-router-dom";
import { ErrorCodes } from "../../../enums/errorCodes.enums";
import ProfileLinkedAccountSuccessBox from "./profileLinkedAccountSuccessBox";
import { combineSortCodeAndAccount } from "../../../common/utils/combinedSortcodeAndAcc";
import { AddressErrorResponse } from "../../../interfaces/partyDetails.interface";

type ActionType =
  | { type: ActionTypeEnum.SET_EDIT_MODE }
  | { type: ActionTypeEnum.RESET_EDIT_MODE };

const initialEditMode = {
  linkedDetails: false,
};

const editReducer = (state: typeof initialEditMode, action: ActionType) => {
  switch (action.type) {
    case ActionTypeEnum.SET_EDIT_MODE:
      return { ...state, linkedDetails: true };
    case ActionTypeEnum.RESET_EDIT_MODE:
      return { ...state, linkedDetails: false };
    default:
      return state;
  }
};

const ProfileLinkedAccountParentComponent = () => {
  const [editMode, dispatch] = useReducer(editReducer, initialEditMode);
  const dispatchApp = useAppDispatch();
  const stepperDetails = useAppSelector(
    (state: RootState) => state.stepperDetails
  );
  const mainObj = stepperDetails?.stepperLinkUKBank;

  const { accountNumber, sortCode } = useAppSelector((state: RootState) => ({
    accountNumber:
      state.stepperDetails?.stepperLinkUKBank?.accountNumber?.value,
    sortCode: state.stepperDetails?.stepperLinkUKBank?.sortCode?.value,
  }));

  const [linkedAccountApiErrorFlag, setLinkedAccountApiError] =
    useState<boolean>(false);

  const [processingError, setProcessingError] = useState<boolean>(false);
  const [popupFlag, setPopupFlag] = useState<boolean>(false);
  const [linkedAccountLoader, setLinkedAccountLoaderLoader] =
    useState<boolean>(false);
  const [
    linkedAccountSuccessfulUpdateModalFlag,
    setLinkedAccountSuccessfulUpdateModalFlag,
  ] = useState<boolean>(false);

  const validationIsRequired = (boolVal: boolean) => {
    dispatchApp(updateValidationIsRequired(boolVal));
  };
  const proceedAheadHandler = () => {
    dispatch({ type: ActionTypeEnum.SET_EDIT_MODE });
    togglePopup();
  };

  const editModeHandler = useCallback(() => {
    togglePopup();
  }, []);

  const handleLinkedAccountProcessingError = () => {
    setLinkedAccountLoaderLoader(false);
    setProcessingError(true);
    setLinkedAccountSuccessfulUpdateModalFlag(false);

    const fields = [
      {
        field: "accountNumber",
        value: stepperDetails?.stepperLinkUKBank?.accountNumber?.value,
      },
      {
        field: "sortCode",
        value: stepperDetails?.stepperLinkUKBank?.sortCode?.value,
      },
    ];
    fields.forEach(({ field, value }) => {
      dispatchApp(
        updatedLinkUKBank({
          fieldName: field,
          value,
          draftValue: value,
          isValidate: true,
          isdraftValidate: true,
        })
      );
    });
    dispatch({ type: ActionTypeEnum.RESET_EDIT_MODE });
  };

  const saveButtonHandler = () => {
    const checkValidation = () => {
      const tmpAccountNumber =
        stepperDetails?.stepperLinkUKBank?.accountNumber?.isValidate;
      const tmpSortCode =
        stepperDetails?.stepperLinkUKBank?.sortCode?.isValidate;

      if (tmpAccountNumber && tmpSortCode) {
        validationIsRequired(false);
        return true;
      }
      validationIsRequired(true);
      return false;
    };

    const handleErrorResponse = (response: AddressErrorResponse) => {
      if (
        response?.data?.errorCode ===
        ErrorCodes.LINKED_ACCOUNT_UPDATE_RESTRICTION_ERROR
      ) {
        setLinkedAccountApiError(true);
        setProcessingError(false);
      } else {
        setLinkedAccountApiError(false);
        setProcessingError(true);
      }
      setLinkedAccountLoaderLoader(false);
      setLinkedAccountSuccessfulUpdateModalFlag(false);

      const fields = [
        {
          field: "accountNumber",
          value: stepperDetails?.stepperLinkUKBank?.accountNumber?.value,
        },
        {
          field: "sortCode",
          value: stepperDetails?.stepperLinkUKBank?.sortCode?.value,
        },
      ];
      fields.forEach(({ field, value }) => {
        dispatchApp(
          updatedLinkUKBank({
            fieldName: field,
            value: value,
            draftValue: value,
            isValidate: true,
            isdraftValidate: true,
          })
        );
      });
      dispatch({ type: ActionTypeEnum.RESET_EDIT_MODE });
    };

    if (checkValidation()) {
      (async () => {
        try {
          setLinkedAccountLoaderLoader(true);
          const token = localStorage.getItem("tokenVal") ?? "";
          if (token) {
            const updateLinkedAccountResponse =
              await updateLinkedAccountDetails(
                UPDATE_LINKED_ACC,
                {
                  caseDetail: {
                    type: "Change Linked Account",
                    origin: "Mobile",
                    description: "Change Linked Account From Mobile App",
                    caseType: "Change Linked Account",
                    newAccountNumber: combineSortCodeAndAccount(
                      stepperDetails?.stepperLinkUKBank?.sortCode?.value,
                      stepperDetails?.stepperLinkUKBank?.accountNumber?.value
                    ),
                  },
                },
                token
              );
            if ("error" in updateLinkedAccountResponse) {
              handleErrorResponse(updateLinkedAccountResponse);
            } else {
              const fields = [
                {
                  field: "accountNumber",
                  draftValue:
                    stepperDetails?.stepperLinkUKBank?.accountNumber
                      ?.draftValue,
                },
                {
                  field: "sortCode",
                  draftValue:
                    stepperDetails?.stepperLinkUKBank?.sortCode?.draftValue,
                },
              ];
              fields.forEach(({ field, draftValue }) => {
                dispatchApp(
                  updatedLinkUKBank({
                    fieldName: field,
                    value: draftValue,
                    draftValue: draftValue,
                    isValidate: true,
                    isdraftValidate: true,
                  })
                );
              });
              setLinkedAccountSuccessfulUpdateModalFlag(true);
              setLinkedAccountLoaderLoader(false);
              setLinkedAccountApiError(false);
              setProcessingError(false);
              dispatch({ type: ActionTypeEnum.RESET_EDIT_MODE });
            }
          } else {
            handleLinkedAccountProcessingError();
          }
        } catch (error) {
          handleLinkedAccountProcessingError();
        }
      })();
    }
  };

  const personalDetailsReadData = useMemo(
    () => [
      {
        title: `${Strings.sortCodeStr}`,
        fieldData: `${sortCode}`,
      },
      {
        title: `${Strings.accountNumberStr}`,
        fieldData: `${accountNumber}`,
      },
    ],
    [sortCode, accountNumber]
  );

  const dispatching = (
    key: string,
    tmpVal: string,
    isValid: boolean,
    saved: boolean
  ) => {
    const value = saved ? mainObj?.[key]?.draftValue : mainObj?.[key]?.value;
    dispatchApp(
      updatedLinkUKBank({
        fieldName: key,
        value,
        draftValue: tmpVal,
        isValidate: isValid,
      })
    );
  };

  const handleSortCodeInput = (value: string, key: string, saved: boolean) => {
    const formattedSortCode = sortCodeAutoFormat(value);
    const isValid = value.replace(/-/g, "").length === Strings.six;
    dispatching(key, formattedSortCode, isValid, saved);
  };

  const handleAccountNumberInput = (
    value: string,
    key: string,
    saved: boolean
  ) => {
    const formattedAccount = numberFormat(value);
    const isValid = formattedAccount.length === Strings.eight;
    dispatching(key, formattedAccount, isValid, saved);
  };

  const updateHandler = (param: string, tmpVal: string, saved = false) => {
    if (param === Strings.accountNumber) {
      handleAccountNumberInput(tmpVal, Strings.accountNumber, saved);
    } else if (param === Strings.sortCode) {
      handleSortCodeInput(tmpVal, Strings.sortCode, saved);
    }
  };

  const checkingSortCodeHavingError = (param: string, lengthCount: number) => {
    if (
      true &&
      mainObj?.[param]?.draftValue?.replace(/-/g, "").length != lengthCount
    ) {
      return true;
    } else if (
      mainObj?.[param]?.value?.replace(/-/g, "").length != lengthCount
    ) {
      return true;
    } else return false;
  };

  const togglePopup = useCallback(() => setPopupFlag(!popupFlag), [popupFlag]);

  return (
    <>
      {popupFlag && (
        <ProfileLinkedAccountProceedingConfirmation
          show={true}
          onClick={togglePopup}
          proceedAheadHandler={proceedAheadHandler}
        />
      )}
      <div>
        <div className="mb-3 mt-4">
          <div className="col-6">
            <div>
            <div
              className={`${commonHeading.commonBoldHighlightedHeading} justify-content-between`}
            >
              {Strings.linkedAccount}
              {editMode.linkedDetails && (
                <>
                  {linkedAccountLoader ? (
                    <>
                      <ProfileProcessingSectionBox />
                    </>
                  ) : (
                    <SaveButtonComponent
                      onClick={saveButtonHandler}
                      aria-label="Save linked account details"
                    />
                  )}
                </>
              )}

              {!editMode.linkedDetails && (
                <>
                  <section className="d-flex">
                    <EditButtonComponent
                      onClick={editModeHandler}
                      aria-label="Edit linked account details"
                    />
                  </section>
                </>
              )}
            </div>

            <div className={micsStyles.customLine}></div>
            </div>
          </div>
        </div>

        {editMode.linkedDetails && (
          <div>
            <div className="gap-4 mb-3">
              <HelperLinkBankAccount
                {...{ mainObj, handleSortCodeInput, stepperDetails }}
                label={Strings.linkBankSortCodeLabel}
                placeholder={Strings.linkBankSortCodePlaceholder}
                updateInput={updateHandler}
                helperText={Strings.linkBankSortCodeHelperText}
                maxLength={Strings.eight}
                isError={checkingSortCodeHavingError(
                  Strings.sortCode,
                  Strings.six
                )}
                value={mainObj?.["sortCode"]?.draftValue}
                errorMessage={
                  mainObj?.sortCode?.draftValue?.replace(/-/g, "").length ==
                  INITIAL_COUNT
                    ? Strings.linkBankSortCodeBlankError
                    : Strings.linkBankSortCodeError
                }
                fieldName={Strings.sortCode}
                editFlag={false}
                isEditButtonRequired={true}
              />
            </div>

            <div className="gap-4 mb-3">
              <HelperLinkBankAccount
                {...{ mainObj, handleSortCodeInput, stepperDetails }}
                label={Strings.linkBankAccountNumberLabel}
                placeholder={Strings.linkBankAccountNumberPlaceholder}
                updateInput={updateHandler}
                helperText={Strings.linkBankAccountNumberHelperText}
                maxLength={Strings.eight}
                isError={checkingSortCodeHavingError(
                  Strings.accountNumber,
                  Strings.eight
                )}
                value={mainObj?.["accountNumber"]?.draftValue}
                errorMessage={
                  mainObj?.accountNumber?.draftValue?.length == INITIAL_COUNT
                    ? Strings.linkBankAccountNumberBlankError
                    : Strings.linkBankAccountNumberError
                }
                fieldName={Strings.accountNumber}
                editFlag={false}
                isEditButtonRequired={true}
              />
            </div>
          </div>
        )}
        {!editMode.linkedDetails && (
          <>
            <GenericViewFormComponent
              formData={personalDetailsReadData}
              profileFlag={true}
              showRequiredMarker={false}
            />
          </>
        )}
      </div>
      {linkedAccountLoader && (
        <>
          <em aria-live="polite">
            {Strings.processing}
            {Strings.continueDot}
          </em>
        </>
      )}
      {processingError && (
        <section
          className={`${headingStyles.errorHeadingSmallerStyle} my-2 col-md-8 col-lg-6`}
          role="alert"
          aria-live="assertive"
        >
          {Strings.addressProcessingError}
          <Link to="/contact-us" className="text-danger">
            {" "}
            <b>
              {" "}
              <u>{Strings.contactUsStr}.</u>
            </b>
          </Link>{" "}
        </section>
      )}

      {linkedAccountApiErrorFlag && (
        <section
          className={`${headingStyles.errorHeadingSmallerStyle} my-2 col-md-8 col-lg-6`}
          role="alert"
          aria-live="assertive"
        >
          {Strings.linkedErrorOne}
          <Link to="/contact-us" className="text-danger">
            {" "}
            <b>
              {" "}
              <u>{Strings.contactUsStr}</u>
            </b>
          </Link>{" "}
          {Strings?.linkedAccountUpdateErrorTwo}
        </section>
      )}

      {linkedAccountSuccessfulUpdateModalFlag && (
        <ProfileLinkedAccountSuccessBox
          setLinkedAccountSuccessfulUpdateModalFlag={
            setLinkedAccountSuccessfulUpdateModalFlag
          }
        />
      )}
    </>
  );
};

export default ProfileLinkedAccountParentComponent;
