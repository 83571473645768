import { createSlice } from "@reduxjs/toolkit";
import { getLifeStyleDetailsAction } from "../actions/lifestyleAction";

const initialState = {
  lifeStyleObj: []
};

export const lifeStyleSlice = createSlice({
  name: "lifeStyle",
  initialState,
  reducers: {
    clearStates: () => initialState,
  }, 
  extraReducers: (builder) => {
    builder.addCase(getLifeStyleDetailsAction.fulfilled, (state : any, { payload }) => {
      // TODO always keep in mind to take care wrong payload or empty payload        
      state.lifeStyleObj = payload
    });
  }
});

export const { clearStates } =
  lifeStyleSlice.actions;

export default lifeStyleSlice.reducer;