import { useEffect } from "react";
import { getCountryListAction } from "../../redux/actions/formSelectListsAction";
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks";
import { CitizenShipDisplay } from "../personalInformation/personalInformationFields/citizenShipDisplay";
import { CitizenShipTaxDisplay } from "../personalInformation/personalInformationFields/citizenShipTaxDisplay";

export const StepperCitizenShipNdTaxInfromation = ({
  editFlag = false,
  isEditBtnRequired = true,
  isHeadingRequired = true,
}) => {
  const isExtraFiledRequired = !isHeadingRequired;
  const dispatch = useAppDispatch();
  const { countryList, stepperDetails } = useAppSelector((state: any) => state);
  const mainObj = stepperDetails?.citizenshipAndTaxSection;
  const tmpCountry =
    countryList?.countryList?.data?.records?.countriesAndStates?.countries;
  const Country = tmpCountry?.map((item: any) => {
    return { ...item, value: item.isoCode };
  });

  useEffect(() => {
    dispatch<any>(getCountryListAction());
  }, []);

  return (
    <>
      <div className="linkBankSection">
        <CitizenShipDisplay
          {...{
            mainObj,
            editFlag,
            stepperDetails,
            Country,
            isEditBtnRequired,
            isHeadingRequired,
          }}
        />
        <CitizenShipTaxDisplay
          {...{
            mainObj,
            editFlag,
            stepperDetails,
            Country,
            isEditBtnRequired,
            isExtraFiledRequired,
          }}
        />
      </div>
    </>
  );
};
