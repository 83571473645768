import AddDepositIcon from "../../assest/add-deposit-icon.svg";
import ArrowLeftShort from "../../assest/arrow-left-short.svg";
import ArrowRightShort from "../../assest/arrow-right-short.svg";
import { Img } from "../../common/basic components/img";
import styles from "../../styleModules/dashboard.module.scss";
import {
  fetchAccountTransactions,
  Transaction,
} from "../../services/fetchAccountTransactions";
import { useAppSelector } from "../../redux/redux-hooks";
import { formatDateWithoutTime } from "../../common/utils/dashboardButtonsConditions";
import { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { generateSHA256 } from "../../common/utils/sha256Generator";
import { PAGINATION_ITEMS_COUNT } from "../../common/constant/accountDetailsConstant";

function TransactionWidget() {
  const dispatch = useDispatch();
  const { accountNumber } = useParams<{ accountNumber: string }>();
  const [hashedAccount, setHashedAccount] = useState<string>("");
  const [activePage, setActivePage] = useState(0);

  const { data: transactions, total } = useAppSelector(
    (state: any) => state.transaction
  );

  useEffect(() => {
    if (accountNumber) {
      generateSHA256(accountNumber)
        .then((hashedAccount) => {
          try {
            setHashedAccount(hashedAccount);
            dispatch<any>(
              fetchAccountTransactions({ hashedAccount, activePage })
            );
          } catch (err) {
            console.error("Error fetching transaction via pagination: ", err);
          }
        })
        .catch((err) => console.error("Error generating SHA-256 hash:", err));
    }
  }, [activePage]);

  const paginationHandler = (number?: any, prevOrNext?: string) => {
    if (number >= 0) {
      setActivePage(number);
    }
  };

  const totalPage = Math.ceil(total / PAGINATION_ITEMS_COUNT);

  let items = [];
  for (let number = 0; number < totalPage; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === activePage}
        onClick={() => paginationHandler(number)}
      >
        {number + 1}
      </Pagination.Item>
    );
  }

  return (
    <>
    <div
      key={"account.accountNumber"}
      className={`border col-12 col-md-6 col-lg-3 width-100-percent`}
    >
      {transactions.map((item: Transaction) => (
        <div
          className="transactionSection border-top py-3 px-3"
          key={item.transactionDateTime}
        >
          <div className="row">
            <div className="col-8 contentAreaCopy">
              <div className="row">
                <div className="col-1">
                  <Img src={AddDepositIcon} />
                </div>
                <div className="col-11 ps-2">
                  <div className={styles.transactiontitle}>
                    <span>{item.description}</span>
                  </div>
                  <div className={styles.transactionDate}>
                    <span>
                      {formatDateWithoutTime(item.transactionDateTime)}
                    </span>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 iconAreaCopy">
              <div className={styles.transactionAmount}>
                {item.amount > 0 ? "+" : "-"}£{Math.abs(item.amount)}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
    <div className="transaction-pagination">
        <Pagination>
          <Pagination.Prev
            onClick={() => paginationHandler((activePage - 1), "prev")}
            disabled={activePage === 0}
            className="prev-arrow-pagination pagination-arrow"
          >
            <Img src={ArrowLeftShort} />
          </Pagination.Prev>
          {items}
          <Pagination.Next
            onClick={() => paginationHandler((activePage + 1), "next")}
            disabled={activePage === (totalPage - 1)}
            className="next-arrow-pagination pagination-arrow"
          >
            <Img src={ArrowRightShort} />
          </Pagination.Next>
        </Pagination>
      </div>
    </>
  );
}

export default TransactionWidget;
