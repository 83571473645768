import Strings from "../../theme/string";

// Numeric Constant
export const MAX_STEPPER_COUNT = 7;
export const INITIAL_COUNT = 0;
export const ARRAY_INITIAL_COUNT = -1;
export const USER_AGE = 18;
export const NINE_COUNTER = 9;
export const EIGHTEEN_YEARS_IN_SEC = 24*60*60*1000*365*18;
export const STEPPER_USER_DETAILS_FIELD = [
    "title",
    "firstName",
    "lastName",
    "dateOfBirth",
    "email",
    "mobileWithoutCode",
    "addressline1",
    "posttown",
    "postcode"
]
export const VERIFY_EMPLOYEE_OTHER_JOB = [Strings.employmentStatusEmployed, Strings.employmentStatusSelfEmployeed, Strings.employmentStatusRetired];

export const EMPLOYMENT_STATUS_RADIO_CONST = [
    Strings.employmentStatusEmployed,
    Strings.employmentStatusSelfEmployeed,
    Strings.employmentStatusRetired,
    Strings.employmentStatusUnemployed,
    Strings.employmentStatusStudent,
    Strings.employmentStatusHomemaker
];

export const INCOME_RADIO_CONST = [
    Strings.incomeRange1,
    Strings.incomeRange2,
    Strings.incomeRange3,
    Strings.incomeRange4,
    Strings.incomeRange5
]

//Hindered Url

export const HINDERED_URLS = ["/onBoardingStart","/verifyCustomer",'/memorableWord']


// Route Params Constant

export const MEMORABLE_WORD_ROUTE ="memorableWord"

export const DESIGNATION_LIST = ["Select", "Mr.", "Mrs.", "Miss.", "Prof.", "Dr."]

export const PART_OF_ADDRESS = ["addressline2", "addressline3", "addressline4"]
export const CURRENCY_DEFAULT_VALUE = "£0.00";
export const CURRENT_SYMBOL ="£"
export const WITHDRAWAL_CUSTOM_ACCOUNT_STATUS = ["Locked","Blocked"]

export const STEPPER_TITLES = [
    " - Choose an account",
    " - Summary Box & Privacy Information",
    " - Personal details",
    " - Link your UK bank account.",
    " - Review details",
    " - Employment Details",
    " - FSCS protection and terms & conditions",
  ]

 export const TOTAL_STEPS = 6;
 export const SUGGESTION_INPUT_MIN_VAL = 3;
 export const SUGGESTION_INPUT_MAX_VAL= 7;