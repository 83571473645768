export const summaryBoxQuery = JSON.stringify({
    query: `{
  	summaryBox(id: "2jBVfwJc3q74pSP9vMfLvt"){
    expandableInfosCollection{
      items{
        heading
        mainContent
      }
    }
  }  
}`,
    variables: {}
  });
