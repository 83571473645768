import { FunctionComponent, useEffect, useState } from "react";
import Select from "react-select";
import { Input } from "../../common/basic components/input";
import { SelectInput } from "../../common/basic components/selectInput";
import {
  EMPLOYMENT_STATUS_RADIO_CONST,
  INCOME_RADIO_CONST,
  VERIFY_EMPLOYEE_OTHER_JOB,
} from "../../common/constant/customConstant";
import { JOB_ROLE_VALIDATION } from "../../common/constant/regexConstants";
import { findValueInArray } from "../../common/modules/findValueInArray";
import { getIndustriesListAction } from "../../redux/actions/formSelectListsAction";
import { updateEmployeeDetails } from "../../redux/reducer/stepperSubProductSlice";
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks";
import { getEmploymentData } from "../../services/appApi";
import Strings from "../../theme/string";
import styles from "../../styleModules/reviewStepper.module.scss";
import { EditButtonComponent } from "../choosePlan/ReviewStepper/ReviewSubComponents/miscComponents/editButtonComponent";
import { SaveButtonComponent } from "../choosePlan/ReviewStepper/ReviewSubComponents/miscComponents/saveButtonComponent";
import commonHeadingStyles from "../../styleModules/commonHeading.module.scss";
import micsStyles from "../../styleModules/commonMicsStyles.module.scss";
import commonStyles from "../../styleModules/commonHeading.module.scss"

interface EmploymentContent {
  data: {
    description: string;
  };
  modalData: {
    title: string;
    description: string;
  };
}

interface Props {
  editFlag?: boolean;
  isEditBtnRequired?: boolean;
}

const StepperEmploymentDetails = ({
  editFlag = false,
  isEditBtnRequired = true,
}: Props) => {
  const dispatch = useAppDispatch();
  const { industriesList: IndustriesReduxState, stepperDetails } =
    useAppSelector((state: any) => state);
  const tempIndustries = IndustriesReduxState?.industriesList?.data?.industries;
  const mainObj = stepperDetails?.employmentInformationSection;
  const INDUSTRIES_LIST = tempIndustries?.map((item: any) => {
    return { ...item, value: item.key, label: item.value };
  });

  const [employmentContent, setEmploymentContent] =
    useState<EmploymentContent>();

  const [isEmployeeStatusEditEnabled, setEmployeeStatusIsEditEnabled] =
    useState(false);
  const [isWhatIndustryEditEnabled, setWhatIndustryIsEditEnabled] =
    useState(false);
  const [isJobRoleEditEnabled, setJobRoleIsEditEnabled] = useState(false);
  const [isAnualIncomeEditEnabled, setAnualIncomeIsEditEnabled] =
    useState(false);

  const [dataSaved, setDataSaved] = useState(false);

  useEffect(() => {
    dispatch<any>(getIndustriesListAction());
    dispatching(
      "employeeStatus",
      mainObj?.employeeStatus?.value ?? EMPLOYMENT_STATUS_RADIO_CONST[0],
      true
    );
    dispatching("incomeRange", INCOME_RADIO_CONST[0]?.toLowerCase(), true);
    apiHandler();
  }, []);

  const dispatching = (key: string, tmpVal: string, isValid: boolean) => {
    let draftValue = {};
    if(editFlag)
      draftValue = {draftValue : tmpVal} 
    dispatch<any>(
      updateEmployeeDetails({
        fieldName: key,
        value: editFlag ? mainObj?.[key]?.value : tmpVal,
        isValidate: isValid,
        ...draftValue
      })
    );
  };

  function checkOtherJobIsSelected() {
    //Verify if employment is not student, unemployeed or homemaker
    if (
      findValueInArray(
        VERIFY_EMPLOYEE_OTHER_JOB,
        mainObj?.employeeStatus?.value ?? EMPLOYMENT_STATUS_RADIO_CONST[0]
      )
    )
      return true;
    return false;
  }

  const apiHandler = async () => {
    let apiData = getEmploymentData();
    if (apiData) {
      setEmploymentContent(apiData);
    } else {
      console.error("Error fetching employment details data!");
    }
  };

  const updateJobRoleHandler = (tmpRol: string) => {
    const isValidate = JOB_ROLE_VALIDATION.test(tmpRol);
    dispatching("jobRole", tmpRol, isValidate);
  };

  const editEmployeeStatusHandler = () => {
    setDataSaved(!dataSaved);
    setEmployeeStatusIsEditEnabled((prev) => !prev);
    setJobRoleIsEditEnabled(false);
    setAnualIncomeIsEditEnabled(false);
  };

  const editWhatIndustryHandler = () => {
    setDataSaved(!dataSaved);
    setWhatIndustryIsEditEnabled((prev) => !prev);
    setJobRoleIsEditEnabled(false);
    setAnualIncomeIsEditEnabled(false);
  };

  const editJobRoleHandler = () => {
    if (mainObj?.jobRole?.value) {
      setDataSaved(!dataSaved);
      setJobRoleIsEditEnabled((prev) => !prev);
      setEmployeeStatusIsEditEnabled(false);
      setWhatIndustryIsEditEnabled(false);
    }
  };

  const editAnualIncomeHandler = () => {
    setDataSaved(!dataSaved);
    setAnualIncomeIsEditEnabled((prev) => !prev);
    setEmployeeStatusIsEditEnabled(false);
    setWhatIndustryIsEditEnabled(false);
  };

  const enableNdDisableField = () => {
    if (dataSaved) return true;

    if (editFlag) {
      const editableFields = [
        isEmployeeStatusEditEnabled,
        isWhatIndustryEditEnabled,
        isJobRoleEditEnabled,
        isAnualIncomeEditEnabled,
      ];
      const editableCount = editableFields.filter(Boolean).length;
      return editableCount <= 1;
    }
    return true;
  };

  return (
    <div className="employementDetailsSection">
      <div className="row">
        {!editFlag && (
          <div className="col-md-8 col-lg-6">
            <p className="new-line lh-base">
              {employmentContent?.data?.description}
            </p>
          </div>
        )}
        {editFlag && (
          <div className="d-flex align-items-center gap-3 mb-3 mt-2">
            <div className="col-md-8 col-lg-6">
              <div
                className={`${commonHeadingStyles.commonBoldHighlightedHeading} mb-2`}
              >
                {Strings.employementInfoText}
              </div>
              <div className={micsStyles.customLine}></div>
            </div>
          </div>
        )}
      </div>
      <>
        <div>
          {editFlag && (
            <div className="row">
              <div className="d-flex col-md-8 col-lg-6 align-items-center gap-4">
                <div>
                  <label className="form-label">
                    {Strings.employmentStatusRadioTitle}
                    <span className="text-danger">{isEmployeeStatusEditEnabled &&` *`}</span>
                  </label>
                </div>
                {isEmployeeStatusEditEnabled && (
                  <div className="mb-3">
                    <SaveButtonComponent
                      onClick={() => editEmployeeStatusHandler()}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {editFlag && !isEmployeeStatusEditEnabled && (
            <div className="col-md-8 col-lg-6 mt-0 mb-3">
              <div className="d-flex">
                <div
                  id={`formField-isUKCountry`}
                  className={styles.genericFormFieldStyle}
                  aria-label={`isUKCountry field`}
                >
                  <div className={styles.genericFormFieldTextStyle}>
                    {mainObj?.employeeStatus?.value}
                  </div>
                </div>
                {isEditBtnRequired && (
                  <EditButtonComponent
                    onClick={() => editEmployeeStatusHandler()}
                  />
                )}
              </div>
            </div>
          )}
          {editFlag && isEmployeeStatusEditEnabled && (
            <div className="mb-2">
              <div className="col-md-8 col-lg-6">
                {!editFlag && (
                  <label className="form-label">
                    {Strings.employmentStatusRadioTitle}
                    <span className="text-danger"> * </span>
                  </label>
                )}
                <>
                  <SelectInput
                    labelTitle={!true && "Title"}
                    id="userNameTitle"
                    required
                    className="rounded-0"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      dispatching("employeeStatus", e.target.value, true)
                    }
                    selected={
                      mainObj?.employeeStatus?.value ??
                      EMPLOYMENT_STATUS_RADIO_CONST[0]
                    }
                    options={EMPLOYMENT_STATUS_RADIO_CONST}
                    isError={false}
                    errorMsg={Strings.pleaseSelectAnyValue}
                  />
                </>
              </div>
            </div>
          )}
        </div>
      </>

      {!editFlag && (
        <div className="row">
          <div className="col-md-8 col-lg-6">
            {!editFlag && (
              <label className="form-label">
                {Strings.employmentStatusRadioTitle}
                <span className="text-danger"> * </span>
              </label>
            )}
            <>
              <SelectInput
                labelTitle={!true && "Title"}
                id="userNameTitle"
                required
                className="rounded-0"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  dispatching("employeeStatus", e.target.value, true)
                }
                selected={
                  mainObj?.employeeStatus?.value ??
                  EMPLOYMENT_STATUS_RADIO_CONST[0]
                }
                options={EMPLOYMENT_STATUS_RADIO_CONST}
                isError={false}
                errorMsg={Strings.pleaseSelectAnyValue}
              />
            </>
          </div>
        </div>
      )}

      {checkOtherJobIsSelected() && (
        <>
          <>
            <div>
              {editFlag && (
                <div className="row">
                  <div className="d-flex col-md-8 col-lg-6 align-items-center gap-4">
                    <div>
                      <label className="form-label">
                        {Strings.employmentIndustrySearchTitle}
                        <span className="text-danger">{isWhatIndustryEditEnabled && ` *`}</span>
                      </label>
                    </div>
                    {isWhatIndustryEditEnabled && (
                      <div className="mb-3">
                        <SaveButtonComponent
                          onClick={() => editWhatIndustryHandler()}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}

              {editFlag && !isWhatIndustryEditEnabled && (
                <div className="col-md-8 col-lg-6 mb-3">
                  <div className="d-flex">
                    <div
                      id={`formField-isUKCountry`}
                      className={styles.genericFormFieldStyle}
                      aria-label={`isUKCountry field`}
                    >
                      <div className={styles.genericFormFieldTextStyle}>
                        {mainObj?.jobIndustry?.value}
                      </div>
                    </div>
                    {isEditBtnRequired && (
                      <EditButtonComponent
                        onClick={() => editWhatIndustryHandler()}
                      />
                    )}
                  </div>
                </div>
              )}
              {editFlag && isWhatIndustryEditEnabled && (
                <div className="mb-2">
                  <div className="col-md-8 col-lg-6">
                    {!editFlag && (
                      <div className="stepper-product-label form-label">
                        {Strings.employmentIndustrySearchTitle}{" "}
                        <span className="text-danger"> * </span>
                      </div>
                    )}
                    <Select
                      options={INDUSTRIES_LIST}
                      defaultValue={{
                        label: mainObj?.jobIndustry?.value,
                        value: 0,
                      }}
                      onChange={(e: any) => {
                        let tempIndustry: string = e?.label;
                        dispatching("jobIndustry", tempIndustry, true);
                      }}
                      placeholder={Strings.typeToSearch}
                      className="select-industries-main-component"
                    />
                  </div>
                </div>
              )}
            </div>
          </>

          {!editFlag && (
            <div className="row mb-3">
              <div className="col-md-8 col-lg-6">
                {!editFlag && (
                  <div className="stepper-product-label form-label">
                    {Strings.employmentIndustrySearchTitle}{" "}
                    <span className="text-danger"> * </span>
                  </div>
                )}
                <Select
                  options={INDUSTRIES_LIST}
                  defaultValue={{
                    label: mainObj?.jobIndustry?.value,
                    value: 0,
                  }}
                  onChange={(e: any) => {
                    let tempIndustry: string = e?.label;
                    dispatching("jobIndustry", tempIndustry, true);
                  }}
                  placeholder={Strings.typeToSearch}
                  className={stepperDetails?.buttonValidatonIsRequired && !mainObj?.jobIndustry?.value? "input-error-border" :'select-industries-main-component'}
                />
                {stepperDetails?.buttonValidatonIsRequired && !mainObj?.jobIndustry?.value && <div className={commonStyles.errorHeadingSmallStyle}>{Strings.employmentIndustrySearchTitleErrorMsg}</div>}
              </div>
            </div>
          )}

          <>
            <div>
              {editFlag && (
                <div className="row">
                  <div className="d-flex col-md-8 col-lg-6 align-items-center gap-4">
                    <div>
                      <label className="form-label">
                        {Strings.employmentInputJobRoleTitle}
                        <span className="text-danger">{isJobRoleEditEnabled && ` *`}</span>
                      </label>
                    </div>
                    {isJobRoleEditEnabled && (
                      <div>
                        <SaveButtonComponent
                          onClick={() => editJobRoleHandler()}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}

              {editFlag && !isJobRoleEditEnabled && (
                <div className="col-md-8 col-lg-6 mb-3">
                  <div className="d-flex">
                    <div
                      id={`formField-isUKCountry`}
                      className={styles.genericFormFieldStyle}
                      aria-label={`isUKCountry field`}
                    >
                      <div className={styles.genericFormFieldTextStyle}>
                        {mainObj?.jobRole?.value}
                      </div>
                    </div>
                    {isEditBtnRequired && (
                      <EditButtonComponent
                        onClick={() => editJobRoleHandler()}
                      />
                    )}
                  </div>
                </div>
              )}
              {editFlag && isJobRoleEditEnabled && (
             
                    <InputRenderer
                      label=""
                      placeholder={Strings.jobRolePlaceholder}
                      value={mainObj?.jobRole?.draftValue ?? mainObj?.jobRole?.value}
                      updateInput={(e: any) =>
                        updateJobRoleHandler(e?.target?.value)
                      }
                      isError={
                        stepperDetails?.buttonValidatonIsRequired &&
                        !mainObj?.jobRole?.isValidate
                      }
                      errorMessage={
                        mainObj?.jobRole?.value
                          ? Strings.jobRoleErrorMessage
                          : Strings.jobRoleBlankErrorMessage
                      }
                    />
              )}
            </div>
          </>

          {!editFlag && (
            <InputRenderer
              label={Strings.employmentInputJobRoleTitle}
              placeholder={Strings.jobRolePlaceholder}
              value={mainObj?.jobRole?.value}
              updateInput={(e: any) => updateJobRoleHandler(e?.target?.value)}
              isError={
                stepperDetails?.buttonValidatonIsRequired &&
                !mainObj?.jobRole?.isValidate
              }
              errorMessage={
                mainObj?.jobRole?.value
                  ? Strings.jobRoleErrorMessage
                  : Strings.jobRoleBlankErrorMessage
              }
            />
          )}

          <>
            <div>
              {editFlag && (
                <div className="row">
                  <div className="d-flex col-md-8 col-lg-6 align-items-center gap-4">
                    <div>
                      <label className="form-label">
                        {Strings.employmentIndustrySearchTitle}
                        <span className="text-danger">{isAnualIncomeEditEnabled && ` *`}</span>
                      </label>
                    </div>
                    {isAnualIncomeEditEnabled && (
                      <div className="mb-3">
                        <SaveButtonComponent
                          onClick={() => editAnualIncomeHandler()}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}

              {editFlag && !isAnualIncomeEditEnabled && (
                <div className="col-md-8 col-lg-6 mb-3">
                  <div className="d-flex">
                    <div
                      id={`formField-isUKCountry`}
                      className={styles.genericFormFieldStyle}
                      aria-label={`isUKCountry field`}
                    >
                      <div className={styles.genericFormFieldTextStyle}>
                        {mainObj?.incomeRange?.value}
                      </div>
                    </div>
                    {isEditBtnRequired && (
                      <EditButtonComponent
                        onClick={() => editAnualIncomeHandler()}
                      />
                    )}
                  </div>
                </div>
              )}
              {editFlag && isAnualIncomeEditEnabled && (
                <div className="mb-3">
                  <div className="col-md-8 col-lg-6">
                    {!editFlag && (
                      <div className="stepper-product-label form-label">
                        {Strings.employmentIndustrySearchTitle}{" "}
                        <span className="text-danger"> * </span>
                      </div>
                    )}
                    <SelectInput
                      labelTitle={!true && "Title"}
                      id="userNameTitle"
                      className="rounded-0"
                      name="SelectIncomeRange"
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        dispatching(
                          "incomeRange",
                          e.target.value?.toLowerCase(),
                          true
                        )
                      }
                      selected={mainObj?.incomeRange?.value}
                      options={INCOME_RADIO_CONST}
                      isError={false}
                      errorMsg={Strings.pleaseSelectAnyValue}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
          {!editFlag && (
            <div className="row">
              <div className="col-md-8 col-lg-6">
                <label className="form-label">
                  {Strings.employmentIncomeRadioTitle}
                  <span className="text-danger"> * </span>
                </label>
                <>
                  <SelectInput
                    labelTitle={!true && "Title"}
                    id="userNameTitle"
                    className="rounded-0"
                    name="SelectIncomeRange"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      dispatching(
                        "incomeRange",
                        e.target.value?.toLowerCase(),
                        true
                      )
                    }
                    selected={mainObj?.incomeRange?.value}
                    options={INCOME_RADIO_CONST}
                    isError={false}
                    errorMsg={Strings.pleaseSelectAnyValue}
                  />
                </>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default StepperEmploymentDetails;

interface InputRendererProps {
  label?: string;
  placeholder?: string;
  value?: string;
  updateInput?: object;
  helperText?: string;
  maxLength?: number;
  errorMessage?: string;
  isError: boolean;
  validateInput?: object;
}

const InputRenderer: FunctionComponent<InputRendererProps> = ({
  label,
  placeholder,
  value,
  updateInput,
  helperText,
  maxLength,
  errorMessage,
  validateInput,
  isError,
}) => {  
  return (
    <div className="row mb-3">
      <div className="col-md-8 col-lg-6">
        <label className="form-label">
          {label}
          {label && <span className="text-danger"> * </span>}
        </label>
        <Input
          placeholder={placeholder}
          value={value}
          onChange={updateInput}
          className={`terms-conditions-choosePlan stepper-product-row form-control ${isError && "input-error-border"}`}
          maxLength={maxLength}
          onBlur={validateInput}
        />
        {isError && (
          <div className={`${commonHeadingStyles.errorHeadingSmallerStyle} mt-1`}>
            {errorMessage}
          </div>
        )}
        <span className="fst-italic font-size-12px">{helperText}</span>
      </div>
    </div>
  );
};
