import React, { useEffect, useState } from "react";
import usInformationData from "../../../assest/json/usInformationPopup.json";
import { Checkbox } from "../../../common/basic components/checkBox";
import { InformationDisplayPopup } from "../../../common/components/InfomationDisplayPopup";
import SearchCheckBox from "../../../common/components/searchCheckBox";
import { TaxDisplayComponent } from "../../../common/components/stepper/taxDisplayComponent";
import {
  ARRAY_INITIAL_COUNT,
  INITIAL_COUNT,
  NINE_COUNTER
} from "../../../common/constant/customConstant";
import { updateCitizenAndTax } from "../../../redux/reducer/stepperSubProductSlice";
import { useAppDispatch } from "../../../redux/redux-hooks";
import { default as StylesCommonHeading } from "../../../styleModules/commonHeading.module.scss";
import styles from "../../../styleModules/reviewStepper.module.scss";
import Strings from "../../../theme/string";
import { EditButtonComponent } from "../../choosePlan/ReviewStepper/ReviewSubComponents/miscComponents/editButtonComponent";
import { SaveButtonComponent } from "../../choosePlan/ReviewStepper/ReviewSubComponents/miscComponents/saveButtonComponent";
import StringData from "../../choosePlan/stringCitizenShipNdTaxInformation";

export const CitizenShipTaxDisplay = (props: any) => {
  const {
    mainObj,
    editFlag,
    Country,
    stepperDetails,
    isEditBtnRequired,
    isExtraFiledRequired,
  } = props;
  const dispatch = useAppDispatch();
  const {
    isTaxInputEnableForAnother,
    isTaxInputEnableForUK,
    isTaxInputEnableForUS,
  } = mainObj;
  const [isPopupEnable, setIsPoupEnabled] = useState(false);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [dataSaved, setDataSaved] = useState(false);


  const valueUpdateInObj = (
    tmpVal: string,
    key: string,
    saved: boolean = false
  ) => {
    if (saved && editFlag) {
      return {
        value: mainObj?.[key]?.draftValue,
        draftValue: mainObj?.[key]?.draftValue,
      };
    } else if (editFlag) {
      return { draftValue: tmpVal, value: mainObj?.[key]?.value };
    } else return { value: tmpVal };
  };

  const setOtherThanUKCitizenHandler = (item: any, tmpFieldName: string) => {
    const key = tmpFieldName;
    const obj = valueUpdateInObj(item, tmpFieldName, false);
    dispatch<any>(
      updateCitizenAndTax({
        obj: {
          isValidate: item?.length > INITIAL_COUNT ? true : false,
          ...obj,
        },
        fieldName: tmpFieldName,
      })
    );
  };

  const isCheckBoxChecked = () => {
    if (!stepperDetails?.buttonValidatonIsRequired) return false;
    if (
      stepperDetails?.buttonValidatonIsRequired &&
      (mainObj?.isTaxInputEnableForAnother?.value ||
        mainObj?.isTaxInputEnableForUK?.value ||
        mainObj?.isTaxInputEnableForUS?.value)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const updateOtherCountryTaxField = (val: any, countryName: string) => {
    let tmpObj: any[] = [];
    if (mainObj?.taxNumber?.length > INITIAL_COUNT) {
      tmpObj = [...mainObj.taxNumber];
      const tmpIndex = mainObj.taxNumber.findIndex(
        (item: any) => item?.countryName == countryName
      );
      if (tmpIndex != ARRAY_INITIAL_COUNT) {
        tmpObj.splice(tmpIndex, 1);
      }
    } else {
      tmpObj =
        mainObj?.taxNumber && mainObj?.taxNumber.length > INITIAL_COUNT
          ? [...mainObj.taxNumber]
          : [];
    }
    tmpObj.push({
      countryName,
      value: val,
      isValidate: val.length == NINE_COUNTER ? true : false,
    });
    dispatch<any>(
      updateCitizenAndTax({ obj: tmpObj, fieldName: Strings.taxNumber })
    );
  };

  const updateTaxCountry = () => {
    let arr: any[] = [];
    const tmpObj = StringData?.selectTaxRecidency?.checkInputs;
    if (isTaxInputEnableForUK?.value)
      arr.push(<div>{tmpObj[Strings.zero]?.label}</div>);
    if (isTaxInputEnableForAnother?.value)
      arr.push(<div>{tmpObj[Strings.one]?.label}</div>);
    if (isTaxInputEnableForUS?.value)
      arr.push(<div>{tmpObj[Strings.two]?.label}</div>);
    return arr;
  };

  const valueFromDraftValuaAndValue = (key : string) =>{
    return mainObj?.[key]?.draftValue ? !mainObj?.[key]?.draftValue : !mainObj?.[key]?.value;
  }

  const getCheckValue = (key : string) =>{
    return isEditEnabled ? mainObj?.isTaxInputEnableForAnother?.value : !mainObj?.isTaxInputEnableForAnother?.value
  }

  const updateTaxResidencyHandler = (key: any) => {
    let draftValue = {};
    if (key == Strings.us.toLowerCase()) {
      if(isEditEnabled){
        draftValue = {draftValue : valueFromDraftValuaAndValue("isTaxInputEnableForUS")}
      }
      dispatch<any>(
        updateCitizenAndTax({
          obj: { value: getCheckValue("isTaxInputEnableForUS"), ...draftValue },
          fieldName: Strings.isTaxInputEnableForUS,
        })
      );
    }
    if (key === Strings.another) {
      if(isEditEnabled){
        draftValue = {draftValue : valueFromDraftValuaAndValue("isTaxInputEnableForAnother")}
      }
      dispatch<any>(
        updateCitizenAndTax({
          obj: { value: getCheckValue("isTaxInputEnableForAnother"), ...draftValue },
          fieldName: Strings.isTaxInputEnableForAnother,
        })
      );
    }
    if (key === Strings.uk) {
      if(isEditEnabled){
        draftValue = {draftValue : valueFromDraftValuaAndValue("isTaxInputEnableForUK")}
      }
      dispatch<any>(
        updateCitizenAndTax({
          obj: { value: getCheckValue("isTaxInputEnableForUK"), ...draftValue },
          fieldName: Strings.isTaxInputEnableForUK,
        })
      );
    }
  };

  const editModeHandler = () => {
    setDataSaved(false);
    setIsEditEnabled(true);
  };

  const checkingDraftValueIsPersent = (key : string) =>{
    return mainObj?.[key]?.draftValue == undefined ? false : true;
  }

  const saveModeHandler = () => {
    const tmpTaxNumberIsUSGiven = ((checkingDraftValueIsPersent("isTaxInputEnableForUS") && !mainObj?.isTaxInputEnableForUS?.draftValue)) || ((!checkingDraftValueIsPersent("isTaxInputEnableForUS") && !mainObj?.isTaxInputEnableForUS?.value))
      ? true
      : !isTaxNumberField(Strings.us);
    const tmpOtherCountryResident = (checkingDraftValueIsPersent("isTaxInputEnableForAnother") && !mainObj?.isTaxInputEnableForAnother?.draftValue) || (!checkingDraftValueIsPersent("isTaxInputEnableForAnother") && !mainObj?.isTaxInputEnableForAnother?.value)
      ? true
      : mainObj?.otherThanUKTaxNumber?.isValidate
      ? true
      : false;

      const tmpTaxNumberValidation = mainObj?.otherThanUKTaxNumber?.draftValue?.find((item: any) =>
        isTaxNumberField(item.label) == true
     )
      const tmpTaxDetailsField = mainObj?.isTaxInputEnableForAnother?.draftValue ?? mainObj?.isTaxInputEnableForAnother?.value 
      ? !tmpTaxNumberValidation
      : true 

    const tmpCheckBoxChecked = (checkingDraftValueIsPersent("isTaxInputEnableForAnother") && mainObj?.isTaxInputEnableForAnother?.draftValue) || 
                               (!checkingDraftValueIsPersent("isTaxInputEnableForAnother") && mainObj?.isTaxInputEnableForAnother?.value) ||
                               (checkingDraftValueIsPersent("isTaxInputEnableForUK") && mainObj?.isTaxInputEnableForUK?.draftValue) || 
                               (!checkingDraftValueIsPersent("isTaxInputEnableForUK") && mainObj?.isTaxInputEnableForUK?.value)  ||
                               (checkingDraftValueIsPersent("isTaxInputEnableForUS") && mainObj?.isTaxInputEnableForUS?.draftValue) || 
                               (!checkingDraftValueIsPersent("isTaxInputEnableForUS") && mainObj?.isTaxInputEnableForUS?.value) ;

    if(isEditEnabled &&
      tmpCheckBoxChecked &&
      tmpTaxNumberIsUSGiven &&
      tmpOtherCountryResident &&
      tmpTaxDetailsField){
      dispatch<any>(
        updateCitizenAndTax({
          obj: { value: mainObj?.isTaxInputEnableForUS?.draftValue ?? mainObj?.isTaxInputEnableForUS?.value},
          fieldName: Strings.isTaxInputEnableForUS,
        })
      );
      dispatch<any>(
        updateCitizenAndTax({
          obj: { value: mainObj?.isTaxInputEnableForAnother?.draftValue ?? mainObj?.isTaxInputEnableForAnother?.value },
          fieldName: Strings.isTaxInputEnableForAnother,
        })
      );
      dispatch<any>(
        updateCitizenAndTax({
          obj: { value: mainObj?.isTaxInputEnableForUK?.draftValue ?? mainObj?.isTaxInputEnableForUK?.value },
          fieldName: Strings.isTaxInputEnableForUK,
        })
      );
      setDataSaved(true);
      setIsEditEnabled(false);
    }

  };

  const getTaxNumberForCountry = (val: string) => {
    const obj = mainObj?.taxNumber?.find((item: any) => {
      return item.countryName == val;
    });
    return obj?.value;
  };

  const isTaxNumberField = (tmpVal: string) => {
    let isValidate = mainObj?.taxNumber?.find((item: any) => {
      return item.countryName == tmpVal && item.isValidate;
    });
    if (isValidate) return false;
    else return true;
  };

  const isTaxNumberFieldHasValue = (tmpVal: string) => {
    let isValidate = mainObj?.taxNumber?.findIndex((item: any) => {
      return item.countryName == tmpVal && item?.value;
    });
    return isValidate > ARRAY_INITIAL_COUNT ? true : false;
  };

  const enableNdDisableField = () => {
    if (dataSaved && isEditEnabled && editFlag) return true;
    if (dataSaved) return true;
    if (editFlag && !isEditEnabled) return true;
    if (dataSaved && editFlag) return true;
  };

  const getValueBtwDraftNdValue = (key: string) => {
    if (mainObj?.[key]?.draftValue) return mainObj?.[key]?.draftValue;
    else return mainObj?.[key]?.value;
  };

  const checkDraftValueNdValue = (key : string) =>{
    if(isEditEnabled)
    {
      return mainObj?.[key]?.draftValue ?? mainObj?.[key]?.value;
    }
    else
      return mainObj?.[key]?.value;
  }

  const returnDraftValueNdValue = (key : string) =>{
    return mainObj?.[key]?.draftValue ? mainObj?.[key]?.draftValue : mainObj?.[key]?.value
  }

  console.log(mainObj?.isTaxInputEnableForAnother)

  return (
    <>
      {isPopupEnable && (
        <InformationDisplayPopup
          show={isPopupEnable}
          onClick={() => setIsPoupEnabled(false)}
          data={usInformationData}
        />
      )}

      <div className="d-flex align-items-center gap-3 mt-3">
        {editFlag && (
          <label className="form-label">
            {" "}
            {Strings.taxResidency}
            <span className="text-danger"> {!enableNdDisableField() && ` *`}</span>
          </label>
        )}
        {isEditEnabled && (
          <SaveButtonComponent onClick={() => saveModeHandler()} />
        )}
      </div>
      {enableNdDisableField() && (
        <div className="col-md-8 col-lg-6">
          <div className="d-flex">
            <div
              id={`formField-isUKCountry`}
              className={styles.genericFormFieldStyle}
              aria-label={`isUKCountry field`}
            >
              <div className={`${styles.genericFormFieldStyle}`}>
                <div className="d-flex justify-content-between">
                  <div className={`${styles.genericFormFieldTextStyleForTaxResident}`}>
                      {updateTaxCountry()}
                  </div>
                  <div>
                    {isEditBtnRequired && (
                      <EditButtonComponent onClick={() => editModeHandler()} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!enableNdDisableField() && (
        <div className="row mb-3">
          <div className="col-md-8 col-lg-6">
            <label className="form-label">
              {StringData.selectTaxRecidency.title}{" "}
              <span className="text-danger"> * </span>
            </label>
            {StringData?.selectTaxRecidency?.checkInputs?.map((item: any) => (
              <div
                className={`terms-conditions-choosePlan search-input ${isCheckBoxChecked() && "input-error-border"}`}
                key={item.country}
              >
                <Checkbox
                  label={item.label}
                  informationTrue={
                    item &&
                    item?.country?.toLowerCase().search(Strings.us) >=
                      INITIAL_COUNT
                  }
                  inputClassName={"checkbox-width-zero"}
                  labelClassName={"text-color-green"}
                  isChecked={checkDraftValueNdValue(item.checked)}
                  onChange={() => updateTaxResidencyHandler(item.country)}
                  onClickPopupHandler={() => setIsPoupEnabled(true)}
                />
              </div>
            ))}
            {isCheckBoxChecked() && (
              <div className={`${StylesCommonHeading.errorHeadingSmallerStyle} mt-1`}>
                {Strings.mustSelectAtLeastOneOption}
              </div>
            )}
          </div>
        </div>
      )}
      {enableNdDisableField() && (
        <>
          <div className="row mt-3 col-md-8 col-lg-6">
            <label className="form-label">
              {Strings.otherCountriesTaxResident}
            </label>
          </div>
          <div className="col-md-8 col-lg-6">
          {mainObj?.otherThanUKTaxNumber?.value?.map(
                    (item: any) => <div className={`my-2 py-2 w-full ${styles.genericFormFieldTextStyle} ${styles.genericFormFieldStyle}`}>{item.label}</div>
                  )}
            
          </div>
        </>
      )}
      {!enableNdDisableField() &&
        (mainObj?.isTaxInputEnableForAnother?.draftValue || mainObj?.isTaxInputEnableForAnother?.value) && (
          <div className="row">
            <div className="col-md-8 col-lg-6">
              <label className="form-label">
                {StringData.selectTaxCountry}{" "}
                <span className="text-danger"> * </span>
              </label>
              <SearchCheckBox
                options={Country}
                setSelectedOptions={(item: any) =>
                  setOtherThanUKCitizenHandler(
                    item,
                    Strings.otherThanUKTaxNumber
                  )
                }
                selectedOptions={getValueBtwDraftNdValue(
                  Strings.otherThanUKTaxNumber
                )}
                isSelected={!enableNdDisableField() &&
                  ((stepperDetails?.buttonValidatonIsRequired && mainObj?.isTaxInputEnableForAnother?.value)|| (mainObj?.isTaxInputEnableForAnother?.draftValue && editFlag)) &&
                  !mainObj?.otherThanUKTaxNumber?.isValidate}
              />
            </div>
            {!enableNdDisableField() &&
              ((stepperDetails?.buttonValidatonIsRequired && mainObj?.isTaxInputEnableForAnother?.value)|| (isEditEnabled && (mainObj?.isTaxInputEnableForAnother?.draftValue ?? mainObj?.isTaxInputEnableForAnother?.value ))) &&
              !mainObj?.otherThanUKTaxNumber?.isValidate && (
                <div className={StylesCommonHeading.errorHeadingSmallerStyle}>
                  {Strings.countryTaxResidentCanNotBeBlank}
                </div>
              )}
          </div>
        )}
      {!enableNdDisableField() &&
        (mainObj?.isTaxInputEnableForAnother?.draftValue || mainObj?.isTaxInputEnableForAnother?.value) &&
        mainObj?.otherThanUKTaxNumber?.isValidate &&
        returnDraftValueNdValue("otherThanUKTaxNumber")?.map((item: any) => {          
          return (
            <>
              <TaxDisplayComponent
                key={item?.label}
                label={`${Strings.taxNumberFor} ${item.label}`}
                placeholder={Strings.enterTextNumber}
                bottomText={Strings.taxFormatExample}
                updateCountryHandler={(
                  e: React.ChangeEvent<HTMLInputElement>
                ) => updateOtherCountryTaxField(e, item?.label)}
                value={getTaxNumberForCountry(item?.label)}
                stepperDetails
                editFlag
                mainObj
                item
                isTaxNumberFieldHasValue ={isTaxNumberFieldHasValue(item?.label)}
                isErrorRequired ={ (stepperDetails?.buttonValidatonIsRequired || editFlag) &&
                  isTaxNumberField(item?.label)}
              />
            </>
          );
        })}

      {!enableNdDisableField() && (mainObj?.isTaxInputEnableForUS?.draftValue || mainObj?.isTaxInputEnableForUS?.value) && (
        <TaxDisplayComponent
          label={`${Strings.taxNumberFor} US`}
          placeholder={Strings.enterTextNumber}
          bottomText={Strings.taxFormatExample}
          updateCountryHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateOtherCountryTaxField(e, Strings.us)
          }
          value={getTaxNumberForCountry(Strings.us)}
          stepperDetails
          editFlag
          mainObj
          isTaxNumberFieldHasValue ={isTaxNumberFieldHasValue(Strings.us)}
          isErrorRequired ={ (stepperDetails?.buttonValidatonIsRequired || editFlag) &&
          isTaxNumberField(Strings.us)}
        />
      )}
    </>
  );
};
