import styles from "../../../styleModules/reviewStepper.module.scss";
import commonStyle from "../../../styleModules/commonMicsStyles.module.scss";

const ProfileProcessingSectionBox = () => {
  return (
    <section className={styles.imageStyle}>
      <span
        className="square-stepper-button current"
        style={{
          width: "42px",
          height: "38px",
          cursor: "pointer",
        }}
        aria-label="Profile Processing"
      >
        <div className={commonStyle.loader}></div>
      </span>
    </section>
  );
};

export default ProfileProcessingSectionBox;
