import React, { useCallback, useMemo, useReducer, useState } from "react";
import styles from "../../../styleModules/commonMicsStyles.module.scss";
import profileStyles from "../../../styleModules/profileStyles.module.scss";
import headingStyles from "../../../styleModules/commonHeading.module.scss";
import {
  ActionTypeEnum,
  AddressFieldEnum,
} from "../../../enums/reviewStepper.enums";
import { PART_OF_ADDRESS } from "../../../common/constant/customConstant";
import Strings from "../../../theme/string";
import { SaveButtonComponent } from "../../choosePlan/ReviewStepper/ReviewSubComponents/miscComponents/saveButtonComponent";
import { EditButtonComponent } from "../../choosePlan/ReviewStepper/ReviewSubComponents/miscComponents/editButtonComponent";
import { saveProfileAddress } from "../../../services/profileSectionApis";
import { SAVE_PROFILE_ADDRESS } from "../../../services/url";
import {
  AddressErrorResponse,
  AddressProfileBody,
  PartyAddress,
  SaveProfileAddressResponse,
} from "../../../interfaces/partyDetails.interface";
import { useAppDispatch, useAppSelector } from "../../../redux/redux-hooks";
import { RootState } from "../../../interfaces/reviewStepper.interface";
import {
  updateUserDetails,
  updateValidationIsRequired,
} from "../../../redux/reducer/stepperSubProductSlice";
import ProfileProcessingSectionBox from "./profileProcessingBox";

import AddressField from "../../personalInformation/personalInformationFields/addressField";
import ProfileAddressSuccessBox from "./profileAddressSuccessBox";
import { Link } from "react-router-dom";
import { ErrorCodes } from "../../../enums/errorCodes.enums";

type ActionType =
  | { type: ActionTypeEnum.SET_EDIT_MODE }
  | { type: ActionTypeEnum.RESET_EDIT_MODE };

const initialEditMode = {
  address: false,
};

const editReducer = (state: typeof initialEditMode, action: ActionType) => {
  switch (action.type) {
    case ActionTypeEnum.SET_EDIT_MODE:
      return { ...state, address: true };
    case ActionTypeEnum.RESET_EDIT_MODE:
      return { ...state, address: false };
    default:
      return state;
  }
};

const ProfileAddressParentComponent = (addressData: {
  addressData: PartyAddress;
}) => {
  const {
    addressline1,
    addressline2,
    addressline3,
    addressline4,
    posttown,
    postcode,
  } = useAppSelector((state: RootState) => ({
    addressline1: state.stepperDetails?.stepperUserDetails?.addressline1?.value,
    addressline2: state.stepperDetails?.stepperUserDetails?.addressline2?.value,
    addressline3: state.stepperDetails?.stepperUserDetails?.addressline3?.value,
    addressline4: state.stepperDetails?.stepperUserDetails?.addressline4?.value,
    posttown: state.stepperDetails?.stepperUserDetails?.posttown?.value,
    postcode: state.stepperDetails?.stepperUserDetails?.postcode?.value,
  }));

  const appDispatch = useAppDispatch();
  const stepperDetails = useAppSelector(
    (state: RootState) => state?.stepperDetails
  );
  const [editMode, dispatch] = useReducer(editReducer, initialEditMode);
  const dataUserDetails = stepperDetails?.stepperUserDetails;

  const editModeHandler = useCallback(() => {
    dispatch({ type: ActionTypeEnum.SET_EDIT_MODE });
  }, []);

  const [addressLoader, setAddressLoader] = useState<boolean>(false);
  const [addressApiErrorFlag, setAddressApiErrorFlag] =
    useState<boolean>(false);
  const [processingError, setProcessingError] = useState<boolean>(false);
  const [
    addressSuccessfulUpdateModalFlag,
    setAddressSuccessfulUpdateModalFlag,
  ] = useState<boolean>(false);

  const addressFieldsEnumsValue = useMemo(
    () => [
      AddressFieldEnum.ADDRESS_ONE,
      AddressFieldEnum.ADDRESS_TWO,
      AddressFieldEnum.ADDRESS_THREE,
      AddressFieldEnum.ADDRESS_FOUR,
      AddressFieldEnum.POST_TOWN,
      AddressFieldEnum.POST_CODE,
    ],
    []
  );

  const saveAddressApiCall = () => {
    const addressProfileBody: AddressProfileBody = {
      addressDetails: {
        updateResidentialAddress: false,
        updateCorrespondenceAddress: true,
        updateAccountAddress: true,
        updateAddress: {
          addressLine1:
            dataUserDetails[AddressFieldEnum.ADDRESS_ONE]?.draftValue ?? "",
          addressLine2:
            dataUserDetails[AddressFieldEnum.ADDRESS_TWO]?.draftValue ?? "",
          addressLine3:
            dataUserDetails[AddressFieldEnum.ADDRESS_THREE]?.draftValue ?? "",
          addressLine4:
            dataUserDetails[AddressFieldEnum.ADDRESS_FOUR]?.draftValue ?? "",
          city: dataUserDetails[AddressFieldEnum.POST_TOWN]?.draftValue ?? "",
          state: "",
          postalCode:
            dataUserDetails[AddressFieldEnum.POST_CODE]?.draftValue ?? "",
          country: Strings?.gbr,
        },
      },
    };
    (async () => {
      try {
        setAddressLoader(true);
        const token = localStorage.getItem("tokenVal") ?? "";
        if (token) {
          const saveApiAddressResponse: SaveProfileAddressResponse =
            await saveProfileAddress(
              SAVE_PROFILE_ADDRESS,
              addressProfileBody,
              token
            );
          if ("error" in saveApiAddressResponse) {
            handleErrorResponse(saveApiAddressResponse);
          } else {
            addressFieldsEnumsValue?.forEach((title) => {
              appDispatch(
                updateUserDetails({
                  fieldName: title,
                  value: dataUserDetails[title]?.draftValue ?? "",
                  draftValue: dataUserDetails[title]?.draftValue ?? "",
                  isValidate: dataUserDetails[title]?.isValidate ?? true,
                  isdraftValidate:
                    dataUserDetails[title]?.isdraftValidate ?? true,
                })
              );
            });
            setAddressSuccessfulUpdateModalFlag(true);
            setAddressLoader(false);
            setAddressApiErrorFlag(false);
            setProcessingError(false);
            dispatch({ type: ActionTypeEnum.RESET_EDIT_MODE });
          }
        } else {
          handleAddressProcessingError();
        }
      } catch (error) {
        handleAddressProcessingError();
      }
    })();
  };
  const handleErrorResponse = (response: AddressErrorResponse) => {
    if (
      response?.data?.errorCode ===
      ErrorCodes.SAVE_PROFILE_ADDRESS_RESTRICTION_ERROR
    ) {
      setAddressApiErrorFlag(true);
      setProcessingError(false);
    } else {
      setAddressApiErrorFlag(false);
      setProcessingError(true);
    }
    setAddressLoader(false);
    setAddressSuccessfulUpdateModalFlag(false);
    dispatch({ type: ActionTypeEnum.RESET_EDIT_MODE });
  };
  const handleAddressProcessingError = () => {
    setAddressLoader(false);
    setProcessingError(true);
    setAddressSuccessfulUpdateModalFlag(false);
    dispatch({ type: ActionTypeEnum.RESET_EDIT_MODE });
  };

  const saveModeHandler = () => {
    if (
      addressFieldsEnumsValue?.every(
        (title) =>
          (dataUserDetails[title]?.isdraftValidate ||
            PART_OF_ADDRESS.includes(title)) ??
          true
      )
    ) {
      saveAddressApiCall();
    } else {
      appDispatch(updateValidationIsRequired(true));
    }
  };

  const viewAddressData = useMemo(
    () =>
      [
        addressline1,
        addressline2,
        addressline3,
        addressline4,
        posttown,
        postcode,
      ]
        .filter((address) => Boolean(address))
        .join(", "),
    [addressline1, addressline2, addressline3, addressline4, posttown, postcode]
  );

  return (
    <>
      <div role="region" aria-labelledby="personal-details-section">
        <div className="d-flex align-items-center gap-3 mb-3"></div>
        <div className="row gap-4 mb-3">
          <div>
            <div className="d-flex align-items-center form-label gap-3">
              <div>
                <label htmlFor="formField-address">
                  {Strings.address}
                  <span className="text-danger" aria-hidden="true">{editMode.address && ` *`}</span>
                </label>
              </div>
              {editMode.address && (
                <>
                  {addressLoader ? (
                    <>
                      <ProfileProcessingSectionBox />
                      <em aria-live="polite">
                        {Strings.processing}
                        {Strings.continueDot}
                      </em>
                    </>
                  ) : (
                    <SaveButtonComponent
                      onClick={saveModeHandler}
                      aria-label="Save address"
                    />
                  )}
                </>
              )}
            </div>
            {!editMode.address && (
              <>
                <section className="d-flex">
                  <div
                    id="formField-address"
                    className={styles.genericFormFieldStyle}
                    aria-label="Display of saved address details"
                  >
                    <div className={styles.genericFormFieldTextStyle}>
                      {viewAddressData}
                    </div>
                  </div>
                  <EditButtonComponent
                    onClick={editModeHandler}
                    aria-label="Edit address"
                  />
                </section>
                {addressApiErrorFlag && (
                  <section
                    className={`${headingStyles.errorHeadingSmallStyle} my-2`}
                    role="alert"
                    aria-live="assertive"
                  >
                    {Strings.addressUpdateErrorOne}
                    <Link to="/contact-us" className="text-danger">
                      {" "}
                      <b>
                        {" "}
                        <u>{Strings.contactUsStr}</u>
                      </b>
                    </Link>{" "}
                    {Strings.addressUpdateErrorTwo}
                  </section>
                )}
                {processingError && (
                  <section
                    className={`${headingStyles.errorHeadingSmallStyle} my-2`}
                    role="alert"
                    aria-live="assertive"
                  >
                    {Strings.addressProcessingError}
                    <Link to="/contact-us" className="text-danger">
                      {" "}
                      <b>
                        {" "}
                        <u>{Strings.contactUsStr}.</u>
                      </b>
                    </Link>{" "}
                  </section>
                )}
              </>
            )}
            {editMode.address && (
              <div className="row gap-4 mt-4">
                <AddressField
                  stepperDetails={stepperDetails}
                  reviewFlag={true}
                  aria-label="Address form fields"
                />
              </div>
            )}
            {addressSuccessfulUpdateModalFlag && (
              <ProfileAddressSuccessBox
                setAddressSuccessfulUpdateModalFlag={
                  setAddressSuccessfulUpdateModalFlag
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ProfileAddressParentComponent;
