import {ReactComponent  as Facebook} from "../../assest/monu_Facebook.svg";
import {ReactComponent  as Instagram} from "../../assest/monu_Instagram.svg";
import {ReactComponent  as Linkedin} from "../../assest/monu_LinkedIn.svg";
import {ReactComponent  as DownloadLinkAndriod} from "../../assest/monu_Apple.svg";
import {ReactComponent  as FcsLogo} from "../../assest/monu_FSCS_logo.svg";
import {ReactComponent  as DownloadLinkApple} from "../../assest/monu_Google.svg";
import {ReactComponent  as DisplayImg} from "../../assest/monu_Welcome.svg";
import {ReactComponent  as RectMainImg} from "../../assest/Rectangle_main.svg";
import {ReactComponent  as RectSubImg} from "../../assest/Rectangle_sub.svg";
import {ReactComponent  as TickIconWithBourndry} from "../../assest/monu_Mastertick.svg";
import {ReactComponent  as MonuIcon} from "../../assest/monu_Icon_Saving.svg";
import {ReactComponent  as MonuMission} from "../../assest/monu_Mission.svg";
import {ReactComponent  as MonuWelcomeProduct} from "../../assest/monu_welcome_product.svg";
import {ReactComponent  as OurMissionFirst} from "../../assest/our_mission_img_first.svg"; 
import {ReactComponent  as OurMissionSecond} from "../../assest/our_mission_img_sec.svg"; 
import {ReactComponent  as DiamondTickIcon} from "../../assest/diamondCheckIconWithoutBorder.svg"; 
import {ReactComponent  as VectorTickIcon} from "../../assest/vector_tick.svg"; 
// import {ReactComponent  as FTICON} from "../../assest/monu_FT_icon.png";

export default{
    Facebook : Facebook,
    Instagram : Instagram,
    DownloadLinkAndriod : DownloadLinkAndriod,
    FcsLogo : FcsLogo,
    DownloadLinkApple : DownloadLinkApple,
    RectSubImg : RectSubImg,
    RectMainImg : RectMainImg,
    DisplayImg : DisplayImg,
    TickIconWithBourndry : TickIconWithBourndry,
    Linkedin : Linkedin,
    MonuIcon : MonuIcon,
    MonuMission : MonuMission,
    MonuWelcomeProduct : MonuWelcomeProduct,
    OurMissionFirst : OurMissionFirst,
    OurMissionSecond :OurMissionSecond,
    DiamondTickIcon: DiamondTickIcon,
    VectorTickIcon : VectorTickIcon
    // FTICON : FTICON
}