import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDashboardDiscoverMore } from "../../services/appApi";

export const dashboardDiscoverMoreAction = createAsyncThunk(
  "discoverMore",
  async () => {
    try {
      const res = await getDashboardDiscoverMore();
      return res;
    } catch (error) {
      return error;
    }
  }
);
