import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "../basic components/button";

export const CustomModal = (props: any) => {
  const { show, title, size, onClick, child, isButton, buttonVal } = props;
  return (
    <Modal show={show} size={size} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={onClick}
        ></button>
      </Modal.Header>
      <Modal.Body>
      {child}
      </Modal.Body>
      <Modal.Footer>
        {isButton && buttonVal && buttonVal.map((item : any,index : number)=>
              {
              return <Button
                variant="warning"
                type="submit"
                size={item.size}
                key={index}
                onClick={item.onClick}
                label={item.value}
                className={item.className}
              />})}
      </Modal.Footer>
    </Modal>
  );
};