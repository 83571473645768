import { useEffect, useState } from "react";
import { CHOOSE_MATURITY_BEFORE_30_DAYS } from "../common/constant/accountDetailsConstant";

interface AccountData {
  depositRequiredByDate: string;
  maturityDate: string;
}

const useMaturityCheck = (
  data: AccountData,
  maturityDateBefore = CHOOSE_MATURITY_BEFORE_30_DAYS
) => {
  const [isWithinMaturedDays, setIsWithinMaturedDays] = useState(false);

  useEffect(() => {
    if (!data.maturityDate) return;

    const parseDate = (dateStr: string) => {
      const [day, month, year] = dateStr.split("-");
      return new Date(`${year}-${month}-${day}`);
    };

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const maturedDate = parseDate(data.maturityDate);
    maturedDate.setHours(0, 0, 0, 0);

    const maturedAlertDate = new Date(maturedDate);
    maturedAlertDate.setDate(maturedDate.getDate() - maturityDateBefore);

    const isWithinMaturedRange =
      currentDate.getTime() >= maturedAlertDate.getTime() &&
      currentDate.getTime() <= maturedDate.getTime();

    setIsWithinMaturedDays(isWithinMaturedRange);
  }, [data.maturityDate, maturityDateBefore]);

  return { isWithinMaturedDays };
};

export default useMaturityCheck;
