import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMiddlePageBottomDetails } from "../../services/appApi";

export const getMiddlePageBottomDetailsAction = createAsyncThunk(
  "middlepageBottom",
  async() => {
    try {
      const res = await getMiddlePageBottomDetails();
      return res;
    } catch (error) {
      console.error(error);
    }
  }
);
