import React, { useEffect, useState } from "react";
import WelcomePage from "../../assest/monu_Welcome.svg";
import ImgPath from "../../common/constant/imgConstanct";
import { getWelcomeBoardDetailsAction } from "../../redux/actions/welcomeBoardAction";
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks";
import Strings from "../../theme/string";
import { Button } from "../basic components/button";
import { Img } from "../basic components/img";
import { CustomModal } from "./customModal";
import { MiddleTopPage } from "./middleTopPage";

export const LandingPageScreenTop = () => {
  const dispatch = useAppDispatch();
  const [isModalEnable, setIsModalEnable] = useState(false)
  const setIsModalEnableFalse = () => {
    setIsModalEnable(false);
  }
  useEffect(() => {
    dispatch<any>(getWelcomeBoardDetailsAction());
  }, []);

  const welcomeBoards: any = useAppSelector(
    (state: any) => state.welcomeBoards
  );
  const data = welcomeBoards?.welcomeBoards[0];
  return (
    <>
      {isModalEnable && <CustomModal show={isModalEnable} onClick={() => setIsModalEnableFalse()} title={Strings.chooseAProduct} size="xl" child={ <MiddleTopPage />   }/>}
      <div className="banner_box">
        <div className="container-xxl">
          <div className="row banner-content">
            {/* <div className="col-1"></div> */}
            <div className="col-7">
              <div>{data?.title}</div>
              <div className="banner-sec-dev">{data?.secDiv}
                <div className="banner-sec-dev-italic italian-font-color">{data?.secDivItalic}</div>
              </div>
              <hr className="banner-hr-block" />
              <div className="banner-thr-dev">{data?.thrDiv}
              </div>
              <div className="mt-3">
                <Button
                  type="button"
                  label="Get Started"
                  className="btn btn-light getstarted_btn"
                  onClick={() => setIsModalEnable(true)}
                />
              </div>
            </div>
            <div className="col-5">
              <Img src={WelcomePage} className="welcome-img float-end img-fluid" />
            </div>
          </div>
          <div className="row banner-button">
            <div className="banner-button-parent mb-1">
              <div className="banner-button-outer">
                <ImgPath.RectMainImg />
                <div className="banner-button-inner">
                  <ImgPath.RectSubImg />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
