import React, { useEffect, useState } from "react";
import { fetchDashboardData } from "../../services/fetchDashboardData";
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks";
import Strings from "../../theme/string";
import * as dashboardConstants from "../../common/constant/dashboardConstants";
import styles from "../../styleModules/dashboard.module.scss";
import DiscoverMore from "./DiscoverMore";
import { CustomModal } from "../../common/components/customModal";
import welcome from "../../assest/welcome.svg";
import LoadingModal from "../../common/components/LoadingModal";
import { formatBalance } from "../../common/utils/formatBalance";
import DashboardAccountWidgets from "./DashboardAccountWidgets";
import { getSavingPublicProductAction } from "../../redux/actions/savingAccountPublicProductAction";

const DashboardComponent = () => {
  const [isModalEnable, setIsModalEnable] = useState(false);
  const [isShowingModal, setIsShowingModal] = useState(false);
  const {
    data: accounts,
    isLoading,
    error,
  } = useAppSelector((state: any) => state.dashboardAccountData);

  const { url } = useAppSelector((state: any) => state.memorableWord);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (url?.pathname === "/memorableWord") {
      setIsShowingModal(true);
    }
  }, [url]);

  useEffect(() => {
    dispatch<any>(fetchDashboardData());
    dispatch<any>(getSavingPublicProductAction());
  }, [dispatch]);

  const activeandApprovedAccounts = accounts?.filter(
    (account: any) =>
      account.accountStatus === dashboardConstants.active ||
      account.accountStatus === dashboardConstants.approved
  );

  const grossCurrentBalance = activeandApprovedAccounts.reduce(
    (acc: number, curr: any) => {
      return (acc += curr.balance);
    },
    0
  );

  const accountWidgets = activeandApprovedAccounts.map((account: any) => (
    <DashboardAccountWidgets account={account} />
  ));

  const setIsModalEnableFalse = () => {
    setIsShowingModal(false);
  };

  return (
    <>
      {isLoading && <LoadingModal show={true} size="sm" />}

      {isShowingModal && (
        <CustomModal
          show={isShowingModal}
          onClick={() => setIsModalEnableFalse()}
          size="md"
          child={
            <div className="text-center">
              <img src={welcome} alt="welcome" className="mx-auto" />
              <p className="fs-4 fw-medium">
                {`Elliott ${Strings.dashboardModalText}`}
              </p>
            </div>
          }
        />
      )}
      <div className="col-12 col-md-6 col-lg-3 py-2 px-2 mt-3 width-100-percent">
        <div className="container">
          {isModalEnable && (
            <CustomModal
              show={isModalEnable}
              onClick={() => setIsModalEnableFalse()}
              size="md"
              child={
                <div className="text-center">
                  <img src={welcome} alt="welcome" className="mx-auto" />
                  <p className="fs-4 fw-medium">
                    {`Elliott ${Strings.dashboardModalText}`}
                  </p>
                </div>
              }
            />
          )}
          <div className="account-class">
            <div className="d-flex justify-content-between">
              <div>
                <h3 className="fw-semibold text-start fs-2">
                  {Strings.dashboardHeading}
                </h3>
                {grossCurrentBalance ? (
                  <p
                    className={`text-primary_one ${styles.yourCurrentBalance} text-nowrap`}
                  >
                    {Strings.dashboardCurrentBalance}
                    <span className={`fw-semibold ps-2 ${styles.balance}`}>
                      {formatBalance(grossCurrentBalance)}
                    </span>
                  </p>
                ) : (
                  ""
                )}
              </div>

              <div className={`${styles.lastLoginTextContainer}`}>
                <p className={`text-nowrap fw-light ${styles.lastLoginText}`}>
                  Last Login 06/09/2024 5.00PM
                </p>
              </div>
            </div>
            <div className="row justify-content-start gap-2">
              {!error ? <>{accountWidgets}</> : <p>{error}</p>}
            </div>
          </div>
          <div className="discover-more">
            <div className="row">
              <div className="col-12 col-md-10 py-2">
                <h3 className={`text-start ${styles.discoverHeading}`}>
                  {Strings.dashboardDiscoverMore}
                </h3>
              </div>
            </div>
            <div className="row justify-content-start gap-2">
              <DiscoverMore />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardComponent;
