import React from 'react'

export const ListUlLi = ({ descriptionValue }: any) => {
  return (
    <ul>
      {descriptionValue?.map((item: any, key : number) => 
        <li key={key}>{item}</li>
      )}
    </ul>
  )
}
