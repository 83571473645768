export enum ActionTypeEnum {
    SET_EDIT_MODE = "SET_EDIT_MODE",
    RESET_EDIT_MODE = "RESET_EDIT_MODE",
  }
  
  export enum ReviewFieldEnum {
    TITLE = "title",
    NAME = "name",
    DATE_OF_BIRTH = "dateOfBirth",
    ADDRESS="address"
  }
  
  export enum ReviewNameFieldEnum {
    FIRST_NAME = "firstName",
    MIDDLE_NAME = "middleName",
    LAST_NAME = "lastName",
  }
  

  export enum AddressFieldEnum{
    ADDRESS_ONE="addressline1",
    ADDRESS_TWO="addressline2",
    ADDRESS_THREE="addressline3",
    ADDRESS_FOUR="addressline4",
    POST_CODE="postcode",
    POST_TOWN="posttown",
  }