import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MonuUnion from "../../assest/monu_union.svg";
import cancelImg from "../../assest/monu_upcoming_transaction_cancel.svg";
import { Img } from "../../common/basic components/img";
import { formatDateWithoutTime } from "../../common/utils/dashboardButtonsConditions";
import { patchToAPI } from "../../services/appApi";
import { WITHDRAWAL_AMOUNT_NOTICE_ACCOUNT } from "../../services/url";
import styles from "../../styleModules/dashboard.module.scss";
import Strings from "../../theme/string";

export const CancelWidge = (props: any) => {
  const {item} = props;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const cancelProcessing = async () => {
    setIsLoading(true)
    const bodyObj = {
      "withdrawalNoticeDetails": {
        "amount": item.amount,
        "status": "Cancelled"
      }
    }
    const res = await patchToAPI(`${WITHDRAWAL_AMOUNT_NOTICE_ACCOUNT}/${item.noticeId}`,bodyObj);
    if(res)
    {
      setIsLoading(false);
      navigate("/confirmCancel");
    }
  };

  const checkWithdrawalTime = (tmpTime:string) =>{
    const curTime : any = new Date();
    const withdrawalTime : any = new Date(tmpTime);
    const diff = withdrawalTime - curTime;
    return (diff - 1000 * 60 * 60 * 24) > 0  ;
  }

  return (
    <>
      <div className="transactionSection border-top py-3 px-3">
        <div className="row">
          <div className="col-8 contentAreaCopy">
            <div className="row">
              <div className="col-1">
                <Img src={cancelImg} />
              </div>
              <div className="col-11 ps-2">
                <div className={styles.transactiontitle}>
                  <span>{`${Strings.withdrawaTo} ${item.withdrawToAccount}`}</span>
                </div>
                <div className={styles.transactionDate}>
                  <span>
                    {`${Strings.dueOn} ` +
                      formatDateWithoutTime(item.withdrawalDate)}
                  </span>
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div className="col-4 iconAreaCopy">
            <div className={styles.transactionAmount}>{item.amount}</div>
            <a className="point-btn" onClick={() => setOpenConfirmation(true)}>
              {Strings.btnCancel}
            </a>
          </div>
        </div>
      </div>
      {openConfirmation && <div className="container mb-2">
        <div
          className={`copySection accountDetailsSection border`}
          style={{ width: "100%" }}
        >
         
          {!checkWithdrawalTime(item.withdrawalDate) ?
            <div className="row d-flex flex-wrap justify-content-end">
              <div className={styles.transactionCancelTitle}>
              {Strings.yourWithdrawalBeingPro}  
              </div>
              <div className={styles.transactionCancelDiscription}>
              {Strings.cancelTransactionDis}
              </div>
              <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary_one py-1 px-2"
                onClick={() => setOpenConfirmation(false)}
              >
                {Strings.refresh}
              </button>
              </div>
            </div>
            : <div className="row">
            <div className="col-8 contentAreaCopy">
              {Strings.wouldYouLikeCancel}
            
          </div><div className="row d-flex flex-wrap justify-content-end">
            <div className="col-auto">
              <button
                className="btn btn-primary_one py-1 px-2 btn-primary_one-outlined"
                onClick={() => setOpenConfirmation(false)}
              >
                {Strings.no}
              </button>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-primary_one py-1 px-2"
                onClick={() => cancelProcessing()}
              >
                {isLoading && (
                  <Img
                    src={MonuUnion}
                    loading="lazy"
                    height="20"
                    width="20"
                    alt="User Profile Picture"
                  />
                )}
                {!isLoading ? (
                  <span className="ps-1">{Strings.yesCancelRequest}</span>
                ) : (
                  <span className="ps-1">{`${Strings.processing} ${Strings.continueDot}`}</span>
                )}
              </button>
            </div>
          </div>
          </div>
          }          
        </div>
      </div>}
    </>
  );
};
