import React from "react";
import { useNavigate } from "react-router-dom";
import confirmationCheck from "../../assest/monu_successcheck.svg";
import Strings from "../../theme/string";
import { Button } from "../basic components/button";
import { Img } from "../basic components/img";

export const VerifyCustom = () => {
  const navigate = useNavigate();
  return (
    <div>
      <section className="container-xxl mt-5 customWidth921">
        <div className="terms-conditions-choosePlan consent-choosePlan p-5">
          <div className="text-center">
            <div>
              <Img
                src={confirmationCheck}
                alt="Check List Icon"
                aria-label="Check List Icon"
              />
            </div>
            <div className="row font-size-36px">
              <div
                className="col fw-bold"
              >
                {Strings.withdrawalRequestSuccCanceled}
              </div>
            </div>
            <Button
              label={Strings.closed}
              onClick={() => 
                navigate(-1)
              }
              className="btn btn-lg btn-primary_one"
            ></Button>
          </div>
        </div>
      </section>
    </div>
  );
};
