import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CREATE_PARTY_API } from "./url";

interface MarketingPreferences {
  smsOptIn: boolean;
  emailOptIn: boolean;
  postOptIn: boolean;
}

export const updateMarketingPreferences = createAsyncThunk<
  MarketingPreferences,
  { updatedPreferences: MarketingPreferences }
>("marketingPreferences/update", async ({ updatedPreferences }) => {
  try {
    const token = await localStorage.getItem("tokenVal");
    if (!token) {
      throw new Error("Token not found");
    }
    const body = {
      PartyDetails: updatedPreferences,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };
    const response = await axios.patch(CREATE_PARTY_API, body, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("error while updating marketing preferences:", error);
    throw error;
  }
});
