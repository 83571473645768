import React, { FunctionComponent, useState } from "react";
import { Input } from "../../common/basic components/input";
import styles from "../../styleModules/reviewStepper.module.scss";
import { EditButtonComponent } from "../choosePlan/ReviewStepper/ReviewSubComponents/miscComponents/editButtonComponent";
import { SaveButtonComponent } from "../choosePlan/ReviewStepper/ReviewSubComponents/miscComponents/saveButtonComponent";

export const HelperLinkBankAccount = (props: any) => {
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [dataSaved, setDataSaved] = useState(false);
  const [isSavedButtonClicked, setIsSavedButtonClicked] = useState(false);
  const {
    mainObj,
    label,
    placeholder,
    value,
    updateInput,
    helperText,
    maxLength,
    isError,
    errorMessage,
    fieldName,
    editFlag,
    stepperDetails,
    isEditButtonRequired = true
  } = props;

  const enableNdDisableField = () => {
    if (dataSaved && isEditEnabled && editFlag) return true;
    if (dataSaved) return true;
    if (editFlag && !isEditEnabled) return true;
    if (dataSaved && editFlag) return true;
  };

  const editModeHandler = () => {
    updateInput(fieldName, mainObj?.[fieldName].value);
    setIsEditEnabled(true);
    setIsSavedButtonClicked(false);
  };

  const saveModeHandler = () => {
    if (isError) setIsSavedButtonClicked(true);
    if (!isError) {
      setIsEditEnabled(false);
      setDataSaved(false);
      updateInput(fieldName, mainObj?.[fieldName]?.draftValue, true);
    }
  };

  return (
    <>
      {editFlag ? (
        <div className="d-flex align-items-center gap-4">
          <div>
            <label className="form-label">
              {label}
              <span className="text-danger">{isEditEnabled && ` *`}</span>
            </label>
          </div>
          {isEditEnabled && (
            <div className="mb-3">
              <SaveButtonComponent onClick={() => saveModeHandler()} />
            </div>
          )}
        </div>
      ) : (
        <p className="new-line lh-base">
          {label}
          <span className="text-danger">{` *`}</span>
        </p>
      )}

      {enableNdDisableField() && (
        <div className="col-md-8 col-lg-6 mb-6">
          <div className="d-flex">
            <div
              id={`formField-${fieldName}`}
              className={styles.genericFormFieldStyle}
              aria-label={`${fieldName} field`}
            >
              <div className={styles.genericFormFieldTextStyle}>
                {editFlag ? mainObj?.[fieldName]?.draftValue ?? value : value}
              </div>
            </div>
            {isEditButtonRequired && <EditButtonComponent onClick={() => editModeHandler()} />}
          </div>
        </div>
      )}

      {!enableNdDisableField() && (
        <InputRenderer
          label={label}
          placeholder={placeholder}
          value={editFlag ? mainObj?.[fieldName]?.draftValue ?? value : value}
          updateInput={(e: any) => updateInput(fieldName, e?.target?.value)}
          helperText={helperText}
          maxLength={maxLength}
          isError={
            (stepperDetails.buttonValidatonIsRequired ||
              isSavedButtonClicked) &&
            isError
          }
          errorMessage={errorMessage}
          setDataSaved={setDataSaved}
          setIsEditEnabled={setIsEditEnabled}
        />
      )}
    </>
  );
};

interface InputRendererProps {
  label?: string;
  placeholder?: string;
  value?: string;
  updateInput?: object;
  helperText?: string;
  maxLength?: number;
  errorMessage?: string;
  validateInput?: object;
  isError?: boolean;
  isEditEnabled?: string;
  setIsEditEnabled?: any;
  setDataSaved?: any;
}

const InputRenderer: FunctionComponent<InputRendererProps> = ({
  placeholder,
  value,
  updateInput,
  helperText,
  maxLength,
  errorMessage,
  validateInput,
  isError,
}) => {
  return (
    <div className="row mb-3">
      <div className="col-md-8 col-lg-6">
        <Input
          placeholder={placeholder}
          value={value}
          onChange={updateInput}
          className={`form-control ${isError && "input-error-border"}`}
          maxLength={maxLength}
          onBlur={validateInput}
        />
        {isError && (
          <div className="fst-italic font-size-12px text-danger">
            {errorMessage}
          </div>
        )}
        <span className="fst-italic font-size-12px">{helperText}</span>
      </div>
    </div>
  );
};
