import React from "react";
import { useParams } from "react-router-dom";
import { STEPPER_TITLES } from "../../constant/customConstant";

export const StepperTitle = ({ activeStep }: { activeStep: number }) => {
  const { id } = useParams();
  const disTitle = STEPPER_TITLES[activeStep]?.split("-");
  
  return (
    <div className="Stepper-title">
      <h3>
        <b>{id} - </b>
        {disTitle[1]}
      </h3>
    </div>
  );
};
