import React, { useMemo } from "react";
import { DateInput } from "../../../common/components/dateInput";
import Strings from "../../../theme/string";
import { userMaxAge } from "../../../common/utils/userMaxAge";
import { DobFieldProps } from "../../../interfaces/reviewStepper.interface";

const DobField = ({ stepperDetails, reviewFlag = false }: DobFieldProps) => {
  const fieldData = stepperDetails.stepperUserDetails.dateOfBirth;
  const { value, draftValue, isValidate, isdraftValidate } = fieldData || {};
  const selectedValue = reviewFlag ? draftValue : value;
  const selectedValidation = reviewFlag ? isdraftValidate : isValidate;

  const isError = useMemo(
    () => stepperDetails.buttonValidatonIsRequired && !selectedValidation,
    [stepperDetails.buttonValidatonIsRequired, selectedValidation]
  );

  return (
    <DateInput
      max={userMaxAge}
      lableValue={!reviewFlag ? Strings.dateOfBirth : undefined}
      labelDangerRequired={!reviewFlag}
      placeHolder={Strings.dateOfBirthPlaceholder}
      isBottomText
      bottomText={Strings.dateOfBirthBottomText}
      errorMsg={Strings.dateOfBirthErrorMsg}
      regExp={Strings.dateOfBirthRegex}
      isError={isError}
      id="dateOfBirth"
      value={selectedValue}
      blankErrorMsg={Strings.dateOfBirthCanNotBeBlank}
      spanclassName="fst-italic font-size-12px"
      reviewFlag={reviewFlag}
      mainValue={value}
      mainValueValidation={isValidate}
    />
  );
};
export default DobField;
