import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAccountTransactions,
  Transaction,
} from "../../services/fetchAccountTransactions";
import { sortTransactions } from "../../common/utils/sortTransactions";

interface TransactionState {
  total: number;
  data: Transaction[];
  isLoading: boolean;
  error: string | null;
}

const initialState: TransactionState = {
  data: [],
  total: 0,
  isLoading: false,
  error: null,
};

const accountDetailsTransactionsSlice = createSlice({
  name: "accountDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccountTransactions.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAccountTransactions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = sortTransactions(action.payload.data);
        state.total = action.payload?.total;
      })
      .addCase(fetchAccountTransactions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Failed to fetch data";
      });
  },
});

export default accountDetailsTransactionsSlice.reducer;
