import { createSlice } from "@reduxjs/toolkit";
import { getIndustriesListAction } from "../actions/formSelectListsAction";

const initialState = {
    industriesList: []
};

export const industriesListSlice = createSlice({
  name: "industriesList",
  initialState,
  reducers: {
    clearStates: () => initialState,
  }, 
  extraReducers: (builder) => {
    builder.addCase(getIndustriesListAction.fulfilled, (state : any, { payload }) => {
      state.industriesList = payload
    });
  }
});

export const { clearStates } =
industriesListSlice.actions;

export default industriesListSlice.reducer;