"use client";
import React from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
// import { LDModal } from "./common/components/withdraw/LDModal";
// import { WarningIcon } from "./common/components/withdraw/icons";

function ErrorFallback({ error }) {
  const navigate = useNavigate();
  const { resetBoundary } = useErrorBoundary();

  const resetBoundaryAndRedirect = () => {
    resetBoundary();
    navigate(`/`);
  };

  return (
    // <LDModal
    //   show={true}
    //   size="md"
    //   title={"Error Page"}
    //   onClose={() => resetBoundaryAndRedirect()}
    //   onSubmit={() => resetBoundaryAndRedirect()}
    //   centered={true}
    //   submitLabel="Ok"
    //   isDisplaySecondary={false}
    // >
      <div className="row">
        <center>
          <label className="form-label">
            <b>
              {/* <WarningIcon />{" "} */}
              {`Error: Something isn't working or is missing. We apologize for
              this problem.`}
            </b>
          </label>
          <pre className="text-danger">Reason: {error?.message}</pre>
        </center>
      </div>
    // </LDModal>
  );
}

export default ErrorFallback;