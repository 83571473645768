import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAccountProductPublic } from "../../services/publicApi";

export const getSavingPublicProductAction = createAsyncThunk(
  "savingPublicProduct",
  async() => {
    try {
      const res = await getAccountProductPublic('');
      return res;
    } catch (error) {
      console.error(error);
    }
  }
);