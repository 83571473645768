import RightArrow from "../../common/basic components/RightArrow";
import { Link } from "react-router-dom";
import styles from "../../styleModules/dashboard.module.scss";
import { formatBalance } from "../../common/utils/formatBalance";
import DashboardButtons from "./DashboardButtons";
import { extractSortCodeAndAccount } from "../../common/utils/extractSortCodeAndAcc";
import useMaturityCheck from "../../hooks/useMaturityCheck";

const DashboardAccountWidgets = ({ account }: any) => {
  const { sortCode, accountNumber } = extractSortCodeAndAccount(
    account.accountNumber
  );
  const { isWithinMaturedDays } = useMaturityCheck(account);

  return (
    <div
      key={account.accountNumber}
      className={`border rounded col-12 col-md-6 col-lg-3 m-2 py-2`}
      style={{ minWidth: "416px" }}
    >
      <div className="d-flex justify-content-between">
        <p className={`fw-semibold ${styles.accountName}`}>
          {account.accountName}
        </p>
        <Link to={`/accountdetails/${account.accountNumber}`}>
          <RightArrow />
        </Link>
      </div>
      <div className={styles.darkGrayColor}>
        <span>{sortCode}</span>
        <span className="px-1">|</span>
        <span>{accountNumber}</span>
      </div>
      <div className={styles.accountBalance}>
        {formatBalance(account.balance)}
      </div>
      <div className={styles.darkGrayColor}>
        <span>{account.interestRate} Gross</span>
        <span className="px-1">/</span>
        <span>{account.aer} AER</span>
      </div>
      <hr />
      <DashboardButtons account={account} />
      <div className="my-1">
        {isWithinMaturedDays && (
          <button
            className={`btn btn-primary_one py-1 px-2 ${styles.btnTextSize}`}
          >
            {`Choose Maturity Options`}
          </button>
        )}
      </div>
    </div>
  );
};

export default DashboardAccountWidgets;
