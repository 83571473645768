import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks";
import { getMiddlePageBottomDetailsAction } from "../../redux/actions/middlePageBottomAction";
import { getMiddlePageTopDetailsAction } from "../../redux/actions/middlePageTopAction";
import { MiddleBottomPage } from "./middleBottomPage";
import { MiddleTopPage } from "./middleTopPage";

export const MiddlePage = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch<any>(getMiddlePageBottomDetailsAction());
    dispatch<any>(getMiddlePageTopDetailsAction());
  }, []);

  const middlePageBottom  = useAppSelector((state : any) => state.middlePageBottom);

  return (
    <>
      <MiddleTopPage />
      {middlePageBottom?.middlePageObj.map((item : any) => {
        return <MiddleBottomPage {...{ item }} />;
      })}
    </>
  );
};
