import React from "react";

const RightArrow = () => {
  return (
    <div className="row">
      <div className="col-md-8 col-lg-6">
        <div className="" role="button">
          <span>
            <img
              alt={"Right Arrow Icon"}
              src={require(`../../assest/OpenLinkRightChevron.png`)}
              className="open-link-right-rotated-icon"
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default RightArrow;
