import { useEffect, useState } from "react";
import TickIconWithBourndry from "../../assest/monu_tickmark_outercircle.svg";
import MonuUnion from "../../assest/monu_union.svg";
import { Img } from "../../common/basic components/img";
import { updatedLinkUKBank } from "../../redux/reducer/stepperSubProductSlice";
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks";
import { patchToAPI } from "../../services/appApi";
import { FETCH_FINANCIAL_ACCOUNT } from "../../services/url";
import styles from "../../styleModules/dashboard.module.scss";
import Strings from "../../theme/string";
import { HelperRadioButtonLinkBankAccount } from "../linkBankAccount/helperRadioButtonLinkAccount";

function InterestPaidWidget(props: any) {
  const { payInterestTo, hashedAccount } = props;
  const dispatch = useAppDispatch();
  const { stepperDetails } = useAppSelector((state: any) => state);
  const mainObj = stepperDetails.stepperLinkUKBank;
  const [isLoading, setIsLoading] = useState(false);
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  const [isUserChoosen, setIsUserChoosen] = useState(false);
  const [isInterestPaidUpdated, setIsInterestPaidUpdated] = useState(false);
  const updateEditHandler = async (param: string) => {
    setIsUserChoosen(false);
    if (param === Strings.update) {
      setIsLoading(true);
      const obj = { payInterestTo : mainObj?.interestBeingPaid?.value}
      const res = await patchToAPI(FETCH_FINANCIAL_ACCOUNT+hashedAccount,obj);
      if(res)
      {
        setIsLoading(false);
        setIsEditModeActive(!isEditModeActive);
        setIsInterestPaidUpdated(true);
      }
    } else {
      setIsInterestPaidUpdated(false);
      setIsEditModeActive(!isEditModeActive);
    }
  };

  const patchUpdatedData = () => {
    setIsInterestPaidUpdated(false);
  }

  const dispatching = (param: string) => {
    setIsUserChoosen(true);
    dispatch<any>(
      updatedLinkUKBank({
        fieldName: Strings.interestBeingPaid,
        value: param,
        isValidate: true,
      })
    );
  };

  useEffect(()=>{
    dispatch<any>(
      updatedLinkUKBank({
        fieldName: Strings.interestBeingPaid,
        value: payInterestTo,
        isValidate: true,
      })
    );
  },[])

  return (
    <>
      <div
        key={"account.accountNumber"}
        className={`border rounded col-12 col-md-6 col-lg-3 py-2 px-2 mt-3 w-100`}
      >
        <div className="d-flex justify-content-between">
          <div>
            <span>{Strings.interest}</span>
          </div>

          {isEditModeActive && (
            <div>
              <button
                className={`btn-primary_one btn  py-1 px-2`}
                onClick={(e: any) => updateEditHandler(e.target.value)}
                value={Strings.update}
                disabled={!isUserChoosen}
              >
                {isLoading && (
                  <Img
                    src={MonuUnion}
                    loading="lazy"
                    height="30"
                    width="30"
                    alt="User Profile Picture"
                  />
                )}
                {!isLoading
                  ? Strings.update
                  : <span className="ps-1">{`${Strings.processing} ${Strings.continueDot}`}</span>}
              </button>
            </div>
          )}
        </div>

        <div className="copySection accountDetailsSection mt-2">
          <div className="row">
            {!isEditModeActive && (
              <div className="col-8 contentAreaCopy">
                <div className={styles.darkGrayColor}>
                  <span>{Strings.payInterestTo}</span>
                </div>
                <div className={styles.copyTextColor}>
                  <span>
                    {payInterestTo}
                  </span>
                </div>
              </div>
            )}
            {!isEditModeActive && (
              <div className="col-4 iconAreaCopy">
                <button
                  className={`btn btn-primary_one py-1 px-2`}
                  onClick={(e: any) => updateEditHandler(e.target.value)}
                  value={Strings.edit}
                  disabled={!payInterestTo}
                >
                  {Strings.edit}
                </button>
              </div>
            )}
            {isEditModeActive && (
              <div className="gap-4 mb-3">
                <HelperRadioButtonLinkBankAccount
                  fieldName={Strings.interestBeingPaid}
                  editFlag={false}
                  label={Strings.linkBankInterestRadioLabel}
                  id={Strings.fixedTermDeposit}
                  mainObj={mainObj}
                  dispatching={dispatching}
                />
              </div>
            )}
          </div>
        </div>
        {isInterestPaidUpdated && (
          <div
            key={"account.accountNumber"}
            className={`border rounded col-12 col-md-6 col-lg-3 py-2 px-2 mt-3 w-100`}
          >
            <div className="row d-flex">
              <div className="col-1 m-2">
                <Img src={TickIconWithBourndry} className="bullet-height" />
              </div>
              <div className="col-10">
                <p className="font-size-26px font-weight-bold mb-0"> {Strings.payInterestToUpdate}</p>
                <div className="my-3">
                  {Strings.yourInterestWillPaidToLinkedAccount}
                </div>
                <div className="">
                  <button
                    className={`btn-primary_one btn  py-1 px-2`}
                    onClick={(e: any) => patchUpdatedData()}
                    value={Strings.update}
                  >
                    {Strings.done}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default InterestPaidWidget;
