import { createAsyncThunk } from "@reduxjs/toolkit";
import { getNavigationDetails } from "../../services/appApi";

export const getNavigationBarAction = createAsyncThunk(
  "navigationBar",
  async() => {
    try {
      let tmpOrg = localStorage.getItem("organization"); 
      const res = await getNavigationDetails(tmpOrg);
      return res;
    } catch (error) {
      console.error(error);
    }
  }
);  

