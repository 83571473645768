import React, { useCallback, useEffect, useState } from "react";
import { Checkbox } from "../../../common/basic components/checkBox";
import {
  ReviewData,
  RootState,
} from "../../../interfaces/reviewStepper.interface";
import {
  updateCheckList,
  updateReviewConfirmation,
} from "../../../redux/reducer/stepperSubProductSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/redux-hooks";
import { getStepperReviewData } from "../../../services/appApi";
import Strings from "../../../theme/string";
import Styles from "../../../styleModules/commonHeading.module.scss"
import LinkedAccountReviewComponent from "./ReviewSubComponents/LinkedAccountReviewComponent";
import { NationalityAndTaxReviewComponent } from "./ReviewSubComponents/NationalityAndTaxReviewComponent";
import PersonalDetailsReviewComponent from "./ReviewSubComponents/PersonalDetailsReviewComponent";
import ProductDetailsViewComponent from "./ReviewSubComponents/ProductDetailsViewComponent";
import StepperEmploymentDetails from "../../employmentDetails/stepperEmploymentDetails";

const StepperReviewComponent = () => {
  const { reviewConfirmation, buttonValidatonIsRequired, stepperCheckList } =
    useAppSelector((state: RootState) => state.stepperDetails);
  const [reviewData, setReviewData] = useState<ReviewData>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    apiHandler();
  }, []);

  const apiHandler = useCallback(async () => {
    const apiData = await getStepperReviewData();
    if (apiData) {
      setReviewData(apiData);
    } else {
      console.error("Error fetching review data!");
    }
  }, []);

  return (
    <section aria-labelledby="review-stepper-section">
      <header id="review-stepper-header" className="mb-4">
        <div>{Strings.reviewPagelandingText}</div>
      </header>
      <article>
        {/* UNCOMMENT THE BELOW WHEN YOU WORK ON THEM */}
        <ProductDetailsViewComponent />
        <PersonalDetailsReviewComponent />
        <NationalityAndTaxReviewComponent editFlag={true} />
        <LinkedAccountReviewComponent />
        <StepperEmploymentDetails editFlag={true} />
        <div className="col-md-8 col-lg-6 mt-5">
          <div className={`terms-conditions-choosePlan ${buttonValidatonIsRequired && !reviewConfirmation && !stepperCheckList.reviewConfirmation.value && "input-error-border"}`}>
            <Checkbox
              onChange={() => {
                dispatch(
                  updateCheckList({
                    obj: {
                      value: !stepperCheckList.reviewConfirmation.value,
                      date: new Date().toISOString(),
                    },
                    fieldName: Strings?.reviewConfirmation,
                  })
                );
              }}
              label={Strings.reviewPageCheckBoxLabel}
              isChecked={stepperCheckList.reviewConfirmation.value}
              inputClassName={"checkbox-width-zero"}
              labelClassName={reviewConfirmation ? "text-primary_one" : ""}
              aria-label="Confirmation checkbox"
            />
          </div>
          {buttonValidatonIsRequired && !reviewConfirmation && !stepperCheckList.reviewConfirmation.value && (
            <div className={`${Styles.errorHeadingSmallerStyle} py-1`}>{Strings.pleaseSelectToContinue}</div>
          )}
        </div>
      </article>
    </section>
  );
};
export default StepperReviewComponent;
