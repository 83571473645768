import React from "react";
import { Img } from "../basic components/img";
import ImgPath from "../../common/constant/imgConstanct";
import TickIconWithBourndry from "../../assest/monu_Mastertick.svg"; 
import our_mission_img_first from "../../assest/our_mission_img_first.svg";
import our_mission_img_sec from "../../assest/our_mission_img_sec.svg";
import arrow_right from "../../assest/arrow_right.svg";

export const MiddleBottomPage = (props : any) => {
  const {title, dis, extra, src, contentBold} = props.item;
  let tmpItem = "";
  return (
    <section id="middlePageBottom" className="align-items-center container-xxl mission-card">
      <div className="card">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-md-7">
                <h2 className="card-title">{title}</h2>
                <ul className="ul-img-add">
                {dis.map((item : any)=>{
                    if(contentBold){
                      tmpItem = item.split('.');                                    
                      return <li key={tmpItem[0]}><div className="d-flex"><Img src={TickIconWithBourndry} className="bullet-height"/> <span className="card-text"><strong>{tmpItem[0]}.</strong>{tmpItem[1]}.</span></div></li>              
                    }
                    else
                      return <li><div className="d-flex"><Img src={TickIconWithBourndry} className="bullet-height"/> <span className="card-text">{item}</span></div></li>
                  })}
                </ul>
                {!contentBold && <a href="#" className="card-link"> 
                  {extra}
                  <Img
                    src={arrow_right}
                    width="16px"
                    height="16px"
                  />
                </a>}
            </div>
            <div className="col-md-5 text-end">
              <Img
                src={!contentBold ? our_mission_img_first: our_mission_img_sec}
                className="img-fluid rounded-start"
                alt="manu_saving"
              />
            </div>
          </div>
        </div>
      </div>
      </section>
  );
};
