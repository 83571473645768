import { Img } from "../../common/basic components/img";
import withdrawalSection from "../../assest/withdrawalSection.svg";
import confirmSection from "../../assest/confirm.svg";
import headingStyles from "../../styleModules/commonHeading.module.scss";
import { Button } from "../../common/basic components/button";
import { useLocation, useNavigate } from "react-router-dom";
import Strings from "../../theme/string";
import { formatDateWithOrdinalSuffix } from "../../common/utils/formatDateWithOrdinalSuffix";
import { AccountTypes } from "../../enums/customerService.enums";
import { useMemo } from "react";

const ConfirmWithdrawalComponent = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { type, from, withdrawalDate } = state || {};

  const imageSrc = useMemo(() => {
    return type === AccountTypes.NOTICE_ACCOUNT
      ? withdrawalSection
      : confirmSection;
  }, [type]);

  const headingText = useMemo(() => {
    return type === AccountTypes.NOTICE_ACCOUNT
      ? Strings.withdrawalConfirmed
      : Strings?.easyAccessMainWithdrawText;
  }, [type]);

  const messageText = useMemo(() => {
    return type === AccountTypes.NOTICE_ACCOUNT
      ? `${Strings.withdrawSubTextConfirmOne} ${from} ${
          Strings.withdrawSubTextConfirmTwo
        } ${formatDateWithOrdinalSuffix(withdrawalDate)}.`
      : `${Strings.easyAccessWithdrawTextOne} ${from} ${Strings.easyAccessWithdrawTextTwo}`;
  }, [type, from, withdrawalDate]);

  return (
    <section className="d-flex justify-content-around">
      <div className="terms-conditions-choosePlan consent-choosePlan p-5 customWidth800">
        <div className="text-center">
          <div>
            <Img
              src={imageSrc}
              alt="withdrawal section icon"
              aria-label="Withdrawal Section Icon"
              loading="lazy"
            />
          </div>
          <div className="row gap-3">
            <div className={`${headingStyles.headingMedium} mt-4`}>
              {headingText}
            </div>
            <div className={headingStyles.subHeading}>{messageText}</div>
            <div>
              <Button
                label="Done"
                className="btn btn-md btn-primary_one"
                onClick={() => navigate("/dashboard")}
                aria-label="Confirm withdrawal and return to dashboard"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ConfirmWithdrawalComponent;
