import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ImgPath from "../../common/constant/imgConstanct";
import { fetchProductDetailsGraphqlData } from "../../services/graphqlApis/apis/productDetailsApi";
import { GRAPHQL_API } from "../../services/url";
import Strings from "../../theme/string";
import productCategoryData from "../../assest/json/savingAccountProduct.json";

export const SavingScreen = () => {
  const navigate = useNavigate();
  const [productCategory, setProductCategory] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const productDetailsGraphqlResponse = await fetchProductDetailsGraphqlData(
          GRAPHQL_API
        );
        if (
          productDetailsGraphqlResponse &&
          typeof productDetailsGraphqlResponse === "object"
        ) {
          const tempObj = productDetailsGraphqlResponse?.data?.productDetailsData?.productcontent?.productdataCollection?.items;
          const updatedObj = tempObj?.map((item:any)=>{
            return {
              "id" : item._id,
            "title": item.content,
            src : item.iconimg.url,
            productFamily:item.content,
            description:item.productdescriptionlist,
            btnName:item.btnname
            }
          })
          // For testing purpose only Will remove after Contentful will work
          if(updatedObj)
            setProductCategory({data:updatedObj});
          else
            setProductCategory(productCategoryData);
        }
      } catch (error) {
        setProductCategory(productCategoryData);
        console.error("Error fetching footer data", error);
      }
    })();
  }, []);

  return (
    <div className="container savings-selectaccount-container">
      <div className="row">
        <div>
          <h3>{Strings.selectCategoryTitle}</h3>
        </div>
        <div>
          <span>{Strings.selectCategoryDescription}</span>
        </div>
      </div>
      <div className="row category-cards-row">
        {productCategory?.data.map((pData: any) => (
          <div className="col" key={pData?.id}>
            <div className="account-item-parent">
              <div className="category-details-content">
                <div className="image-container">
                  <img
                    src={pData?.src}
                    className="account-type-image"
                    alt={`${pData?.title} icon`}
                  />
                </div>
                <h4 className="category-title">{pData?.title}</h4>
                <div className="product-pointers-container">
                  {pData?.description.map((dText: string, key: number) => (
                    <div key={key} className="pointer-point">
                      <span>
                        <ImgPath.DiamondTickIcon />
                      </span>
                      <span>{dText}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  onClick={() => navigate(`/onBoardingStart/${pData?.title}`)}
                  className={`btn btn-primary_one btn-lg border-radius-0 font-size-16px width-100-percent`}
                >
                  {pData?.btnName}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
