export const dateFormat = (date : any =new Date(), join='/', format="DDMMYYYY") =>{
  const tmpDate = date;
  const todayDate = tmpDate.getDate() - 10 < 0 ? '0' + tmpDate.getDate() : tmpDate.getDate() ;
  const todayMonth = tmpDate.getMonth() - 10 < 0 ? '0' + tmpDate.getMonth() : tmpDate.getMonth();
  const todayYear = tmpDate.getFullYear();
  if(format == 'YYYYMMDD')
    return (todayYear + join +  todayMonth + join + todayDate)
  else 
    return(todayDate + join +  todayMonth + join + todayYear)
}
