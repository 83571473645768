import { createAsyncThunk } from "@reduxjs/toolkit";
import { getWithdrawalAccount } from "../../services/appApi";

export const getWithdrawalAccountListAction = createAsyncThunk(
  "withdrawalAccount",
  async(hashedAccount:any) => {
    try {
      const res = await getWithdrawalAccount(hashedAccount);
      return res;
    } catch (error) {
      console.error(error);
    }
  }
);
