export const findIndexInArr = (arr: any, key: any, searchType: string) => {
    arr.findIndex((item: any) => {
        if (item[key] == searchType) {
            return item;
        }
    })
}

export const sortCodeAutoFormat = (sortCode: string): string => {
    const tempSortCode = sortCode.trim().replace(/[^0-9]/g, "");
    if (tempSortCode.length < 2) return tempSortCode;
    if (tempSortCode.length < 4) return tempSortCode.replace(/(\d{2})(\d{1})/, "$1-$2");
    if (tempSortCode.length < 6) return tempSortCode.replace(/(\d{2})(\d{2})(\d{1})/, "$1-$2-$3");
    return tempSortCode.replace(/(\d{2})(\d{2})(\d{2})/, "$1-$2-$3");
};

export const numberFormat = (numberParam: string): string => {
    const tempSortCode = numberParam.trim().replace(/[^0-9]/g, "");
    return tempSortCode;
};

export const numberFormatWithDecimal = (numberParam: string): string => {
    const tempSortCode = numberParam.trim().replace(/[^0-9.]/g, "");
    return tempSortCode;
};

export const findValueFromFieldNameFromArray = (arr :any, label : string ) => {
    return arr.map((item : any)=> item[label])
}