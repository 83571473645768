import { createSlice } from "@reduxjs/toolkit";
import { updateMarketingPreferences } from "../../services/updateMarketingPreferences";

const initialState = {
  smsOptIn: false,
  emailOptIn: false,
  postOptIn: false,
  status: "idle",
};

const marketingPrefencesSlice = createSlice({
  name: "marketingPrefences",
  initialState,
  reducers: {
    toggleSmsOptIn: (state) => {
      state.smsOptIn = !state.smsOptIn;
    },
    toggleEmailOptIn: (state) => {
      state.emailOptIn = !state.emailOptIn;
    },
    togglePostOptIn: (state) => {
      state.postOptIn = !state.postOptIn;
    },
    setInitialPreferences: (state, action) => {
      state.smsOptIn = action.payload.smsOptIn ?? state.smsOptIn;
      state.emailOptIn = action.payload.emailOptIn ?? state.emailOptIn;
      state.postOptIn = action.payload.postOptIn ?? state.postOptIn;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateMarketingPreferences.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateMarketingPreferences.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.smsOptIn = action.payload.smsOptIn ?? state.smsOptIn;
        state.emailOptIn = action.payload.emailOptIn ?? state.emailOptIn;
        state.postOptIn = action.payload.postOptIn ?? state.postOptIn;
      })
      .addCase(updateMarketingPreferences.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {
  toggleSmsOptIn,
  toggleEmailOptIn,
  togglePostOptIn,
  setInitialPreferences,
} = marketingPrefencesSlice.actions;

export default marketingPrefencesSlice.reducer;
