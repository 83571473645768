import React from "react";
import {
  formatDateWithoutTime,
  hasSufficientBalance,
} from "../../common/utils/dashboardButtonsConditions";
import styles from "../../styleModules/dashboard.module.scss";
import Strings from "../../theme/string";
import { useLocation, useNavigate } from "react-router-dom";
import { DashboardButtons } from "../../interfaces/dashboardButtons.interface";
import useFundingPeriodValidation from "../../hooks/useFundingPeriodValidation";

const dashboardButtons: React.FC<DashboardButtons> = ({ account }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { accountType, balance, accountNumber } = account;

  const funded = hasSufficientBalance(balance);
  const { isFundingPeriodValid } = useFundingPeriodValidation(account);

  if (accountType === "FT") {
    return (
      <div>
        {isFundingPeriodValid && !funded && (
          <button
            className={`btn btn-primary_one py-1 px-2 ${styles.btnTextSize}`}
            onClick={() =>
              navigate(`/addmoney/${accountNumber}`, {
                state: { from: location?.pathname },
              })
            }
          >
            {Strings.dashboardButtonTextFT1}
            <span className="ps-1">
              {formatDateWithoutTime(account.depositRequiredByDate)}
            </span>
          </button>
        )}
        {isFundingPeriodValid && funded && (
          <button
            className={`btn btn-primary_one py-1 px-2 ${styles.btnTextSize}`}
            onClick={() =>
              navigate(`/addmoney/${accountNumber}`, {
                state: { from: location?.pathname },
              })
            }
          >
            {Strings.dashboardButtonTextFT2}
            <span className="ps-1">
              {formatDateWithoutTime(account.depositRequiredByDate)}
            </span>
          </button>
        )}
      </div>
    );
  } else if (accountType === "NT" || accountType === "EA") {
    return (
      <div className={`pb-2 ${styles.btnTextSize}`}>
        {funded ? (
          <div className="d-flex justify-content-start gap-2 align-items-start flex-wrap">
            <button
              className={`btn btn-primary_one py-1 px-2 ${styles.btnTextSize}`}
              onClick={() =>
                navigate(`/addmoney/${accountNumber}`, {
                  state: { from: location?.pathname },
                })
              }
            >
              {Strings.dashboardButtonAddMoney}
            </button>
            <button
              className={`btn btn-primary_three py-1 px-2 ${styles.btnTextSize}`}
              onClick={() =>
                navigate(`/moveMoney/${accountNumber}`, {
                  state: { from: location?.pathname },
                })
              }
            >
              {Strings.dashboardButtonMoveMoney}
            </button>
            <button
              className={`btn btn-primary_two py-1 px-2 ${styles.btnTextSize}`}
              onClick={() =>
                navigate(`/withdrawMoney/${accountNumber}`, {
                  state: { from: location?.pathname },
                })
              }
            >
              {Strings.dashboardButtonWithdraw}
            </button>
          </div>
        ) : (
          <div className="d-flex justify-content-start gap-2 align-items-start flex-wrap">
            <button
              className={`btn btn-primary_one text-nowrap py-1 px-2 ${styles.btnTextSize}`}
              onClick={() =>
                navigate(`/addmoney/${accountNumber}`, {
                  state: { from: location?.pathname },
                })
              }
            >
              {Strings.dashboardButtonMakeYourFirst}
            </button>
          </div>
        )}
      </div>
    );
  } else {
    return <>No Data</>;
  }
};

export default dashboardButtons;
