export const extractSortCodeAndAccount = (
  combined: string | undefined | null
) => {
  if (!combined) {
    console.error("Invalid combiled string passed: ", combined);
    return { sortCode: null, accountNumber: null };
  }
  const sortCode = `${combined.slice(0, 2)}-${combined.slice(
    2,
    4
  )}-${combined.slice(4, 6)}`;
  const accountNumber = combined.slice(6);
  return { sortCode, accountNumber };
};
