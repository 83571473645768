import styles from "../../styleModules/dashboard.module.scss";
import ContentCopy from "../../assest/content_copy.svg";
import { Img } from "../../common/basic components/img";
import { extractSortCodeAndAccount } from "../../common/utils/extractSortCodeAndAcc";
import { copyToClipboard } from "../../common/utils/copyToClipboard";

function ManageSavingAccountWidget({ data, isLoading }: any) {
  const { sortCode, accountNumber: savingAccountNumber } =
    extractSortCodeAndAccount(data[0]?.accountNumber || "") || {};

  const toShowPartyName = data[0]?.partyName;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div
      key={"account.accountNumber"}
      className={`border rounded col-12 col-md-6 col-lg-3 py-2 px-2 mt-3 width-100-percent`}
    >
      <div>
        <span>{`Monument account details`}</span>
      </div>

      <div className="copySection accountDetailsSection mt-2">
        <div className="row">
          <div className="col-8 contentAreaCopy">
            <div className={styles.darkGrayColor}>
              <span>{`Payee name`}</span>
            </div>
            <div className={styles.copyTextColor}>
              <span>{toShowPartyName}</span>
            </div>
          </div>
          <div className="col-4 iconAreaCopy">
            <Img
              src={ContentCopy}
              onClick={() => copyToClipboard(toShowPartyName)}
            />
          </div>
        </div>
      </div>
      <div className="copySection accountDetailsSection mt-2">
        <div className="row">
          <div className="col-8 contentAreaCopy">
            <div className={styles.darkGrayColor}>
              <span>{`Sort code`}</span>
            </div>
            <div className={styles.copyTextColor}>
              <span>{sortCode}</span>
            </div>
          </div>
          <div className="col-4 iconAreaCopy">
            <Img src={ContentCopy} onClick={() => copyToClipboard(sortCode)} />
          </div>
        </div>
      </div>
      <div className="copySection accountDetailsSection mt-2">
        <div className="row">
          <div className="col-8 contentAreaCopy">
            <div className={styles.darkGrayColor}>
              <span>{`Account Number`}</span>
            </div>
            <div className={styles.copyTextColor}>
              <span>{savingAccountNumber}</span>
            </div>
          </div>
          <div className="col-4 iconAreaCopy">
            <Img
              src={ContentCopy}
              onClick={() => copyToClipboard(savingAccountNumber)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageSavingAccountWidget;
