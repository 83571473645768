import React from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import { ChildItem, NavItem } from "../../interfaces/headerSection.interface";
import { useNavigate } from "react-router-dom";

export const DropDown = ({ heading, children }: NavItem) => {
  const navigate = useNavigate();
  return (
    <NavDropdown
      title={heading}
      aria-label={`${heading}dropdown`}
      id={`dropdown-${heading}`}
      role="menu"
    >
      {children?.map((child: ChildItem) => (
        <NavDropdown.Item
          onClick={() => navigate(child.navigation)}
          key={child.heading}
          aria-label={child?.heading}
          role="menuitem"
        >
          {child.heading}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
};
