import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  savingAccountProduct: []
};

export const savingAccountProductSlice = createSlice({
  name: "navigationBar",
  initialState,
  reducers: {
    clearStates: () => initialState,
}
});

export const { clearStates } =
savingAccountProductSlice.actions;

export default savingAccountProductSlice.reducer;
