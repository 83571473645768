import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styles from "../../styleModules/breadcrumbs.module.scss";
import navStrings from "../../theme/navStrings";
import Strings from "../../theme/string";
import { BreadcrumbProps, Route } from "../../interfaces/breadcrumb.interface";

const routes: Route[] = [
  { path: `${navStrings.addMoney}`, label: `${Strings.breadcrumbsAddmoney}` },
  {
    path: `${navStrings.moveMoney}`,
    label: `${Strings.breadcrumbsMovemoney}`,
  },
  {
    path: `${navStrings.withdrawMoney}`,
    label: `${Strings.breadcrumbsWithdrawmoney}`,
  },
];

const Breadcrumbs = ({ data, previousPath }: BreadcrumbProps) => {
  const [breadCrumbItems, setBreadCrumbItems] = useState<Route[]>([]);
  const location = useLocation();

  useEffect(() => {
    const breadcrumbs = [];

    breadcrumbs.push({ path: "/dashboard", label: "Home" });

    if (data?.accountName) {
      breadcrumbs.push({
        path: `/accountdetails/${data?.accountNumber}`,
        label: data?.accountName,
      });
    }

    const matchedRoute = routes.find((route) =>
      location.pathname.startsWith(route.path.split(":")[0])
    );

    if (matchedRoute) {
      breadcrumbs.push({ path: location.pathname, label: matchedRoute.label });
    }

    setBreadCrumbItems(breadcrumbs);
  }, [location.pathname, data, previousPath]);

  const breadCrumbList = breadCrumbItems.map((breadcrumb, index) => (
    <span key={breadcrumb.path}>
      <NavLink
        to={breadcrumb.path}
        className={({ isActive }) =>
          !isActive ? styles.breadcrumbActive : styles.breadcrumbLink
        }
      >
        {breadcrumb.label}
      </NavLink>
      {index < breadCrumbItems.length - 1 && " / "}
    </span>
  ));

  return <div aria-label="Breadcrumb navigation">{breadCrumbList}</div>;
};

export default Breadcrumbs;
