import React from "react";
import { Button } from "../../../common/basic components/button";
import Strings from "../../../theme/string";
import profileStyles from "../../../styleModules/profileStyles.module.scss";
import headingStyles from "../../../styleModules/commonHeading.module.scss";
import { Img } from "../../../common/basic components/img";
import saveTick from "../../../assest/saveTick.svg";

const ProfileAddressSuccessBox = ({
  setAddressSuccessfulUpdateModalFlag,
}: {
  setAddressSuccessfulUpdateModalFlag: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}) => {
  return (
    <section
      className={`${profileStyles.profileTintedBox} d-flex col-6 mt-2`}
      role="dialog"
      aria-modal="true"
      aria-labelledby="address-update-success"
    >
      <div className="d-flex mt-3 ms-4 gap-4">
        <div>
          <Img
            src={saveTick}
            className="img-fluid img-margin-top-75 stepper-img"
            alt="Save icon"
            width="40px"
            height="40px"
          />
        </div>
        <div className=" gap-4">
          <div id="address-update-success" className={headingStyles.heading}>
            {Strings?.addressUpdatedText}
          </div>
          <Button
            label="Done"
            className="btn btn-md btn-primary_one mt-2"
            onClick={() => setAddressSuccessfulUpdateModalFlag(false)}
            aria-label="Close address update success message"
          />
        </div>
      </div>
    </section>
  );
};

export default ProfileAddressSuccessBox;
