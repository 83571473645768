import Facebook from "../../../assest/monu_Facebook.svg";
import Instagram from "../../../assest/monu_Instagram.svg";
import LinkedIn from "../../../assest/monu_LinkedIn.svg";
import DownloadLinkAndriod from "../../../assest/monu_Apple.svg";
import DownloadLinkApple from "../../../assest/monu_Google.svg";
import FcsLogo from "../../../assest/monu_FSCS_logo.svg";

const iconMap: Record<string, string> = {
 FacebookIcon: Facebook,
 InstagramIcon: Instagram,
 LinkedInIcon: LinkedIn,
 AndroidIcon: DownloadLinkAndriod,
 AppleIcon: DownloadLinkApple,
 FcsLogoIcon: FcsLogo,
};
export default iconMap;