import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { FETCH_ACCOUNT } from "./url";
import { PAGINATION_ITEMS_COUNT } from "../common/constant/accountDetailsConstant";

export interface Transaction {
  transactionId: string;
  transactionDateTime: string;
  transactionType: string;
  description: string;
  amount: number;
  balance: number;
  total: number;
}

interface ResponseData {
  data: Transaction[];
  total: number;
}

export const fetchAccountTransactions = createAsyncThunk<
  ResponseData,
  { hashedAccount: string; activePage?: number }
>(
  "accountdetails/fetchAccountTransactions",
  async ({ hashedAccount, activePage }) => {
    try {
      const token = await localStorage.getItem("tokenVal");
      let URL = `${FETCH_ACCOUNT}${hashedAccount}/transaction-history?limit=${PAGINATION_ITEMS_COUNT}&offset=${activePage}`;
      const response = await axios.get(URL, {
        headers: {
          Authorization: token,
        },
      });
      return response.data;
    } catch (error: any) {
      throw error;
    }
  }
);
