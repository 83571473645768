import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userFirstName   : "",
    userMiddleName  : "",
    userLastName    : "",
    address         : "",
    pinCode         : "",
    phone           : "",
    country         : ""
    
};

export const customeCredentialSlice = createSlice({
  name: "customerCredential",
  initialState,
  reducers: {
    clearStates: () => initialState,
    setUserDetails: (state : any, action) => {
        state.userFirstName = action.payload.firstName;
        state.userMiddleName = action.payload.middleName;
        state.userLastName = action.payload.lastName;
        state.address = action.payload.address;
        state.pinCode = action.payload.pinCode;
        state.phone = action.payload.phone;
        state.country = action.payload.country;
    },
  },
});

export const {
    clearStates,
    setUserDetails
} = customeCredentialSlice.actions;

export default customeCredentialSlice.reducer;
