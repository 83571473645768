import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ALL_SAVING_ACCOUNT_SF } from "./url";

export interface Account {
  accountName: string;
  accountNumber: string;
  accountOwner: string;
  accountStatus: string;
  accountType: string;
  aer: number;
  balance: number;
  interestRate: number;
  creationDate: string;
}

export const fetchDashboardData = createAsyncThunk<{ data: Account[] }, void>(
  "dashbaord/fetchAccountData",
  async () => {
    try {
      const token = await localStorage.getItem("tokenVal");
      const response = await axios.get(ALL_SAVING_ACCOUNT_SF, {
        headers: {
          Authorization: token,
        },
      });

      return response.data;
    } catch (error: any) {
      throw error;
    }
  }
);
