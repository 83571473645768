import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks";
import { dashboardDiscoverMoreAction } from "../../redux/actions/dashboardDiscoverMoreAction";
import { Img } from "../../common/basic components/img";
import styles from "../../styleModules/dashboard.module.scss";
import RightArrow from "../../common/basic components/RightArrow";

const DiscoverMore = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch<any>(dashboardDiscoverMoreAction());
  }, []);

  const discoverMoreData: any = useAppSelector(
    (state: any) => state.dashboardDiscoverMore?.discoverMoreObj
  );

  const data = discoverMoreData?.products;

  const productsElement = data?.map((product: any) => {
    return (
      <div
        key={product.name}
        className={`border rounded col-12 col-md-6 col-lg-3 m-2 py-2`}
        style={{ minWidth: "416px", minHeight: "144px" }}
      >
        <div className="d-flex justify-content-between">
          <div className="p-3">
            <div className="d-flex justify-content-start align-items-center">
              <span className={`${styles.discoverProductHeading}`}>
                {product.name}
              </span>
              <Link to="#" className="mx-4">
                <RightArrow />
              </Link>
            </div>
            <p className={styles.discoverProductPara}>{product.description}</p>
            {product.links?.map((link: string) => (
              <ul className={`${styles.discoverProductLink}`}>
                <li className={`${styles.discoverProductLi}`}>
                  <Link
                    key={link}
                    to=""
                    className={`${styles.discoverProductPara}`}
                  >
                    {link}
                  </Link>
                </li>
              </ul>
            ))}
          </div>
          <div className="head-image">
            <Img src={product?.src} loading="lazy" alt="" />
          </div>
        </div>
      </div>
    );
  });

  return <>{productsElement}</>;
};

export default DiscoverMore;
