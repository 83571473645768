// Helper function to convert an ArrayBuffer to a base64 string

const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
  const byteArray = new Uint8Array(buffer);
  const binaryString = byteArray.reduce(
    (data, byte) => data + String.fromCharCode(byte),
    ""
  );
  return window.btoa(binaryString);
};

// Helper function to convert a base64 string to an ArrayBuffer
const base64ToArrayBuffer = (base64: string): ArrayBuffer => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

// Encrypt a message
export const encryptMessage = async (
  message: string,
  key: CryptoKey
): Promise<string> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const iv = crypto.getRandomValues(new Uint8Array(12)); // Initialization vector
  const encryptedData = await crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv,
    },
    key,
    data
  );
  return `${arrayBufferToBase64(iv)}:${arrayBufferToBase64(encryptedData)}`;
};

// Decrypt a message
export const decryptMessage = async (
  encryptedMessage: string,
  key: CryptoKey
): Promise<string> => {
  const [ivBase64, encryptedDataBase64] = encryptedMessage.split(":");
  const iv = base64ToArrayBuffer(ivBase64);
  const encryptedData = base64ToArrayBuffer(encryptedDataBase64);
  const decryptedData = await crypto.subtle.decrypt(
    {
      name: "AES-GCM",
      iv: iv,
    },
    key,
    encryptedData
  );
  const decoder = new TextDecoder();
  return decoder.decode(decryptedData);
};

// Generate a new AES key
export const generateKey = async (): Promise<CryptoKey> => {
  return crypto.subtle.generateKey(
    {
      name: "AES-GCM",
      length: 256,
    },
    true,
    ["encrypt", "decrypt"]
  );
};
