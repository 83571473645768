import Axios from "axios";

const API_URL = "https://graphql.contentful.com/content/v1/spaces/";
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer mGqVbHrr1rZN6wC4_YSu909yJOHKPjbVqixkONuodx4`,
};
const body = {
    "query":
      '{ emailVerificationFooter(id: "3qV41LZm1tuluO6V8T9nTZ") { monumentLogo { url } fscsLogo { url } fscsText copyright }}',
  }

const createInstance = (URL : any) => {
  return Axios.create({
    baseURL: URL,
    headers
  });
};

const basebackendDev = createInstance(API_URL);

export const fetchData = async () => {
  const spaceId = "dtnxxwebc5xx";
  const envId = "develop";

  let URLCONTAINFULL = `${spaceId}/environments/${envId}`;
  try {
    const { data } = await basebackendDev.post(URLCONTAINFULL, body);
  } catch (error) {
    console.error(error);
  } finally{
  }
};
