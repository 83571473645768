export enum AccountTypes {
  EASY_ACCESS = "EA",
  NOTICE_ACCOUNT = "NT",
  FIXED_TERM_DEPOSIT = "FT",
  EASY_ACCESS_COMPLETE = "Easy Access",
  NOTICE_ACCOUNT_COMPLETE = "Notice",
  FIXED_TERM_DEPOSIT_COMPLETE = "Fixed Term",
  // ADD OTHERS AS YOU WORK
}

export enum AccountStatus {
  LOCKED = "Locked",
  BLOCKED = "Blocked",
}
