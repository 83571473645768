import { createAsyncThunk } from "@reduxjs/toolkit";
import { getStepperSubProductDetails } from "../../services/appApi";

export const getStepperSubProductAction = createAsyncThunk(
  "stepperSubProduct",
  async () => {
    try {
      const res = await getStepperSubProductDetails();
      return res;
    } catch (error) {
      console.error(error);
    }
  }
);

