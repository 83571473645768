import { Transaction } from "../../services/fetchAccountTransactions";

export const sortTransactions = (transactions: Transaction[]) => {
  return transactions.sort((a: Transaction, b: Transaction) => {
    return (
      new Date(b.transactionDateTime).getTime() -
      new Date(a.transactionDateTime).getTime()
    );
  });
};
