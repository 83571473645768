import axios, { AxiosError } from "axios";
import { summaryBoxQuery } from "../queries/summaryBoxQuery";

export const fetchSummaryBoxGraphqlData = async (url: string) => {
    const data = summaryBoxQuery;
    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": 'application/json',
          Authorization: 'Bearer lBmuNKWeexAERNtCLBIfMAn-URK1_MKiD9IaePhHyBw',
        },
        
      });
      return response?.data;
    } catch (error) {
      const typedError = error as AxiosError;
      if (axios.isAxiosError(typedError)) {
        if (typedError?.response) {
          return typedError?.message || "An error occurred";
        } else if (typedError?.request) {
          return "No response received from server";
        }
      }
      return typedError?.message;
    }
  };