import React, { useState } from "react";
import { updateUserDetails } from "../../redux/reducer/stepperSubProductSlice";
import { useAppDispatch } from "../../redux/redux-hooks";
import Styles from "../../styleModules/commonHeading.module.scss"

export const DateInput = (props: any) => {
  const dispatch = useAppDispatch();
  const {
    value,
    onFocus,
    onBlur,
    placeHolder,
    id,
    lableValue,
    bottomText,
    spanclassName,
    labelDangerRequired,
    isBottomText,
    regExp,
    errorMsg,
    max,
    blankErrorMsg,
    isError,
    mainValue,
    mainValueValidation,
    reviewFlag,
  } = props;

  const maxDate : any= new Date(max);
  
  const validateInputAndUpdate = (e: any, key: any) => {
    const tmpVal = e.target.value;
    const tmpReg = new RegExp(regExp);
    const tmpValNewDate : any = new Date(tmpVal);
    const isValidate = tmpReg.test(tmpVal) && maxDate - tmpValNewDate >= 0;
    dispatch<any>(
      updateUserDetails({
        fieldName: key,
        value: !reviewFlag ? tmpVal : mainValue,
        isValidate: isValidate
          ? true
          : !reviewFlag
          ? false
          : mainValueValidation,
        draftValue: tmpVal,
        isdraftValidate: isValidate ? true : false,
      })
    );
  };

  return (
    <div className={`${!reviewFlag && "col-md-8 col-lg-6"}`}>
      {!reviewFlag && (
        <label htmlFor={id} className="form-label">
          {lableValue}
          {labelDangerRequired && <span className="text-danger"> * </span>}
        </label>
      )}
      <input
        type="date"
        className={`form-control text-uppercase ${isError && 'form-control-error'}`}
        id={id}
        placeholder={placeHolder}
        value={value}
        onChange={(e) => validateInputAndUpdate(e, id)}
        onFocus={onFocus}
        onBlur={onBlur}
        max={max}
      />
      {isError && (
        <div className="text-danger">{value ? errorMsg : blankErrorMsg}</div>
      )}
      {isBottomText && <span className={spanclassName}>{bottomText}</span>}
    </div>
     );
    };