import React from "react";
import { NavigationBar } from "./navigationBar";

export const Header = () => {
  return (
    <header id="header" className="header" role="banner">
      <NavigationBar />
    </header>
  );
};
