import { createSlice } from "@reduxjs/toolkit";
import { getMiddlePageTopDetailsAction } from "../actions/middlePageTopAction";

const initialState = {
  middlePageTopObj: []
};

export const middlePageTopSlice = createSlice({
  name: "middlePageTop",
  initialState,
  reducers: {
    clearStates: () => initialState,
  }, 
  extraReducers: (builder) => {
    builder.addCase(getMiddlePageTopDetailsAction.fulfilled, (state : any, { payload }) => {
      // TODO always keep in mind to take care wrong payload or empty payload        
      state.middlePageTopObj = payload
    });
  }
});

export const { clearStates } =
middlePageTopSlice.actions;

export default middlePageTopSlice.reducer;