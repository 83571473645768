import React, { useEffect } from "react";
import { getLifeStyleDetailsAction } from "../../redux/actions/lifestyleAction";
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks";
import { Img } from "../basic components/img";

const LifestyleCard = () => {
  const dispatch = useAppDispatch();
  useEffect(()=>{
    dispatch<any>(getLifeStyleDetailsAction());
  },[])
  const lifeStyle : any = useAppSelector((state : any) => state.lifeStyle);
  const lifeStyleObjDis = lifeStyle.lifeStyleObj.map((item : any) => {
    return (
        <div className="head-text">
          <div className="head-image">
            <Img
              src={item?.product?.src}
              id={item.id}
              loading="lazy"
              height="600"
              width="450"
              alt=""
            />
          </div>
          <div className="text-on-image">
            <p> {item?.product?.name} </p>
            <h3>{item?.product?.description} </h3>
          </div>
      </div>
    );
  });
  return <div className="container">
     <div className="temp-margin w-dyn-list">
          <div className="_3-column-grid w-dyn-items">
    {lifeStyleObjDis}</div>
    </div></div>;
};

export default LifestyleCard;
