import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLifeStyleDetails } from "../../services/appApi";

export const getLifeStyleDetailsAction = createAsyncThunk(
  "lifeStyle",
  async() => {
    try {
      const res = await getLifeStyleDetails();
      return res;
    } catch (error) {
      console.error(error);
    }
  }
);
