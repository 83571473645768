import { useEffect } from "react";
import { getCountryListAction } from "../../../../redux/actions/formSelectListsAction";
import { useAppDispatch, useAppSelector } from "../../../../redux/redux-hooks";
import Strings from "../../../../theme/string";
import { CitizenShipDisplay } from "../../../personalInformation/personalInformationFields/citizenShipDisplay";
import { CitizenShipTaxDisplay } from "../../../personalInformation/personalInformationFields/citizenShipTaxDisplay";
import stylesMemo from "../../../../../src/styleModules/memorableWord.module.scss";
import commonHeadingStyles from "../../../../styleModules/commonHeading.module.scss";
import micsStyles from "../../../../styleModules/commonMicsStyles.module.scss";

export const NationalityAndTaxReviewComponent = ({
  editFlag = false,
  isHeadingRequired = true,
  isEditBtnRequired = true,
}) => {
  const dispatch = useAppDispatch();
  const { countryList, stepperDetails } = useAppSelector((state: any) => state);
  const mainObj = stepperDetails?.citizenshipAndTaxSection;
  const tmpCountry =
    countryList?.countryList?.data?.records?.countriesAndStates?.countries;
  const Country = tmpCountry?.map((item: any) => {
    return { ...item, value: item.isoCode };
  });

  useEffect(() => {
    dispatch<any>(getCountryListAction());
  }, []);

  return (
    <>
      <div className="linkBankSection">
        <div className="col-md-8 col-lg-6 mt-4">
          <div
            className={`${commonHeadingStyles.commonBoldHighlightedHeading} mb-2`}
          >
            {Strings?.myProfileNationalTaxHeading}
          </div>
          <div className={micsStyles.customLine}></div>
        </div>
        <CitizenShipDisplay
          {...{
            mainObj,
            editFlag,
            stepperDetails,
            Country,
            isHeadingRequired,
            isEditBtnRequired,
          }}
        />
        <CitizenShipTaxDisplay
          {...{ mainObj, editFlag, stepperDetails, Country, isEditBtnRequired }}
        />
      </div>
    </>
  );
};
