import { createAsyncThunk } from "@reduxjs/toolkit";
import { getWelcomeBoardDetails } from "../../services/appApi";

export const getWelcomeBoardDetailsAction = createAsyncThunk( // TODO rename as action
  "lifeStyle",
  async() => {
    try {
      let tmpOrg = localStorage.getItem("organization"); 
      const res = await getWelcomeBoardDetails(tmpOrg);
      return res;
    } catch (error) {
      console.error(error);
    }
  }
);