import React from "react";
import productLogo from "../../assest/productLogo.svg";
import { Img } from "../basic components/img";

export const LogoDisplay = () => {
  return (
    <>
      <Img
        src={productLogo}
        loading="lazy"
        height="30"
        width="176"
        alt=""
      />
    </>
  );
};
