import { Img } from "../../common/basic components/img";
import withdrawalSection from "../../assest/withdrawalSection.svg";
import confirmSection from "../../assest/confirm.svg";
import headingStyles from "../../styleModules/commonHeading.module.scss";
import { Button } from "../../common/basic components/button";
import { useLocation, useNavigate } from "react-router-dom";
import Strings from "../../theme/string";
import { AccountTypes } from "../../enums/customerService.enums";
import { useMemo } from "react";
import { formatBalance } from "../../common/utils/formatBalance";

const ConfirmTransfer = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { type, from, amount, to } = state || {};

  const imageSrc = useMemo(() => {
    return type === AccountTypes.NOTICE_ACCOUNT
      ? withdrawalSection
      : confirmSection;
  }, [type]);

  return (
    <section className="d-flex justify-content-around">
      <div className="terms-conditions-choosePlan consent-choosePlan p-5 customWidth800">
        <div className="text-center">
          <div>
            <Img
              src={imageSrc}
              alt="withdrawal section icon"
              aria-label="Withdrawal Section Icon"
              loading="lazy"
            />
          </div>
          <div className="row gap-3">
            <div className={`${headingStyles.headingMedium} mt-4`}>
              {Strings.confirmTransferHeading}
            </div>
            <div className={headingStyles.subHeading}>
              {`We’ve transferred ${formatBalance(amount)} from your ${
                from.accountName
              } to your ${to.accountName}.`}
            </div>
            <div>
              <Button
                label="Done"
                className="btn btn-md btn-primary_one"
                onClick={() => navigate(-2)}
                aria-label="Confirm transfer and return to details page"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ConfirmTransfer;
