import { StepperPersonalInformation } from "../personalInformation/stepperPersonalInformation";
import { StepperCitizenShipNdTaxInfromation } from "../choosePlan/stepperCitizenShipNdTaxInfromation";
import StepperEmploymentDetails from "../employmentDetails/stepperEmploymentDetails";
import Strings from "../../theme/string";
import styles from "../../styleModules/commonHeading.module.scss";
import micsStyles from "../../styleModules/commonMicsStyles.module.scss";
import {
  PersonalDetailsStepConfig,
  PersonalDetailsStepperStepProps,
} from "../../interfaces/generalStepper.interface";

const stepConfig: PersonalDetailsStepConfig[] = [
  {
    title: Strings.personalInformationText,
    content: <StepperPersonalInformation />,
    ariaLabel: "Personal Information Form",
  },
  {
    title: Strings.citizenshipText,
    content: <StepperCitizenShipNdTaxInfromation />,
    ariaLabel: "Citizenship and Tax Information Form",
  },
  {
    title: Strings.employementInfoText,
    content: <StepperEmploymentDetails />,
    ariaLabel: "Employment Information Form",
  },
];

const StepperStep = ({ step }: PersonalDetailsStepperStepProps) => (
  <div>
    <div className="row mb-3">
      <div className="col-md-8 col-lg-6">
        <div className={`${styles.commonBoldHighlightedHeading} mb-2`}>
          {step.title}
        </div>
        <div className={micsStyles.customLine}></div>
      </div>
    </div>
    <div aria-label={step.ariaLabel}>{step.content}</div>
  </div>
);

const StepperPersonalDetails = () => (
  <div>
    <div className="row mb-3">
      <div className="col">
        <div tabIndex={0} aria-label={Strings.stepperPersonalInformationTitle}>
          {Strings.stepperPersonalInformationTitle}
        </div>
      </div>
    </div>
    <div className="d-flex flex-column gap-3">
      {stepConfig?.map((step, index) => (
        <StepperStep key={index} step={step} />
      ))}
    </div>
  </div>
);
export default StepperPersonalDetails;
