import { createSlice } from "@reduxjs/toolkit";
import { getMiddlePageBottomDetailsAction } from "../actions/middlePageBottomAction";

const initialState = {
  middlePageObj: []
};

export const middlePageBottomSlice = createSlice({
  name: "middlepageBottom",
  initialState,
  reducers: {
    clearStates: () => initialState,
  }, 
  extraReducers: (builder) => {
    builder.addCase(getMiddlePageBottomDetailsAction.fulfilled, (state : any, { payload }) => {
      // TODO always keep in mind to take care wrong payload or empty payload        
      state.middlePageObj = payload
    });
  }
});

export const { clearStates } =
middlePageBottomSlice.actions;

export default middlePageBottomSlice.reducer;