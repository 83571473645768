import { createSlice } from "@reduxjs/toolkit";
import { getStepperSummaryBoxPrivacyPolicyAction } from "./../actions/stepperSummaryBoxPrivacyPolicyAction"

const initialState = {
  stepperStepTwo : []
};

export const stepperSummaryBoxPrivacyPolicySlice = createSlice({
  name: "StepperSummaryBoxPrivacyPolicy",
  initialState,
  reducers: {
    clearStates: () => initialState,
  }, 
  extraReducers: (builder) => {
    builder.addCase(getStepperSummaryBoxPrivacyPolicyAction.fulfilled, (state : any, { payload }) => {
      // TODO always keep in mind to take care wrong payload or empty payload        
      state.stepperStepTwo = payload
    });
  }
});

export const { clearStates } =
stepperSummaryBoxPrivacyPolicySlice.actions;

export default stepperSummaryBoxPrivacyPolicySlice.reducer;