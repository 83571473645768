import { createSlice } from "@reduxjs/toolkit";
import { getNavigationBarAction } from "../actions/navigationBarAction";

const initialState = {
  nav: [],
  defaultFlipFlopText: "Login",
  loggedInUserName:""
};

export const navigationBarSlice = createSlice({
  name: "navigationBar",
  initialState,
  reducers: {
    clearStates: () => initialState,
    setUserName:(state,action)=>{
      state.loggedInUserName=action.payload;
    },
    clearUserName:(state)=>{
      state.loggedInUserName=""
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getNavigationBarAction.fulfilled, (state : any, { payload }) => {
      // TODO always keep in mind to take care wrong payload or empty payload        
      state.nav = payload
    });
  }
});

export const { clearStates,setUserName,clearUserName } =
  navigationBarSlice.actions;

export default navigationBarSlice.reducer;

