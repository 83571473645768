import { createAsyncThunk } from "@reduxjs/toolkit";
import { getStepperSummaryBoxPrivacyPolicyDetails } from "../../services/appApi";

export const getStepperSummaryBoxPrivacyPolicyAction = createAsyncThunk(
  "stepperStepTwo",
  async() => {
    try {
      const res = await getStepperSummaryBoxPrivacyPolicyDetails();
      return res;
    } catch (error) {
      console.error(error);
    }
  }
);
