export const combineSortCodeAndAccount = (
  sortCode: string | undefined | null,
  accountNumber: string | undefined | null
) => {
  if (!sortCode || !accountNumber) {
    console.error(
      "Invalid sortCode or accountNumber passed:",
      sortCode,
      accountNumber
    );
    return null;
  }
  const combined = sortCode.replace(/-/g, "") + accountNumber;
  return combined;
};
