export const footerQuery = JSON.stringify({
    query: `{
    footerDetails(id: "2Sl8gJDMJtgkcjJDJKQuHN") {
      overallDescription
      socialLinksCollection{
        items {
          name
          icon {
            title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
        }
      }
      appLinksCollection {
        items {
          name
          icon {
            title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
        }
      }
      sectionsCollection {
        skip
        limit
        items {
          title
          linksCollection {
            items {
              name
              url
              ariaLabel
            }
          }
        }
      }
    }
  }`,
    variables: {}
  });
