import { createSlice } from "@reduxjs/toolkit";
import { dashboardDiscoverMoreAction } from "../actions/dashboardDiscoverMoreAction";

const initialState = {
  discoverMoreObj: [],
};

export const dashboardDiscoverMoreSlice = createSlice({
  name: "discoverMore",
  initialState,
  reducers: {
    clearStates: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      dashboardDiscoverMoreAction.fulfilled,
      (state: any, { payload }) => {
        // TODO always keep in mind to take care wrong payload or empty payload
        state.discoverMoreObj = payload;
      }
    );
  },
});

export const { clearStates } = dashboardDiscoverMoreSlice.actions;

export default dashboardDiscoverMoreSlice.reducer;
