import React from 'react'
import Strings from '../../../theme/string'

export const MainStepperPageTitle = (props: any) => {
  return (
    <div className=''>
      {Strings.choosePlanSummaryHeader}
    </div>
  )
}
