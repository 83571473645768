import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMiddlePageTopDetails } from "../../services/appApi";

export const getMiddlePageTopDetailsAction = createAsyncThunk(
  "middlepageTop",
  async() => {
    try {
      const res = await getMiddlePageTopDetails();
      return res;
    } catch (error) {
      console.error(error);
    }
  }
);