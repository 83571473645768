import React from "react";
import Modal from "react-bootstrap/Modal";
import Strings from "../../../theme/string";
import { Button } from "../../../common/basic components/button";
import { ProfileLinkedAccountProceedingConfirmationProps } from "../../../interfaces/profile.interface";

export const ProfileLinkedAccountProceedingConfirmation = ({
  show,
  onClick,
  proceedAheadHandler,
}: ProfileLinkedAccountProceedingConfirmationProps) => (
  <Modal
    show={show}
    dialogClassName="my-modal customHowWeUseDataModal"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    onHide={onClick}
  >
    <Modal.Header closeButton>
      <Modal.Title className="visually-hidden">
        {Strings.profileLinkedAccConfirmationStr}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="text-center font-weight-light">
      {[
        Strings.profileLinkedAccInfoOne,
        Strings.profileLinkedAccInfoTwo,
        Strings.profileLinkedAccInfoThree,
      ].map((text, index) => (
        <div key={index} className="mb-3">
          {text}
        </div>
      ))}
    </Modal.Body>
    <Modal.Footer className="justify-content-center">
      <Button
        label="Continue"
        className="btn btn-primary_one width-100-percent"
        onClick={proceedAheadHandler}
      />
    </Modal.Footer>
  </Modal>
);
