import styles from "../../styleModules/memorableWord.module.scss";
import Strings from "../../theme/string";
import { updateMarketingPreferences } from "../../services/updateMarketingPreferences";
import {
  toggleSmsOptIn,
  toggleEmailOptIn,
  togglePostOptIn,
} from "../../redux/reducer/marketingPrefencesSlice";
import { useAppSelector, useAppDispatch } from "../../redux/redux-hooks";
import {
  MarketingPreferencesPropsInterface,
  MarketingPreferencesInterface,
} from "../../interfaces/marketingPreferences.interface";

const MarketingPreferences = ({
  labels,
  apiOnToggle,
}: MarketingPreferencesPropsInterface) => {
  const getMarketingPreferences: MarketingPreferencesInterface = useAppSelector(
    (state: any) => state.marketingPrefences
  );

  const dispatch = useAppDispatch();

  const handleToggle = (
    preferencesType: keyof typeof getMarketingPreferences
  ) => {
    const updatedPreferences = {
      ...getMarketingPreferences,
      [preferencesType]: !getMarketingPreferences[preferencesType],
    };

    if (preferencesType === "smsOptIn") {
      dispatch(toggleSmsOptIn());
    } else if (preferencesType === "emailOptIn") {
      dispatch(toggleEmailOptIn());
    } else if (preferencesType === "postOptIn") {
      dispatch(togglePostOptIn());
    }

    if (apiOnToggle) {
      dispatch<any>(updateMarketingPreferences({ updatedPreferences }));
    }
  };

  return (
    <section className="d-flex flex-column mt-2">
      <p className={`mb-0 fs-18 ${styles.marketingPrefLabel}`}>
        {Strings.marketingPreferencesHeading}
      </p>
      <hr className="my-2" />
      <div
        className={`form-check form-switch d-flex justify-content-between align-items-center ${styles.preferenceContainer}`}
      >
        <label className={styles.label}>
          {labels ? labels : Strings.message}
        </label>
        <input
          id="messages"
          name="smsOptIn"
          placeholder="messages"
          className={`form-check-input ${styles.customSwitch}`}
          type="checkbox"
          checked={getMarketingPreferences.smsOptIn}
          role="switch"
          onChange={() => handleToggle("smsOptIn")}
        />
      </div>
      <div
        className={`form-check form-switch d-flex justify-content-between align-items-center ${styles.preferenceContainer}`}
      >
        <label className={styles.label}>{Strings.email}</label>
        <input
          id="email"
          name="emailOptIn"
          placeholder="email"
          className={`form-check-input ${styles.customSwitch}`}
          type="checkbox"
          checked={getMarketingPreferences.emailOptIn}
          role="switch"
          onChange={() => handleToggle("emailOptIn")}
        />
      </div>
      <div
        className={`form-check form-switch d-flex justify-content-between align-items-center ${styles.preferenceContainer}`}
      >
        <label className={styles.label}>{Strings.post}</label>
        <input
          id="post"
          name="postOptIn"
          placeholder="post"
          className={`form-check-input ${styles.customSwitch}`}
          type="checkbox"
          checked={getMarketingPreferences.postOptIn}
          role="switch"
          onChange={() => handleToggle("postOptIn")}
        />
      </div>
    </section>
  );
};

export default MarketingPreferences;
