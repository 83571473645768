import React, { useCallback, useEffect, useState } from "react";
import { Img } from "../../common/basic components/img";
import styles from "../../styleModules/dashboard.module.scss";
import ContentCopy from "../../assest/content_copy.svg";
import { Accordion } from "react-bootstrap";
import { FETCH_FINANCIAL_DATA, MONUMENT_BASE_URL } from "../../services/url";
import { Button } from "../../common/basic components/button";
import Strings from "../../theme/string";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../redux/redux-hooks";
import { generateSHA256 } from "../../common/utils/sha256Generator";
import { fetchFinancialData } from "../../services/fetchFinancialAccountData";
import { extractSortCodeAndAccount } from "../../common/utils/extractSortCodeAndAcc";
import { copyToClipboard } from "../../common/utils/copyToClipboard";
import Breadcrumbs from "../../common/basic components/Breadcrumbs";

const ADD_MONEY_FAQ_DEMO_DATA = [
  //This is DEMO purpose only. This data will be coming from CMS
  {
    faqQuestion:
      "My bank does not recognise the sort code and account number you have provided. Why is this?",
    faqAnswer:
      "Monument isn’t part of the Confirmation of Payee Scheme, so you might get a warning when you open your account and try to deposit money. Don’t worry, as long as you enter the correct details your money will still reach us.",
    faqId: 1,
  },
  {
    faqQuestion: "How much money can I transfer in one transaction?",
    faqAnswer: "Sample Dummy Answer, To be fetched from CMS later.",
    faqId: 2,
  },
];

const AddMoney = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { accountNumber } = useParams<{ accountNumber: string }>();

  const [linkedAccountData, setLinkedAccountData] = useState<{
    linkedAccount?: string;
    partyName?: string;
    accountNumber?: string;
  } | null>(null);

  const { data: account } = useAppSelector(
    (state: any) => state.accountDetails
  );

  useEffect(() => {
    //Fetch financial account details to render linked account data
    fetchLinkedAccountData().then(setLinkedAccountData);
  }, []);

  const fetchLinkedAccountData = useCallback(async () => {
    try {
      const token = localStorage.getItem("tokenVal") ?? "";
      const hashAcc = await generateSHA256(accountNumber || "");

      let financialData = await fetchFinancialData(
        FETCH_FINANCIAL_DATA + `?account-number-hash=${hashAcc}`,
        token
      );
      return financialData?.data[0];
    } catch (error) {
      console.error(error);
    }
  }, []);

  function getCurrentAccountDetails(accountNumberOrSortCode: string) {
    if (
      accountNumberOrSortCode === Strings.addMoneyConditionTextAccountNumber
    ) {
      return extractSortCodeAndAccount(linkedAccountData?.accountNumber || "")
        .accountNumber;
    } else if (
      accountNumberOrSortCode === Strings.addMoneyConditionTextSortCode
    ) {
      return extractSortCodeAndAccount(linkedAccountData?.accountNumber || "")
        .sortCode;
    }
    return "-";
  }

  return (
    <div className="mt-4">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Breadcrumbs
              data={account}
              previousPath={`/moveMoney/${account?.accountNumber}`}
            />
          </div>

          <div className="row mt-2">
            {/* Add money Component */}
            <div className="col-8">
              <div className="col-12 headingAddMoney">
                <h2 className="fw-bold text-start">
                  {Strings.addMoneyHeading}
                </h2>
              </div>
              <div className="row mt-3">
                <div className="col">{Strings.addMoneyDesc}</div>
                <div className="mt-2">
                  {Strings.addMoneyCopyHeadingCurrentBank}
                </div>

                {/* Copy texts Section */}
                <div className="mt-2">
                  <div className="copySection addMoneySection">
                    <div className="row">
                      <div className="col-6 contentAreaCopy">
                        <div>
                          <span>{Strings.addMoneyPayeeTitle}</span>
                        </div>
                      </div>
                      <div className="col-6 d-flex flex-row justify-content-end align-items-center iconAreaCopy">
                        <div className={styles.addMoneyCopyTextColor}>
                          <span>{linkedAccountData?.partyName}</span>
                        </div>
                        <Img
                          src={ContentCopy}
                          onClick={() =>
                            copyToClipboard(linkedAccountData?.partyName)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-2">
                  <div className="copySection addMoneySection">
                    <div className="row">
                      <div className="col-6 contentAreaCopy">
                        <div>
                          <span>{Strings.addMoneySortCodeTitle}</span>
                        </div>
                      </div>
                      <div className="col-6 d-flex flex-row justify-content-end align-items-center iconAreaCopy">
                        <div className={styles.addMoneyCopyTextColor}>
                          <span>
                            {getCurrentAccountDetails(
                              Strings.addMoneyConditionTextSortCode
                            )?.toString()}
                          </span>
                        </div>
                        <Img
                          src={ContentCopy}
                          onClick={() =>
                            copyToClipboard(
                              getCurrentAccountDetails(
                                Strings.addMoneyConditionTextSortCode
                              )
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-2">
                  <div className="copySection addMoneySection">
                    <div className="row">
                      <div className="col-6 contentAreaCopy">
                        <div>
                          <span>{Strings.addMoneyAccountTitle}</span>
                        </div>
                      </div>
                      <div className="col-6 d-flex flex-row justify-content-end align-items-center iconAreaCopy">
                        <div className={styles.addMoneyCopyTextColor}>
                          <span>
                            {getCurrentAccountDetails(
                              Strings.addMoneyConditionTextAccountNumber
                            )}
                          </span>
                        </div>
                        <Img
                          src={ContentCopy}
                          onClick={() =>
                            copyToClipboard(
                              getCurrentAccountDetails(
                                Strings.addMoneyConditionTextAccountNumber
                              )
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* FAQ Section */}
                <div className="accordianSectionAddMoney">
                  <div className="mt-2 font-size-18 my-2 fw-bold">{`FAQs`}</div>
                  <Accordion flush className="border-top border-bottom">
                    {ADD_MONEY_FAQ_DEMO_DATA?.map((faqItem: any) => {
                      return (
                        <Accordion.Item
                          eventKey={faqItem?.faqId}
                          key={faqItem?.faqId}
                        >
                          <Accordion.Header>
                            {faqItem?.faqQuestion}
                          </Accordion.Header>
                          <Accordion.Body className="new-line">
                            {faqItem?.faqAnswer}
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                  <div className="helpSectionAddMoney mt-3">
                    <div>
                      {Strings.addMoneyHelpText}
                      <a
                        href={MONUMENT_BASE_URL}
                        target="_blank"
                        className="fw-bold text-decoration-underline"
                      >
                        {Strings.addMoneyHelpHyperlinkText}
                      </a>
                    </div>
                  </div>
                </div>

                {/* Cancel Button Section */}
                <div className="cancelBtnSectionAddMoney text-end mt-3">
                  <Button
                    label={Strings.btnCancel}
                    className="btn btn-secondary btn-md"
                    onClick={() => navigate(-1)}
                    aria-label={Strings.cancelButtonLabelText}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMoney;
