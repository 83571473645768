import { useEffect, useState } from "react";
import { getWithdrawalAccountListAction } from "../../redux/actions/withdrawalAccountAction";
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks";

import { CancelWidge } from "./cancelWidge";

function WithdrawalWidget(props: any) {
  const { hashedAccount } = props;
  const dispatch = useAppDispatch();

  const withdrawalAccount: any = useAppSelector(
    (state: any) => state.withdrawalAccount
  );

  useEffect(() => {
    dispatch<any>(getWithdrawalAccountListAction(hashedAccount));
  }, []);

  const mainObj =
    withdrawalAccount?.withdrawalAccount?.data?.withdrawalNoticeDetails
      ?.withdrawalNoticeList;

  return mainObj?.map((item: any) => (
    <div
      key={"account.accountNumber"}
      className={`border col-12 col-md-6 col-lg-3 width-100-percent`}
    >
      {item?.withdrawalDate && <CancelWidge {...{item}}/>}
    </div>
  ));
}

export default WithdrawalWidget;
