export const hasSufficientBalance = (balance: number) => balance > 0;

export const formatDateWithoutTime = (dateString: string = "") => {
  let date: Date;

  if (/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
    const [day, month, year] = dateString.split("-").map(Number);

    date = new Date(year, month - 1, day);
  } else {
    date = new Date(dateString);
  }

  const day = date.getDate();
  const month = date.toLocaleString("en-GB", { month: "long" });
  const year = date.getFullYear();

  const suffix = getDaySuffix(day);
  return `${day}${suffix} ${month} ${year}`;
};

function getDaySuffix(day: number): string {
  if (day >= 11 && day <= 13) {
    return "th";
  } else {
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }
}
