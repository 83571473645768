import axios, { AxiosError } from "axios";
import { withdrawMoneyNoticeAccPostInterface } from "../interfaces/withdrawalSection.interface";
import {
  transferFromNtToFtRequestInterface,
  withdrawMoneyEasyAccessPostInterface,
} from "../interfaces/moveMoney.interface";

export const fetchFinancialData = async (url: string, token: string) => {
  try {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    const typedError = error as AxiosError;
    if (axios.isAxiosError(typedError)) {
      if (typedError?.response) {
        return typedError?.message || "An error occurred";
      } else if (typedError?.request) {
        return "No response received from server";
      }
    }
    return typedError?.message;
  }
};

export const withdrawalNoticeAccount = async (
  url: string,
  postData: withdrawMoneyNoticeAccPostInterface,
  token: string
) => {
  try {
    const response = await axios.post(url, postData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    const typedError = error as AxiosError;
    if (axios.isAxiosError(typedError)) {
      if (typedError?.response) {
        return typedError?.message || "An error occurred";
      } else if (typedError?.request) {
        return "No response received from server";
      }
    }
    return typedError?.message;
  }
};

export const withdrawalEasyAccessAccount = async (
  url: string,
  postData: withdrawMoneyEasyAccessPostInterface,
  token: string
) => {
  try {
    const response = await axios.post(url, postData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    const typedError = error as AxiosError;
    if (axios.isAxiosError(typedError)) {
      if (typedError?.response) {
        return typedError?.message || "An error occurred";
      } else if (typedError?.request) {
        return "No response received from server";
      }
    }
    return typedError?.message;
  }
};

export const transferFromNtToOthers = async (
  url: string,
  postData: transferFromNtToFtRequestInterface,
  token: string
) => {
  try {
    const response = await axios.post(url, postData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    const typedError = error as AxiosError;
    if (axios.isAxiosError(typedError)) {
      if (typedError?.response) {
        return typedError?.message || "An error occurred";
      } else if (typedError?.request) {
        return "No response received from server";
      }
    }
    return typedError?.message;
  }
};
