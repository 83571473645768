import React, { useEffect, useState } from "react";
import Strings from "../../theme/string";
import { Input } from "../basic components/input";
import { INITIAL_COUNT } from "../constant/customConstant";

const OTPInput = (props: any) => {
  const { mobileNumber, onClick } = props;
  const [seconds, setSeconds] = useState(0);
  const [otp, setOtp] = useState(Array(Strings.six).fill(""));
  const [isValidOTP, setIsValidOTP] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;

    // Only allow single digit input
    if (value.match(/^\d$/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if(index == Strings.five)
        setIsValidOTP(!isValidOTP);
    }
  };

  const startTimer = () => {
    let counter = 0;
    let x = setInterval(() => {
      counter++;
      if (counter > 20) clearInterval(x);
      setSeconds((seconds) => seconds + 1);
    }, 1000);
  };

  useEffect(() => {
    let counter = 0;
    let x = setInterval(() => {
      counter++;
      if (counter > 20) clearInterval(x);
      setSeconds((seconds) => seconds + 1);
    }, 1000);
    return () => {
      clearInterval(x);
    };
  }, []);

  const reStartTimer = () => {
    setSeconds(0);
    startTimer();
  };

  const displayMsg = () => {
    if (otp[Strings.five]) {
      if (isValidOTP) {
        return Strings.verificationCodeInvalid;
      } else {
        return Strings.verificationCodeValid;
      }
    }
    if (seconds <= Strings.twenty)
      return Strings.verificationCodeExpireTwentySec;
    else if (seconds > Strings.twenty)
      return Strings.verificationCodeExpireResend;
  };

  return (
    <div className="margin-left-one">
      {
        <div>
          <div>
            {Strings.pleaseEnterOTP} {mobileNumber} .
          </div>
          <div className={"mt-3"}>
            {otp.map((_, index) => (
              <Input
                key={index}
                type="text"
                maxLength={Strings.one}
                value={otp[index]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e, index)
                }
                className={"otp-input-box"}
              />
            ))}
          </div>
          <div className="mt-3">
            <div className="fst-italic font-size-12px text-danger fw-bold">
              {displayMsg()}
            </div>
          </div>
          <span className="cursor-pointer">
            <u onClick={onClick}>{Strings.changeMobileNumber}</u>
          </span>
          <br />
          <span className="cursor-pointer">
            <u onClick={reStartTimer}>{Strings.resendCode}</u>
          </span>
        </div>
      }
    </div>
  );
};

export default OTPInput;
