import { useEffect, useState } from "react";
import { getVerifyCustomerData } from "../../services/appApi";
import { useNavigate } from "react-router-dom";
import { Img } from "../../common/basic components/img";
import Checklist from "../../assest/Checklist.svg";
import VerificationSuccess from "../../assest/verificationSuccess.svg";
import VerificationWaiting from "../../assest/verificationWaiting.svg";
import { ApplicationSubmissionContent } from "../../interfaces/applicationSubmission.interface";
import { SAVING_APPLICATION_API } from "../../services/url";
import { savingApplicationApi } from "../../services/saveApplicationData";
import { SaveApplicationPostData } from "../../interfaces/verifyCustomerDetails.interface";
import Strings from "../../theme/string";
import { useAppSelector } from "../../redux/redux-hooks";

const VerifyCustomerDetails = () => {
  const navigate = useNavigate();
  const [verifyDataState, setVerifyDataState] =
    useState<ApplicationSubmissionContent>();
  const [verifying, setVerifying] = useState(true);
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const { stepperDetails }: any = useAppSelector((state: any) => ({
    stepperDetails: state.stepperDetails,
    countryDetails: state.countryList,
  }));

  useEffect(() => {
    apiHandler(true);
  }, []);

  const apiHandler = async (statusParam: boolean) => {
    try {
      let apiData = await getVerifyCustomerData();
      if (apiData && statusParam) {
        setVerifyDataState(apiData);

        let postData: SaveApplicationPostData = {
          applicationDetails: {
            interestPaymentAccount:
              stepperDetails?.stepperLinkUKBank?.sortCode?.value.replace(
                /-/g,
                ""
              ) + stepperDetails?.stepperLinkUKBank?.accountNumber?.value,
            applicationType: stepperDetails?.stepperChoosedPlan?.productType,
            declarationsList: [
              {
                acceptanceDateTime:
                  stepperDetails?.stepperCheckList?.reviewConfirmation?.date,
                referenceTnC: "some ID",
                isAccepted:
                  stepperDetails?.stepperCheckList?.reviewConfirmation.value,
                channel: Strings?.channelWeb,
                consentType: "AllInfoIsCorrect",
              },
              {
                acceptanceDateTime:
                  stepperDetails?.stepperCheckList?.termsAndConditionFlag?.date,
                referenceTnC: "some ID",
                isAccepted:
                  stepperDetails?.stepperCheckList?.termsAndConditionFlag.value,
                channel: Strings?.channelWeb,
                consentType: "TermsAndConditions",
              },
            ],
            linkedAccount:
              stepperDetails?.stepperLinkUKBank?.sortCode?.value.replace(
                /-/g,
                ""
              ) + stepperDetails?.stepperLinkUKBank?.accountNumber?.value,
            payInterestTo: Strings.interestRadioLinkedAccount,
            committedInitialDepositAmount: Number(
              stepperDetails?.stepperLinkUKBank?.depositAmount?.value
            ),
            cbsProductKey: "8a6a861f92b825ca0192bea9be6b28e5",
            // cbsProductKey: stepperDetails?.stepperChoosedPlan?.productKey,
            channelApplicationStarted: Strings?.channelWeb,
            applicationSubmitted: true,
          },
        };
        let token = await localStorage.getItem("tokenVal") ?? "";
        let apiResult = await savingApplicationApi(
          SAVING_APPLICATION_API,
          token,
          postData
        );
        if (apiResult && typeof apiResult === "object") {
          setVerificationSuccess(true);
          setVerifying(false);
        } else {
          setVerifyDataState(apiData);
          setVerificationSuccess(false);
          setVerifying(false);
        }
      }
    } catch (error) {
      console.error(Strings.apiErrorText, error);
    }
  };

  const navigationHandler = async () => {
    navigate("/memorableWord");
  };

  const getContentData = () => {
    if (verifying && !verificationSuccess) {
      return verifyDataState?.verifyData;
    } else if (!verifying && verificationSuccess) {
      return verifyDataState?.verificationSuccessData;
    } else if (!verifying && !verificationSuccess) {
      return verifyDataState?.verificationFailureData;
    }
  };

  return (
    <section className="container-xxl mt-5 customWidth921">
      <div className="terms-conditions-choosePlan consent-choosePlan p-5">
        <div className="text-center">
          <div>
            {verifying && !verificationSuccess && (
              <Img
                src={Checklist}
                alt="Check List Icon"
                aria-label="Check List Icon"
              />
            )}
            {!verifying && verificationSuccess && (
              <Img
                src={VerificationSuccess}
                alt="Verification Success Icon"
                aria-label="Verification Success Icon"
              />
            )}
            {!verifying && !verificationSuccess && (
              <Img
                src={VerificationWaiting}
                alt="Verification Pending Icon"
                aria-label="Verification Pending Icon"
              />
            )}
          </div>
          <div className="row font-size-36px">
            <div className="col fw-bold">{getContentData()?.title}</div>
          </div>
          <div className="row font-size-24px">
            <div className="col">{getContentData()?.description}</div>
          </div>
          {!verifying && verificationSuccess && (
            <div className="mt-3">
              <button
                className={`btn-primary_one btn`}
                onClick={(e: any) => navigationHandler()}
                value={Strings.next}
              >
                {Strings.next}
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default VerifyCustomerDetails;
