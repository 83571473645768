import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { clearStates, updateCitizenAndTax, updatedLinkUKBank, updateDraftValueAsDefault, updateUserDetails, updateValidationIsRequired } from "../../../redux/reducer/stepperSubProductSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/redux-hooks";
import Strings from "../../../theme/string";
import { Button } from "../../basic components/button";
import {
  INITIAL_COUNT, STEPPER_USER_DETAILS_FIELD, VERIFY_EMPLOYEE_OTHER_JOB
} from "../../constant/customConstant";
import { Modal } from "react-bootstrap";
import { findValueInArray } from "../../modules/findValueInArray";
import LoadingModal from "../LoadingModal";
import { CREATE_PARTY_API_TSAPI, TS_COG_TOKEN } from "../../../services/url";
import { Nationality, PartyDetails, TaxNumber, TokenResponse } from "../../../interfaces/footer.interface";
import { createPartyAPI, fetchTSToken } from "../../../services/saveApplicationData";
import { stepperFooterProps } from "../../../interfaces/generalStepper.interface";
import { StepperSteps } from "../../../enums/generalStepper.enums";

export const StepperFooter = (props: stepperFooterProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {stepperDetails,countryDetails}: any = useAppSelector((state: any) => ({stepperDetails:state.stepperDetails,countryDetails:state.countryList}));

  const { activeStep, setActiveStep } = props;
  const dataUserDetails = stepperDetails?.stepperUserDetails;
  const dataTaxDetails = stepperDetails?.citizenshipAndTaxSection;
  const dataLinkUKBank = stepperDetails?.stepperLinkUKBank;
  const dataEmployeeDetails = stepperDetails?.employmentInformationSection;
  const [isCancelModalEnabled, setIsCancelModalEnabled] = useState(false);
  
  const [loadModalFlag,setLoadModalFlag] = useState(false);
  const [submitButtonFlag,setSubmitButtonFlag] = useState(false);
  const [submitErrorMessage,setSubmitErrorMessage] = useState("");
  const validationIsRequired = (boolVal: boolean) => {
    dispatch<any>(updateValidationIsRequired(boolVal))
  }

  const checkUserDetails = () => {
    dispatch<any>(
      updateUserDetails({
        fieldName: "middleName",
        value:dataUserDetails?.middleName?.value??"",
        draftValue: dataUserDetails?.middleName?.draftValue,
        isValidate :true,
        isdraftValidate : true,
      })
    );
    let isTrue = STEPPER_USER_DETAILS_FIELD?.find(item => !dataUserDetails[item]?.isValidate)
    if (isTrue) {
      validationIsRequired(true)
      return false;
    }
    else {
      validationIsRequired(false);
      return true;
    }
  }

  const isTaxNumberField = (tmpVal: string) => {
    let isValidate = dataTaxDetails?.taxNumber?.find((item: any) => {
      return item.countryName == tmpVal && item.isValidate
    })
    if (isValidate) return true;
    else return false;
  }

  const checkCitizenAndTaxInformation = () => {
    const tmpCitizen = dataTaxDetails?.otherThanUKCitizen?.isValidate;
    const tmpTaxCheckBoxClicked = dataTaxDetails?.isTaxInputEnableForAnother?.value || dataTaxDetails?.isTaxInputEnableForUK?.value || dataTaxDetails?.isTaxInputEnableForUS?.value;
    const tmpTaxNumberIsUSGiven = !dataTaxDetails?.isTaxInputEnableForUS?.value ? true : isTaxNumberField(Strings.us);
    const tmpOtherCountryResident = !dataTaxDetails?.isTaxInputEnableForAnother?.value ? true : dataTaxDetails?.otherThanUKTaxNumber?.isValidate ? true : false;
    const tmpTaxDetailsField = !dataTaxDetails?.isTaxInputEnableForAnother?.value ? true : dataTaxDetails?.otherThanUKTaxNumber?.value.find((item: any) => isTaxNumberField(item.label))
    if (tmpCitizen && tmpTaxCheckBoxClicked && tmpTaxNumberIsUSGiven && tmpOtherCountryResident && tmpTaxDetailsField) {
      validationIsRequired(false)
      return true;
    }
    else {
      validationIsRequired(true)
      return false;
    }
  }

  const checkLinkUKBankAccount = () =>{
    const tmpAccountNumber = dataLinkUKBank?.accountNumber?.isValidate;
    const tmpDepositAmount = dataLinkUKBank?.depositAmount?.isValidate;
    const tmpSortCode = dataLinkUKBank?.sortCode?.isValidate;
    const tmpInterestBeingPaid = dataLinkUKBank?.interestBeingPaid?.isValidate;
    
    if(tmpAccountNumber && tmpDepositAmount && tmpSortCode && tmpInterestBeingPaid){
      validationIsRequired(false)
      return true;
    }
    validationIsRequired(true)
    return false;
  }

  const checkEmployDetails = () =>{
    const tmpIncomeRange = dataEmployeeDetails?.incomeRange?.isValidate;
    const tmpJobRole = dataEmployeeDetails?.jobRole?.isValidate;
    const tmpEmployeeStatus = dataEmployeeDetails?.employeeStatus?.isValidate;
    const tmpJobIndustry = dataEmployeeDetails?.jobIndustry?.isValidate;

    if(findValueInArray(VERIFY_EMPLOYEE_OTHER_JOB, dataEmployeeDetails?.employeeStatus?.value) && tmpIncomeRange && tmpJobRole && tmpJobIndustry)
    {
      validationIsRequired(false)
      return true;
    }
    else if(dataEmployeeDetails?.employeeStatus?.value && !findValueInArray(VERIFY_EMPLOYEE_OTHER_JOB, dataEmployeeDetails?.employeeStatus?.value))
      {
        validationIsRequired(false)
        return true;
      }
    else {
      validationIsRequired(true)
      return false;
    }
  }

  const checkAllStepper = () => {
    const proceedToNextStep = () => {
      setActiveStep(activeStep + 1);
      window.scrollTo(0, 0);
    };
    switch (activeStep) {
      case StepperSteps.CHOOSE_PLAN:
        const isPlanValid =
          stepperDetails?.choosePlanConsent === true &&
          Object.keys(stepperDetails?.stepperChoosedPlan).length !==
            INITIAL_COUNT;
        validationIsRequired(!isPlanValid);
        if (isPlanValid) {
          proceedToNextStep();
          return true;
        }
        return false;
      case StepperSteps.PERSONAL_DETAILS:
        if (
          checkUserDetails() &&
          checkCitizenAndTaxInformation() &&
          checkEmployDetails()
        ) {
          proceedToNextStep();
          return true;
        }
        return false;
      case StepperSteps.LINK_BANK_ACCOUNT:
        if (checkLinkUKBankAccount()) {
          proceedToNextStep();
          return true;
        }
        return false;
      case StepperSteps.REVIEW:
        if (
          stepperDetails?.stepperCheckList.reviewConfirmation.value === true
        ) {
          let arr = {};
          const mainObj = stepperDetails.stepperLinkUKBank;
          Object.keys(mainObj).forEach((item: string) => {
            const { fieldName, value, isValidate } = mainObj[item];
            arr = { ...arr, [item]: { fieldName, value, isValidate } };
          });
          dispatch(updateDraftValueAsDefault(arr));
          validationIsRequired(false);
          checkAllDraftDetails();
          proceedToNextStep();
          return true;
        } else {
          validationIsRequired(true);
          return false;
        }
      case StepperSteps.SUMMARY_BOX:
        proceedToNextStep();
        return true;
      case StepperSteps.FSCS_AND_TERMS:
        const isConsentValid =
          stepperDetails?.stepperCheckList?.fscsConsentFlag?.value === true &&
          stepperDetails?.stepperCheckList?.termsAndConditionFlag?.value ===
            true;
        validationIsRequired(!isConsentValid);
        if (isConsentValid) {
          window.scrollTo(0, 0);
          submitButtonHandler();
          return true;
        }
        return false;
      default:
        return false;
    }
  };

  const submitButtonHandler = async () => {
    setLoadModalFlag(true);
    try {
      let tsToken: TokenResponse | null = await fetchTSToken(TS_COG_TOKEN);
      if (tsToken) {
        try {
          let postData: { partyDetails: PartyDetails } = {
            partyDetails: {
              ukResidencyStatus:"",
              taxList:
                stepperDetails?.citizenshipAndTaxSection?.taxNumber?.map(
                  (item: TaxNumber) => {
                    return {
                      taxPayCountry:
                        countryDetails?.countryList?.data?.records?.countriesAndStates?.countries?.find(
                          (item: { label: string; countryName: string }) =>
                            item?.label == item?.countryName
                        )?.isoCode,
                      taxIdentification: item.value,
                      active: true,
                    };
                  }
                ) ?? [],
              status: "Active",
              smsOptIn: false,
              postOptIn: false,
              onlineBankingAccess: false,
              nationalityList:
                stepperDetails?.citizenshipAndTaxSection?.otherThanUKCitizen?.value?.map(
                  (item: Nationality) => {
                    return {
                      status: true,
                      nationalityCountryCode: item?.isoCode,
                      nationalId: item?.isoCode,
                    };
                  }
                ) ?? [],
              mobileWithoutCode:
                stepperDetails?.stepperUserDetails?.mobileWithoutCode?.value ?? "",
              mobileVerified: true,
              mobilePhone:
                `${Strings?.mobileCountryCode}${stepperDetails?.stepperUserDetails?.mobileWithoutCode?.value}` ??
                "",
              mobileCountryCode: Strings?.mobileCountryCode,
              mobileBankingAccess: false,
              middleName:
                stepperDetails?.stepperUserDetails?.middlename?.value ?? "",
              marketingPreferenceSubmitted: false,
              lastOnlineAccessDateTime: null,
              partyType: "Individual",
              lastName: stepperDetails?.stepperUserDetails?.lastName?.value,
              lastMobileAccessDateTime: null,
              isRegisteredForOnlineBanking: false,
              isRegisteredForMobileBanking: false,
              firstName: stepperDetails?.stepperUserDetails?.firstName?.value,
              emailOptIn: false,
              email: stepperDetails?.stepperUserDetails?.email?.value,
              declarationsList: [
                {
                  referenceTnC: "PrivacyPolicy",
                  isAccepted:
                    stepperDetails?.stepperCheckList?.privacyFlag?.value,
                  consentType: "PrivacyPolicy",
                  channel: "Web",
                  acceptanceDateTime:
                    (stepperDetails?.stepperCheckList?.privacyFlag?.date)??new Date().toISOString(),
                },
              ],
              countryOfResidence: Strings?.gbr,
              birthDate: stepperDetails?.stepperUserDetails?.dateOfBirth?.value
                ?.split("-")
                .reverse()
                .join("-"),
              addressList: [
                {
                  type: Strings?.address?.toUpperCase(),
                  state: "",
                  postalCode:
                    stepperDetails?.stepperUserDetails?.postcode?.value,
                  country: Strings?.gbr,
                  city: stepperDetails?.stepperUserDetails?.posttown?.value,
                  addressLine4:
                    stepperDetails?.stepperUserDetails?.addressline4?.value,
                  addressLine3:
                    stepperDetails?.stepperUserDetails?.addressline3?.value,
                  addressLine2:
                    stepperDetails?.stepperUserDetails?.addressline2?.value,
                  addressLine1:
                    stepperDetails?.stepperUserDetails?.addressline1?.value,
                },
              ],
            },
          };
          let createPartyData = await createPartyAPI(
            CREATE_PARTY_API_TSAPI,
            tsToken?.access_token,
            postData
          );
          if (createPartyData && typeof createPartyData === "object") {
            setSubmitButtonFlag(true);
          } else {
            setSubmitErrorMessage(Strings.apiErrorText + createPartyData);
            setLoadModalFlag(false);
          }
        } catch (error) {
          setSubmitErrorMessage(Strings.apiCallFailText + error);
          setLoadModalFlag(false);
        }
      } else {
        setSubmitErrorMessage(Strings.apiErrorText + tsToken);
        setLoadModalFlag(false);
      }
    } catch (error) {
      setSubmitErrorMessage(Strings.apiCallFailText + error);
      setLoadModalFlag(false);
    }
  };

  const submitApplicationApiHandler=()=>{
    navigate("/verifyCustomer");
  }

  const buttonName = () => {
    switch (activeStep) {
      case Strings.stepTwo:
        return Strings.startApplication;
      case Strings.stepOne:
      case Strings.stepThree:
      case Strings.stepFour:
      case Strings.stepFive:
        return Strings.btnContinue;
      case Strings.stepSix:
        return Strings.submitApplication;
    }
  }

  const cancelButtonModal = () => {
    //Cancel button to show modal when clicked and get user's confrimation to navigate back
    setIsCancelModalEnabled(true);
  }

  const closeApplicationAndNavigate = () => {
    //Navigate as user confirmed
    setIsCancelModalEnabled(false);
    dispatch<any>(clearStates()); 
    navigate("/savings");
  }

  const submitButtonJsx = (submitButtonFlag &&
    <Button
      label={"Save application form"}
      className={"btn btn-md btn-primary_one"}
      onClick={()=> submitApplicationApiHandler()}
    />
  );

  const checkAllDraftDetails = () =>{
    Object.keys(dataLinkUKBank).map(item=>{
      dispatch<any>(
        updatedLinkUKBank({
          fieldName: item,
          value : dataLinkUKBank?.[item].value,
          isValidate: true
        })
      )
    })
    
    Object.keys(dataEmployeeDetails).map(item=>{
      dispatch<any>(
        updatedLinkUKBank({
          fieldName: item,
          value : dataEmployeeDetails?.[item].value,
          isValidate: true
        })
      )
    })

    if(dataTaxDetails?.isTaxInputEnableForAnother?.draftValue){
      dispatch<any>(
        updateCitizenAndTax({
          obj: { value : dataTaxDetails?.isTaxInputEnableForAnother?.value },
          fieldName: "isTaxInputEnableForAnother",
        })
        );}
        
        if(dataTaxDetails?.isTaxInputEnableForUK?.draftValue){
          dispatch<any>(
      updateCitizenAndTax({
        obj: { value : dataTaxDetails?.isTaxInputEnableForUK?.value },
        fieldName: "isTaxInputEnableForUK",
      })
    );}
    
    if(dataTaxDetails?.isTaxInputEnableForUS?.draftValue){
    dispatch<any>(
      updateCitizenAndTax({
        obj: { value : dataTaxDetails?.isTaxInputEnableForUS?.value },
        fieldName: "isTaxInputEnableForUS",
      })
    );}

    if(dataTaxDetails?.otherThanUKCitizen?.draftValue){
    dispatch<any>(
      updateCitizenAndTax({
        obj: { value : dataTaxDetails?.otherThanUKCitizen?.value },
        fieldName: "otherThanUKCitizen",
      })
    );}

    if(dataTaxDetails?.therThanUKTaxNumber?.draftValue){
    dispatch<any>(
      updateCitizenAndTax({
        obj: { value : dataTaxDetails?.therThanUKTaxNumber?.value },
        fieldName: "therThanUKTaxNumber",
      })
    );}

  }

  const previousButtonStep = () =>{
    if(activeStep == Strings.four)
    {
      checkAllDraftDetails();
    } 
    setActiveStep(activeStep - Strings.one);
  }

  return (
    <>
    <LoadingModal show={loadModalFlag} size="sm" children={submitButtonJsx}/>
   
    <div className="formButtonBlocks">
      <Button
        label={Strings.btnCancel}
        className="btn btn-secondary btn-md"
        onClick={() => cancelButtonModal()}
      />
      <Modal
        show={isCancelModalEnabled}
        size={"xl"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="customHowWeUseDataModal onboardingCancelModal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">{Strings.cancelRequestHeading}</Modal.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => setIsCancelModalEnabled(false)}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="new-line">{Strings.cancelReQuestDescriptionConfirmation}</div>
          <div className="new-line mt-3">{Strings.cancelRequestDescription}</div>
        </Modal.Body>
        <Modal.Footer className="mb-3">
          <div>
            <Button
              label={Strings?.cancelBackText}
              className="btn btn-md btn-primary_one-outlined me-2"
              onClick={() => setIsCancelModalEnabled(false)}
            />
            <Button
              label={Strings?.cancelRequestButtonText}
              className={"btn btn-md btn-primary_one"}
              onClick={() => closeApplicationAndNavigate()}
            />
          </div>
        </Modal.Footer>
      </Modal>
      <div>
        {activeStep != INITIAL_COUNT && (
          <Button
            label={Strings.btnPrevios}
            className="btn btn-md btn-primary_one-outlined me-2"
            onClick={() => {previousButtonStep(); window.scrollTo(0,0)}}
          />
        )}
        <Button
          label={buttonName()}
          className={
            "btn btn-md btn-primary_one"
          }
          onClick={checkAllStepper}
        />
      </div>
    </div>
    {submitErrorMessage &&<div className="text-danger mt-4">{submitErrorMessage}</div>}
    </>
  );
};
