import styles from "../../styleModules/dashboard.module.scss";
import DashboardButtons from "./DashboardButtons";
import { extractSortCodeAndAccount } from "../../common/utils/extractSortCodeAndAcc";
import { formatDateWithoutTime } from "../../common/utils/dashboardButtonsConditions";
import Strings from "../../theme/string";
import useMaturityCheck from "../../hooks/useMaturityCheck";
import { AccountTypes } from "../../enums/customerService.enums";
import useFundingPeriodValidation from "../../hooks/useFundingPeriodValidation";

function AccountWidget({ data }: any) {
  const { sortCode, accountNumber: showAccountNum } = extractSortCodeAndAccount(
    data.accountNumber
  );

  const { isFundingPeriodValid } = useFundingPeriodValidation(data);
  const { isWithinMaturedDays } = useMaturityCheck(data);

  return (
    <div>
      {data.accountType === AccountTypes.FIXED_TERM_DEPOSIT &&
        !isFundingPeriodValid && (
          <div
            className={`border rounded col-12  py-2 px-2 mt-3 ${styles.accountAlert}`}
          >
            <p className="mb-0">
              {Strings.ftMatureNotification}
              {data.maturityDate ? (
                <span className={`${styles.accountAlertDate} ps-1`}>
                  {formatDateWithoutTime(data.maturityDate)}
                </span>
              ) : (
                <span className={`${styles.accountAlertDate} ps-1`}>
                  {Strings.na}
                </span>
              )}
            </p>
          </div>
        )}
      <div
        key={"account.accountNumber"}
        className={`border rounded col-12 col-md-6 col-lg-3 py-2 px-2 mt-3 width-100-percent`}
      >
        <div className={styles.darkGrayColor}>
          <span>{data.accountNumber && sortCode}</span>
          <span className="px-1">|</span>
          <span>{data.accountNumber && showAccountNum}</span>
        </div>
        <div className={styles.accountBalance}>£{data.balance}</div>
        <div className={styles.darkGrayColor}>
          <span>
            {data.interestRate}
            {Strings.grossText}
          </span>
          <span className="px-1">/</span>
          <span>
            {data.aer}
            {Strings.aerText}
          </span>
        </div>
        <hr />
        <div className="row">
          <div className="col-6">
            <div className={styles.darkGrayColor}>
              <span>
                {data.accountType === AccountTypes.FIXED_TERM_DEPOSIT
                  ? Strings.earnedToDate
                  : Strings.interestPaidToDate}
              </span>
            </div>
            <div className={styles.accountBalance}>
              £{data?.lifetimeInterestEarned}
            </div>
          </div>
          <div className="col-6 border-start">
            <div className={styles.darkGrayColor}>
              <span>
                {data.accountType === AccountTypes.FIXED_TERM_DEPOSIT
                  ? Strings.paidAtMaturity
                  : Strings.accruedThisMonth}
              </span>
            </div>
            <div className={styles.accountBalance}>
              £
              {data.accountType === AccountTypes.FIXED_TERM_DEPOSIT
                ? data.interestPaidAtMaturity
                : data.interestEarned}
            </div>
          </div>
          <div>
            {data.accountType === AccountTypes.FIXED_TERM_DEPOSIT &&
              isWithinMaturedDays && (
                <button
                  className={`btn btn-primary_one py-1 px-2 ${styles.btnTextSize}`}
                >
                  {Strings.chooseMaturityOptions}
                </button>
              )}
          </div>
        </div>
        <div className="mt-2">
          {data && <DashboardButtons account={data} />}
        </div>
        {data.accountType === AccountTypes.FIXED_TERM_DEPOSIT &&
          isFundingPeriodValid && (
            <div>
              <hr />
              <div className={styles.note}>
                <p>{Strings.noteHeading}</p>
                <ul>
                  <li>
                    {Strings.ftNoteOne}
                    <span className="px-1">
                      {formatDateWithoutTime(data.depositRequiredByDate)}
                    </span>
                    {Strings.ftNoteTwo}
                  </li>
                  <li>{Strings.ftNoteThree}</li>
                </ul>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

export default AccountWidget;
