import { NavLink } from "../../basic components/navLink";
import { DropDown } from "../../basic components/dropDown";
import { NavItem } from "../../../interfaces/headerSection.interface";

export const HeaderContent = (item: NavItem) => (
  <>
    {item?.child && item?.children ? (
      <DropDown {...item} />
    ) : (
      <NavLink {...item} />
    )}
  </>
);
