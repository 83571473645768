import { useMemo } from "react";
import GenericViewFormComponent from "./GenericViewFormComponent";
import { useAppSelector } from "../../../../redux/redux-hooks";
import Strings from "../../../../theme/string";
import { RootState } from "../../../../interfaces/reviewStepper.interface";
import commonHeadingStyles from "../../../../styleModules/commonHeading.module.scss";
import micsStyles from "../../../../styleModules/commonMicsStyles.module.scss";

const ProductDetailsViewComponent = () => {
  const { productName, interestRate, aerField } = useAppSelector(
    (state: RootState) => state.stepperDetails.stepperChoosedPlan
  );

  const productDetailsReadData = useMemo(
    () => [
      {
        title: `${Strings.productDetailsProductHeading}`,
        fieldData: `${productName}`,
      },
      {
        title: `${Strings.productDetailsInterestHeading}`,
        fieldData: `${interestRate} % ${Strings.reviewPageGrossText} ${
          aerField ? `% ${aerField}` : ``
        }`,
      },
    ],
    [productName, interestRate, aerField]
  );

  return (
    <>
      <div>
        <div className="d-flex align-items-center gap-3 mb-3">
          <div className="col-md-8 col-lg-6">
            <div
              className={`${commonHeadingStyles.commonBoldHighlightedHeading} mb-2`}
            >
              {Strings?.productDetailsTitle}
            </div>
            <div className={micsStyles.customLine}></div>
          </div>
        </div>
        <GenericViewFormComponent formData={productDetailsReadData} showRequiredMarker={false} />
      </div>
    </>
  );
};

export default ProductDetailsViewComponent;
