import React, { useEffect, useMemo } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { getNavigationBarAction } from "../../../redux/actions/navigationBarAction";
import { useAppDispatch, useAppSelector } from "../../../redux/redux-hooks";
import { Logo } from "../logo";
import { NavItem } from "../../../interfaces/headerSection.interface";
import { HeaderContent } from "./headerContent";
import Strings from "../../../theme/string";
import UseHeaderDisplayCustomHook from "./headerDisplayCustomHook";
import userProfilePic from "../../../assest/userProfileImage.svg";
import { Img } from "../../basic components/img";
import { useNavigate } from "react-router-dom";

export const NavigationBar = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch<any>(getNavigationBarAction());
  }, []);

  const mainNavigation = useAppSelector((state: any) => state.mainNavigation);
  const headerFlag = UseHeaderDisplayCustomHook();
  const navigate = useNavigate();
  const renderedNavigation = useMemo(
    () =>
      mainNavigation.nav.map((item: NavItem) => (
        <div key={item.heading} style={{ zIndex: "100" }}>
          {HeaderContent(item)}
        </div>
      )),
    [mainNavigation.nav]
  );

  const generateUserSection = useMemo(() => {
    const userSection = { ...mainNavigation?.nav[1] };
    userSection.heading =
      Strings.welcome + ", " + mainNavigation?.loggedInUserName;
    return userSection;
  }, [mainNavigation?.nav[1], mainNavigation?.loggedInUserName]);

  return (
    <Navbar collapseOnSelect expand="lg" aria-label="Main Navigation">
      <div className="container-xxl">
        <div className="logo">
          <Logo />
        </div>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        />
        {!headerFlag && (
          <>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="justify-content flex-grow-1">
                <Nav className="tag">
                  {renderedNavigation}
                  {mainNavigation.loggedInUserName ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ zIndex: "100" }}
                    >
                      <Img
                        src={userProfilePic}
                        loading="lazy"
                        height="30"
                        width="30"
                        alt="User Profile Picture"
                      />
                      {HeaderContent(generateUserSection)}
                    </div>
                  ) : (
                    <div className="login-button">
                      <button
                        type="button"
                        className="btn btn-light btn-md"
                        aria-label="Login"
                        onClick={() => navigate("/dashboard")} // FOR DEMO PURPOSE ONLY
                      >
                        {Strings?.loginText}
                      </button>
                    </div>
                  )}
                </Nav>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </div>
    </Navbar>
  );
};
