import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   isLodderRunning  : false,
   screenName       : ""
};

export const lodderSlice = createSlice({
  name: "lodder",
  initialState,
  reducers: {
    clearStates: () => initialState,
    setLodder: (state : any, action) => {
        state.isLodderRunning = action.payload;
    },
  },
});

export const {
    clearStates,
    setLodder
} = lodderSlice.actions;

export default lodderSlice.reducer;
