import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { HINDERED_URLS } from "../../constant/customConstant";

const UseHeaderDisplayCustomHook = () => {
  const currentRoute = useLocation();

  //ADD FUTURE URL'S WHERE WE DON'T WANNA SHOW NAVIGATION LINKS
  const hindredUrls = useMemo(() => new Set(HINDERED_URLS), []);

  return (
    hindredUrls?.size > 0 &&
    Array.from(hindredUrls)?.some((url: string) =>
      currentRoute?.pathname?.includes(url)
    )
  );
};

export default UseHeaderDisplayCustomHook;
