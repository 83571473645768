import axios, { AxiosError } from "axios";
import { AddressProfileBody } from "../interfaces/partyDetails.interface";

export const getPartyDetailsApiData = async (url: string, token: string) => {
  try {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    const typedError = error as AxiosError;
    if (axios.isAxiosError(typedError)) {
      if (typedError?.response) {
        return typedError?.message || "An error occurred";
      } else if (typedError?.request) {
        return "No response received from server";
      }
    }
    return typedError?.message;
  }
};


export const saveProfileAddress = async (url: string,body:AddressProfileBody, token: string) => {
  try {
    const response = await axios.patch(url,body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
    return response?.data;
  } catch (error:any) {
    return {error:true,...error?.response};
  }
};
