import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCountryListDetails, getIndustriesListDetails } from "../../services/appApi";

export const getCountryListAction = createAsyncThunk(
  "countryList",
  async() => {
    try {
      const res = await getCountryListDetails();
      return res;
    } catch (error) {
      console.error(error);
    }
  }
);

export const getIndustriesListAction = createAsyncThunk(
  "industriesList",
  async() => {
    try {
      const res = await getIndustriesListDetails();
      console.log("API CALL FOR INDUSTRIES::: ", res);
      return res;
    } catch (error) {
      console.error(error);
    }
  }
);
