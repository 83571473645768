import { FunctionComponent, useEffect, useState } from "react";
import { getFscsProtectionAndTermsData } from "../../services/appApi";
import { Checkbox } from "../../common/basic components/checkBox";
import { useParams } from "react-router-dom";
import { RootState } from "../../interfaces/reviewStepper.interface";
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks";
import { updateCheckList } from "../../redux/reducer/stepperSubProductSlice";
import Strings from "../../theme/string";
import Styles from "../../styleModules/commonHeading.module.scss"
import { Img } from "../../common/basic components/img";
import fscsIcon from "../../assest/fscsIcon.svg";
interface TermsData {
    fscsData: {
        fscsTitle: string;
        fscsSummary: string;
        fscsInformationSheet: string;
        fscsInformationSheetUrl: string;
        fscsConsentData: string;
    },
    termsData: {
        termsTitle: string;
        termsSummary: string;
        termsGeneralSavings: string;
        termsGeneralSavingsUrl: string;
        termsProductType: string;
        termsProductTypeUrl: string;
        termsSummaryBox: string;
        termsSummaryBoxUrl: string;
        termsConsentData: string;
    }
}

const StepperFscsAndTerms = () => {
    const { id } = useParams();
    const [termsData, setTermsData] = useState<TermsData>();
    const [fscsConsent, setFscsConsent] = useState<boolean>(false);
    const [termsAndConditionsConsent, setTermsAndConditionsConsent] = useState<boolean>(false);
    const { buttonValidatonIsRequired, stepperCheckList } = useAppSelector(
        (state: RootState) => state.stepperDetails
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        apiHandler();
    }, []);

    const apiHandler = async () => {
        let apiData = getFscsProtectionAndTermsData();
        if (apiData) {
            setTermsData(apiData);
        } else {
            console.error("Error fetching fscs abd terms data!");
        }
    };

    return (
        <>
            <div className="fscsAndTermsSec1">
                <div className="row font-size-18px">
                    <div className="col fw-bold">{termsData?.fscsData?.fscsTitle}</div>
                </div>
                <div className="row">
                    <div className="col">
                        <p className="new-line lh-base">{termsData?.fscsData?.fscsSummary}</p>
                    </div>
                </div>
                <div className="fscs-logo-input-group row">
                    <div className="input-group-left col-md-8 col-lg-6">
                    <LinkRenderer classRequired={false} textParam={termsData?.fscsData?.fscsInformationSheet} linkParam={termsData?.fscsData?.fscsInformationSheetUrl} />

                    <div className="row">
                        <div className="terms-checkbox-outer-parent">
                            <div className={`${!(buttonValidatonIsRequired && !stepperCheckList?.fscsConsentFlag?.value) && 'terms-conditions-choosePlan'} ${(buttonValidatonIsRequired && !stepperCheckList?.fscsConsentFlag?.value) && 'input-error-border terms-conditions-choosePlan'} consent-choosePlan`}>
                                <Checkbox
                                    onChange={() => {
                                        dispatch(updateCheckList({ obj: { value: !stepperCheckList?.fscsConsentFlag?.value, date: new Date().toISOString() }, fieldName: Strings?.fscsConsentFlag }));
                                    }}
                                    label={termsData?.fscsData?.fscsConsentData}
                                    inputClassName={"checkbox-width-zero"}
                                    labelClassName={fscsConsent && "text-primary_one"}
                                    isChecked={stepperCheckList?.fscsConsentFlag?.value}
                                />
                            </div>
                        </div>
                    </div>
                    {buttonValidatonIsRequired && !stepperCheckList?.fscsConsentFlag?.value && (
                        <div className={`${Styles.errorHeadingSmallerStyle} py-1`}>
                            {Strings.pleaseSelectToContinue}
                        </div>
                    )}
                    </div>
                    <div className="fscs-group-right col-md-4 col-lg-6 align-content-center">
                        <Img
                            src={fscsIcon}
                            className={`img-fluid`}
                            alt="FSCS icon"
                            width="98px"
                            height="98px"
                            aria-label="FSCS icon"
                        />
                    </div>
                </div>
            </div>
            <div className="fscsAndTermsSec2 mt-4">
                <div className="row font-size-18px">
                    <div className="fw-bold">{termsData?.termsData?.termsTitle}</div>
                </div>
                <div className="row">
                    <div className="col">
                        <p className="new-line lh-base">{termsData?.termsData?.termsSummary}</p>
                    </div>
                </div>
                <LinkRenderer textParam={termsData?.termsData?.termsGeneralSavings} linkParam={termsData?.termsData?.termsGeneralSavingsUrl} />
                <LinkRenderer textParam={id} linkParam={termsData?.termsData?.termsProductTypeUrl} />
                <LinkRenderer textParam={termsData?.termsData?.termsSummaryBox} linkParam={termsData?.termsData?.termsSummaryBoxUrl} />
                <div className="row">
                    <div className="col-md-8 col-lg-6">
                        <div className={`${!(buttonValidatonIsRequired && !stepperCheckList?.termsAndConditionFlag?.value) && 'terms-conditions-choosePlan'} ${(buttonValidatonIsRequired && !stepperCheckList?.termsAndConditionFlag?.value) && 'input-error-border terms-conditions-choosePlan'} consent-choosePlan`}>
                            <Checkbox
                                onChange={() => {
                                    dispatch(updateCheckList({ obj: { value: !stepperCheckList?.termsAndConditionFlag?.value, date: new Date().toISOString() }, fieldName: Strings?.termsAndConditionFlag }));
                                }}
                                label={termsData?.termsData?.termsConsentData}
                                inputClassName={"checkbox-width-zero"}
                                labelClassName={fscsConsent && "text-primary_one"}
                                isChecked={stepperCheckList?.termsAndConditionFlag?.value}
                            />
                        </div>
                    </div>
                </div>
                {buttonValidatonIsRequired && !stepperCheckList?.termsAndConditionFlag?.value && (
                    <div className={`${Styles.errorHeadingSmallerStyle} py-1`}>
                        {Strings.pleaseSelectToContinue}
                    </div>
                )}
            </div>
        </>
    );
};

export default StepperFscsAndTerms;

interface LinkRendererProps {
    textParam?: string;
    linkParam?: string;
    classRequired?: boolean;
}

const LinkRenderer: FunctionComponent<LinkRendererProps> = ({ textParam, linkParam, classRequired=true }) => {
    return (<div className="row">
        <div className={classRequired ? 'col-md-8 col-lg-6': 'full-width-link-renderer-class'}>
            <div
                className="terms-conditions-choosePlan openLinkItems"
                role="button"
                onClick={() => window.open(linkParam)}>
                <span>{textParam}</span>
                <span>
                    <img
                        alt={'Right Arrow Icon'}
                        src={require(`../../assest/OpenLinkRightChevron.png`)}
                        className="open-link-right-rotated-icon"
                    />
                </span>
            </div>
        </div>
    </div>)
}
