import axios, { AxiosError } from "axios";
import { productDetailsQuery } from "../queries/productDetailsQuery";

export const fetchProductDetailsGraphqlData = async (url: string) => {
    const data = productDetailsQuery;
    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": 'application/json',
          Authorization: 'Bearer lBmuNKWeexAERNtCLBIfMAn-URK1_MKiD9IaePhHyBw',
        },
        
      });
      return response?.data;
    } catch (error) {
      // For testing only will remove after contenful integrated properly
      return error;
      // const typedError = error as AxiosError;
      // if (axios.isAxiosError(typedError)) {
      //   if (typedError?.response) {
      //     return typedError?.message || "An error occurred";
      //   } else if (typedError?.request) {
      //     return "No response received from server";
      //   }
      // }
      // return typedError?.message;
    }
  };