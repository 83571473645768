import React from "react";
import Modal from "react-bootstrap/Modal";
import { ListUlLi } from "../basic components/listUlLi";

export const InformationDisplayPopup = (props: any) => {
  const { data, onClick, show } = props;
  return (
    <Modal
      show={show}
      size={"xl"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">{data?.title}</Modal.Title>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={onClick}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <div>{data?.body?.title}</div>
        <ListUlLi descriptionValue={data?.body?.description}/>
      </Modal.Body>
      <Modal.Footer>
        <div className="row mb-3-Custom">
          <div className="col" />
          <div className="col-4 text-center flex">
          </div>
          <div className="col" />
        </div>
      </Modal.Footer>
    </Modal>
  );
};