import React, { useEffect } from "react";
import { testPOSTapis } from "../../services/publicApi";
import axios from "axios";

// TODO it will only consume dedicated component

//DUMMY CODE BELOW TO TEST APIs on server, to be removed LATER

export const ContactUs = () => {
    useEffect(() => {
        fetchAPIs();
    }, [])

    const fetchAPIs = async () => {
        let tokenFromStorage = await localStorage.getItem("tokenVal");
        console.log("tokenFromStorage=== ", tokenFromStorage);

        let urlFromStorage = await localStorage.getItem("urlVal");
        console.log("urlFromStorage=== ", urlFromStorage);

        let bodyFromLocalStorage = await localStorage.getItem("apiBody");
        console.log("bodyFromLocalStorage=== ", bodyFromLocalStorage);

        let methodFromLocalStorage = await localStorage.getItem("apiMethod");
        console.log("methodFromLocalStorage=== ", methodFromLocalStorage);

        if (tokenFromStorage == null) {
            localStorage.setItem('tokenVal', 'sampleData') //Only for DEMO purpose
        }

        if (urlFromStorage == null) {
            localStorage.setItem('urlVal', 'https://sampleURL') //Only for DEMO purpose
        }

        if (bodyFromLocalStorage == null) {
            localStorage.setItem('apiBody', '{}') //Only for DEMO purpose set empty body
        }

        if (methodFromLocalStorage == null) {
            localStorage.setItem('apiMethod', 'POST') //Only for DEMO purpose set empty body
        }

        let actualToken = await localStorage.getItem("tokenVal");
        console.log("actualToken=== ", actualToken);

        let actualUrl = await localStorage.getItem("urlVal");
        console.log("actualUrl=== ", actualUrl);

        let actualBody = await localStorage.getItem("apiBody");
        console.log("actualBody=== ", actualBody);

        let actualMethod = await localStorage.getItem("apiMethod");
        console.log("actualMethod=== ", actualMethod);

        //To be removed later
        const myHeaders = new Headers();
        let TOKEN = actualToken;
        let URL = actualUrl;

        let BODY: any = actualBody;


        if (actualMethod == "POST") {
            const resPost = await testPOSTapis(URL, TOKEN, JSON.parse(BODY));
            console.log("POST API VERIFICATION of API on account details::: ", resPost);
        } else if (actualMethod == "PATCH") {
            patchAPITestFuntionViaFetchMethod(URL, TOKEN, JSON.parse(BODY));
        } else {
            //GET calls goes here
        }
    };


    // {
    //     "payInterestTo": "Linked Account"
    // }
    function patchAPITestFuntionViaFetchMethod(URL?: any, token?: any, body?: any) {
        let API_URL = URL;
        if (API_URL == "" || API_URL == 'https://sampleURL') {
            API_URL = 'https://techco.api.monument.co/techcocustapi/techco-sf-integration-service/v1/proxy/financial-accounts?account-number-hash=3ce0a7d9c2ae62dae9df878de056677f282cf0331cba586e22a6f997f98977b9';
        }
        // let FETCHAPI = fetch(API_URL, {
        //     method: 'PATCH',
        //     body: body,
        //     headers: {
        //         'Content-Type': 'application/json'
        //         , "Authorization": `${token}`
        //     }
        // }).then((response) => response.json())
        // .then((json) => console.log(json))
        // .catch((error) => {
        //     // Code
        //     console.log("FETCHAPI ERROR: ", error);
        // });


        axios.patch(API_URL,
            body,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `${token}`
                }
            }
        ).then((response) => {
            // Code
            console.log("AXIOS RESPONSE: ", response);
        }).catch((error) => {
            // Code
            console.log("AXIOS ERROR: ", error);
        })
    }

    return (
        <>
            {'Contact Us'}
        </>
    )
}

