import React from "react";
import { useParams } from "react-router-dom";
import { StepperRunner } from "./stepperRunner";

export const Stepper = () => {
  const { id } = useParams();
  return (
    <>
      <div>
        <StepperRunner />
      </div>
    </>
  );
};
