export const productDetailsQuery = JSON.stringify({
  query: `{
  productDetailsData(id: "DGf54vi4LmkAUHbSyXSI6") {
  productcontent{
    productdataCollection{
  total
      skip
      limit
      items{
        __typename
        _id
        content
        usecase
        productdescriptionlist
        btnname
        iconimg
        {
          title
          url
          }
        }
      }
    } 
  }
}`,
  variables: {},
});
